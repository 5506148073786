import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Driver } from './Driver';
import { Truck } from './Truck';
import { Allocation } from './Allocation';
import { ActivityStore } from './Activity';
import { DATETIME_MINI_FORMAT } from 'helpers';
import moment from 'moment';
import { debounce, pickBy } from 'lodash';
import { ACTION_DELAY } from 'helpers';
import axios from 'axios';

export class ReassignmentChange extends Model {
    static backendResourceName = 'reassignment_change';

    @observable id = null;
    @observable datetime = moment();
    @observable remarks = '';
    @observable isCompleted = false;
    @observable location = '';
    @observable finalizedAt = null;

    casts() {
        return {
            datetime: Casts.datetime,
            finalizedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            newDriver1: Driver,
            newDriver2: Driver,
            newTruck: Truck,
            allocation: Allocation,
            activities: ActivityStore,
        };
    }

    @computed
    get debugMessage() {
        return
        // eslint-disable-next-line
`Reassignment Change\n
id: ${this.id}\n
truck: ${this.newTruck.id}
driver1: ${this.newDriver1.name}
driver2: ${this.newDriver2.name}
planned: ${this.datetime ? this.datetime.format(DATETIME_MINI_FORMAT) : ''}`;
    }

    @computed
    get description() {
        return `${!this.newDriver1.isNew ? this.newDriver1.name : ''} ${!this.newDriver2.isNew ? this.newDriver2.name : ''}`;
    }

    autosave() {
        if (this.cancelRequest) {
            this.cancelRequest();
            this.cancelRequest = undefined;
        }

        this.saveDebounced();
    }

    saveDebounced = debounce(() => {
        this.save({
            onlyChanges: true,
            cancelToken: new axios.CancelToken(c => {
                this.cancelRequest = c;
            }),
        });
    }, ACTION_DELAY);
}

export class ReassignmentChangeStore extends Store {
    Model = ReassignmentChange;
    static backendResourceName = 'reassignment_change';

    finalize(reassignments, email) {
        const data = {
            'reassignment_change': reassignments.map(r => r.id),
            'email_to': email.recipients,
            'email_subject': email.subject,
            'email_body': email.content,
        };

        return this.api.post(this.url() + 'finalize/', data).then(() => {
            reassignments.forEach(r => r.finalizedAt = moment());
        });
    }

    unfinalize(reassignments) {
        const data = {
            'reassignment_change': reassignments.map(r => r.id),
        };

        return this.api.post(this.url() + 'unfinalize/', data).then(() => {
            reassignments.forEach(r => r.finalizedAt = null);
        });
    }

    /**
     * Returns array of driver ids which are duplicate inside the reassignment
     * change store. Example:
     *
     * [1, 2, 3]
     */
    @computed get getDuplicateDrivers() {
        const drivers = {};

        this.models.forEach(reassignment => {
            if (!reassignment.newDriver1.isNew) {
                if (!drivers[reassignment.newDriver1.id]) {
                    drivers[reassignment.newDriver1.id] = [];
                }

                drivers[reassignment.newDriver1.id].push(reassignment.id);
            }

            if (!reassignment.newDriver2.isNew) {
                if (!drivers[reassignment.newDriver2.id]) {
                    drivers[reassignment.newDriver2.id] = [];
                }

                drivers[reassignment.newDriver2.id].push(reassignment.id);
            }
        });

        return Object.keys(pickBy(drivers, reassignmentIds => reassignmentIds.length >= 2)).map(id => parseInt(id));
    }
}
