import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { SuperText } from 're-cy-cle';
import { indexOf } from 'lodash';
import { Activity, ActivityStore, TYPE_UNLOAD } from 'store/Activity';
import { Truck } from 'store/Truck';
import { DocumentStore, STATUS_REJECTED_BY_CUSTOMER, TYPE_TEMPERATURE_PRINT } from 'store/Document';
import { HeadingRow, HeadingColumn } from 'component/Compact/Heading';
import { Row } from 'component/Compact/Row';
import { Column } from 'component/Compact/Column';
import { PageLoader } from 'component/Loader';
import { observable } from 'mobx';
import ContentScollModal from 'spider/semantic-ui/ContentScrollModal';
import { CancelButton } from 'spider/semantic-ui/Button';
import { PaginationControls, ItemButton } from 'component/AdminOverview';
import { Table, Grid, Image } from 'semantic-ui-react';
import { Button } from 'spider/semantic-ui/Button';
import { IconFileDownload } from 're-cy-cle';
import RightDivider from 'spider/component/RightDivider';
import { showSaveNotification } from 'helpers/notification';
import Lightbox from 'react-image-lightbox';
import ReactCrop from 'react-image-crop';
import { DATE_SHORT_FORMAT, DATETIME_FORMAT } from 'helpers';
import 'react-image-lightbox/style.css';
import { Pdf } from 'container/Invoice/ActivityEdit/ModalPdf';
import { TargetTextArea } from 'spider/semantic-ui/Target';

@observer
export default class ModalPlanningCustomerSelectDocument extends Component {
    static propTypes = {
        // Either give a truck or an activity, but not both:
        // - When a truck is given, show all documents related to that truck.
        // - When an activity is given, show all documents related to that
        //   activity with possibilities to link/unlink. Also show none linked
        //   documents, so you can link them.
        activity: PropTypes.instanceOf(Activity),
        truck: PropTypes.instanceOf(Truck),

        viewStore: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
        onDocumentApprove: PropTypes.func.isRequired,
    };

    @observable selectedDocument = null;

    // Crop stuff.
    @observable isCropping = false;
    @observable crop = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    };

    @observable pixelCrop = {
        x: 0,
        y: 0,
        width: 10,
        height: 10,
    };

    @observable fullScreen = false;

    // {copy-previous-load-store}
    @observable previousLoadActivityStore = new ActivityStore({
        relations: ['location'],
        params: {
            '.type': 'load',
            order_by: '-ordered_arrival_datetime_from',
        },
    });

    UNSAFE_componentWillMount() {
        const { activity, truck } = this.props;

        if (activity && truck) {
            throw new Error('Don\'t give both activity and truck!');
        }

        this.documentStore = new DocumentStore({
            relations: ['truck', 'activity'],
            params: { order_by: '-scanned_at' },
        });

        if (activity) {
            const activityTrucks = [activity.assignment.truck.id].concat(activity.previousStops.map(a => a.assignment.truck.id));
            Object.assign(this.documentStore.params, {
                '.status:in': ['pending', 'rejected'].join(','),
                '.activity:isnull': '',
                '.truck:in': activityTrucks.filter(id => !!id).join(','),
            });
        } else {
            Object.assign(this.documentStore.params, {
                '.truck': truck.id,
            })
        }
    }

    componentDidMount() {
        this.documentStore.fetch();

        if (!this.props.activity) {
            this.previousLoadActivityStore.params['.order_reference'] = '_NOT_EXISTS_';
        } else if (!this.props.activity.proposedActivity.isNew) {
            this.previousLoadActivityStore.params['.proposed_activity.internal_order_reference'] = this.props.activity.proposedActivity.internalOrderReference;
        } else {
            this.previousLoadActivityStore.params['.order_reference'] = this.props.activity.orderReference;
        }

        this.previousLoadActivityStore.fetch();
    }

    handleNext = () => {
        let index = indexOf(this.documentStore.models, this.selectedDocument);
        if(this.documentStore.models.get(index+1)) {
            this.props.viewStore.setModal(null);
            this.selectedDocument = this.documentStore.models.get(index+1);
            this.handleSelect(this.selectedDocument);
        }
    };

    handlePrevious = () => {
        let index = indexOf(this.documentStore.models, this.selectedDocument);
        if(this.documentStore.models.get(index-1)) {
            this.props.viewStore.setModal(null);
            this.selectedDocument = this.documentStore.models.get(index-1);
            this.handleSelect(this.selectedDocument);
        }
    };

    handleClose = () => {
        this.props.viewStore.setModal(null);
    };

    handleSelect = doc => {
        if (this.selectedDocument !== doc) {
            this.selectedDocument = doc;
        } else {
            this.selectedDocument = null;
        }
    };

    handleBack = doc => {
        this.props.viewStore.setModal({
            ...this.props,
            render: ModalPlanningCustomerSelectDocument,
        });
    };

    renderDocument = (doc, selected) => {
        return (
            <React.Fragment>
                <Row data-test-document={doc.id} selected={selected}>
                    <Column size={1} onClick={() => this.handleSelect(doc)}>
                        {doc.scannedAt.format(DATETIME_FORMAT)}
                    </Column>
                    <Column size={1} onClick={() => this.handleSelect(doc)}>
                        {t(`document.field.type.options.${doc.type || 'unknown'}`)}
                    </Column>
                    <Column size={1} onClick={() => this.handleSelect(doc)}>
                        <span data-test-doc-status={doc.id}>{doc.status}</span> {doc.status === STATUS_REJECTED_BY_CUSTOMER && doc.rejectReason ? ' - ' + doc.rejectReason : ''}
                    </Column>
                    <Column size={1} onClick={() => this.handleSelect(doc)}>
                        {doc.truck.licensePlate}
                    </Column>
                    <Column width="33px" noPadding>
                        <Button icon onClick={() => doc.openFileInWindow()}>
                            <IconFileDownload />
                        </Button>
                    </Column>
                </Row>
                {this.selectedDocument === doc && this.renderDetails()}
            </React.Fragment>
        );
    };

    handleRotate = () => {
        this.selectedDocument.rotate(90)
            .then(() => this.selectedDocument.fetch())
            .then(showSaveNotification)
            .catch(() => { });
    };
    handleBrighten = (brightness) => {
        this.selectedDocument.brighten(brightness)
            .then(() => this.selectedDocument.fetch())
            .then(showSaveNotification)
            .catch(() => { });
    };
    handleSharpen = (sharpness) => {
        this.selectedDocument.sharpen(sharpness)
            .then(() => this.selectedDocument.fetch())
            .then(showSaveNotification)
            .catch(() => { });
    };

    handleReset = () => {
        this.selectedDocument.reset()
            .then(() => this.selectedDocument.fetch())
            .then(showSaveNotification)
            .catch(() => { });
    };

    handleCrop = () => {
        const toggleCrop = () => {
            this.isCropping = !this.isCropping;
        }

        if (this.isCropping) {
            return this.selectedDocument.crop(this.pixelCrop)
                .then(showSaveNotification)
                .then(toggleCrop);
        }

        toggleCrop();
    }

    handleReject = async () => {
        await this.selectedDocument.rejectByEmployee()
        await this.selectedDocument.fetch();

        this.selectedDocument = null;
        showSaveNotification();
    };

    handleAccept = () => {
        const { activity } = this.props;

        this.selectedDocument.setInput('status', 'approved by planner');
        this.selectedDocument.setInput('type', this.props.type);
        this.selectedDocument.setInput('activity', activity);
        this.props.activity.documents.chooseDocument(this.selectedDocument);
        this.selectedDocument.save({ onlyChanges: true }).then(() => {
            this.selectedDocument = null;
            showSaveNotification();
            this.props.onDocumentApprove();
        });
    };

    detachDoc = async () => {
        const handleCheckStatus=(status)=>{
        if (status === 'rejected by customer' || status === 'rejected') {
            return this.selectedDocument.status
        }else return 'pending'
        }

        const  newActivity  = new Activity();

        this.selectedDocument.setInput('status', handleCheckStatus(this.selectedDocument.status));

        if (this.selectedDocument.type !== TYPE_TEMPERATURE_PRINT) {
            this.selectedDocument.setInput('type', null);
        }

        this.selectedDocument.setInput('activity', newActivity);
        await this.selectedDocument.save({ onlyChanges: true });

        this.selectedDocument = null;
        this.props.viewStore.showSaveNotification();
    };

    renderDocuments = () => {
        const { activity, type } = this.props;
        const activityDocumentIds = activity ? activity.documents.map('id') : [];
        const activityDocumentsWithType = activity ? activity.documents.filter(d => d.type === type) : [];
        const activityDocumentsWithoutType = activity ? activity.documents.filter(d => d.type === null) : [];
        const nonActivityDocuments = this.documentStore.filter(d => !activityDocumentIds.includes(d.id));

        if (this.documentStore.isLoading || (activity && activity.documents.isLoading)) {
            return <PageLoader show />;
        }

        return (
            <React.Fragment>
                <HeadingRow>
                    <HeadingColumn size={1}>{t('document.field.scannedAt.label')}</HeadingColumn>
                    <HeadingColumn size={1}>{t('document.field.type.label')}</HeadingColumn>
                    <HeadingColumn size={1}>{t('document.field.status.label')}</HeadingColumn>
                    <HeadingColumn size={1}>{t('document.field.truck.label')}</HeadingColumn>
                    <HeadingColumn width="33px" />
                </HeadingRow>
                {activityDocumentsWithType.length > 0 && (
                    <SuperText>
                        {t('planning.activityFinalize.selectDocument.approvedDocumentsTitle', {
                            type: t(`document.field.type.options.${type}`)
                        })}
                    </SuperText>
                )}
                {activity && (
                    <React.Fragment>
                        {activityDocumentsWithType.map(doc => this.renderDocument(doc, true))}
                        {activityDocumentsWithoutType.length > 0 && (
                            <SuperText>{t('planning.activityFinalize.selectDocument.attachedDocumentsTitle')}</SuperText>
                        )}
                        {activityDocumentsWithoutType.map(doc => this.renderDocument(doc))}
                    </React.Fragment>
                )}
                {activity && nonActivityDocuments.length > 0 && (
                    <SuperText>{t('planning.activityFinalize.selectDocument.otherDocumentsTitle')}</SuperText>
                )}
                {nonActivityDocuments.map(doc => this.renderDocument(doc))}
            </React.Fragment>
        )
    }

    renderDetails = () => {
        const { activity } = this.props;

        return activity
            ? (
                <Grid data-test-document-selected columns={2} style={{ width: '100%' }}>
                    <Grid.Column width={4}>
                        {this.renderActivityDetails()}
                    </Grid.Column>
                    <Grid.Column width={12}>
                        {this.renderContent()}
                    </Grid.Column>
                </Grid>
            ) : this.renderContent();
    }

    renderActivityDetails = () => {
        const { activity } = this.props;

        return (
            <React.Fragment>
                <Table colSpan={2}>
                    <Table.Row>
                        <Table.HeaderCell >
                            <TargetTextArea style={{width:'100%', border: 'none'}}
                                target={this.selectedDocument}
                                name="rejectReason"
                                placeholder={t('document.field.rejectReason.placeholder')}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table>
                <Table celled compact definition>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan={2}>{t('planning.approveDocument.activityDetails')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{t('planning.approveDocument.activity.type')}</Table.Cell>
                            <Table.Cell>{activity.type}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('planning.approveDocument.activity.finishedTime')}</Table.Cell>
                            <Table.Cell>{activity.finishedDatetime ? activity.finishedDatetime.format(DATE_SHORT_FORMAT) : '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('planning.approveDocument.activity.location')}</Table.Cell>
                            <Table.Cell>{activity.location ? activity.location.city: '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('activity.field.instructions.label')}</Table.Cell>
                            <Table.Cell>{activity.instructions}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                {activity.type === TYPE_UNLOAD && this.previousLoadActivityStore.map(pa => (
                    <Table celled compact definition>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan={2}>{t('planning.approveDocument.previousLoad')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{t('planning.approveDocument.activity.location')}</Table.Cell>
                                <Table.Cell>{pa.location ? activity.location.city: '-'}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{t('activity.field.instructions.label')}</Table.Cell>
                                <Table.Cell>{pa.instructions}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                ))}
                <Table celled compact definition>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan={2}>{t('planning.approveDocument.driver1Details')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell collapsing>{t('planning.approveDocument.driver1.dataExternalId')}</Table.Cell>
                            <Table.Cell>{!activity.assignment.driver1.isNew ? activity.assignment.driver1.dataExternalId: '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>{t('planning.approveDocument.driver1.name')}</Table.Cell>
                            <Table.Cell>{!activity.assignment.driver1.isNew ? activity.assignment.driver1.name: '-'}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Table celled compact definition>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan={2}>{t('planning.approveDocument.driver2Details')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell collapsing>{t('planning.approveDocument.driver2.dataExternalId')}</Table.Cell>
                            <Table.Cell>{!activity.assignment.driver2.isNew ? activity.assignment.driver2.dataExternalId: '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>{t('planning.approveDocument.driver2.name')}</Table.Cell>
                            <Table.Cell>{!activity.assignment.driver2.isNew ? activity.assignment.driver2.name: '-'}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                {this.selectedDocument && this.selectedDocument.status === STATUS_REJECTED_BY_CUSTOMER && this.selectedDocument.rejectReason && (
                    <Table celled compact definition>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan={2}>{t('planning.approveDocument.rejectByCustomer')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell collapsing>{t('planning.approveDocument.rejectByCustomerReason')}</Table.Cell>
                                <Table.Cell data-test-reject-reason style={{ whiteSpace: 'pre-line' }}>{this.selectedDocument.rejectReason}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                )}
            </React.Fragment>
        );
    }

    renderContent = () => {
        if (this.selectedDocument.pdfFile) {
            return this.renderPdf();
        }

        return this.renderImage();
    }

    renderImage = () => {
        return (
            <React.Fragment>
                {this.fullScreen && (
                    <Lightbox
                        mainSrc={this.selectedDocument.file}
                        onCloseRequest={() => this.fullScreen = false}
                    />
                )}
                {!this.isCropping
                    ? <Image centered src={this.selectedDocument.file} onClick={() => this.fullScreen = true} />
                    : (
                        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <ReactCrop
                                src={this.selectedDocument.file}
                                crop={this.crop}
                                onChange={(crop, pixelCrop) => {
                                    this.crop = crop;
                                    this.pixelCrop = pixelCrop;
                                }}
                            />
                        </div>
                    )
                }
            </React.Fragment>
        );
    }

    renderPdf = () => {
        return (
            <Pdf url={this.selectedDocument.pdfFile} />
        );
    }

    disableApproveButton = (isCropping, selectedDocumentStatus) => {
        if(isCropping || selectedDocumentStatus === 'rejected by customer' || selectedDocumentStatus === 'rejected'){
            return true
        }
        else {
            return false
        }
    }

    render() {
        const { activity } = this.props;

        return (
            <ContentScollModal open hasHeader hasActions
                onClose={this.handleClose}
                size="fullscreen"
                centered={false}
            >
                <ContentScollModal.Header>{t('planning.activityFinalize.selectDocument.title')}</ContentScollModal.Header>
                <ContentScollModal.Content style={{ padding:'0px' }}>{this.renderDocuments()}</ContentScollModal.Content>
                <ContentScollModal.Actions>
                    <PaginationControls store={this.documentStore} />
                    <CancelButton onClick={this.handleClose} />
                    <RightDivider />
                        {this.selectedDocument && (
                            <React.Fragment>
                                {this.selectedDocument.file && (
                                    <React.Fragment>
                                        <ItemButton primary
                                            icon="sync"
                                            label={t('planning.activityFinalize.selectDocument.revert')}
                                            loading={this.selectedDocument.isLoading}
                                            disabled={this.isCropping || this.isLoading}
                                            onClick={() => this.handleReset()}
                                        />
                                        <ItemButton primary
                                            icon="undo"
                                            label={t('planning.activityFinalize.selectDocument.rotate')}
                                            disabled={this.isCropping || this.isLoading}
                                            loading={this.selectedDocument.isLoading}
                                            onClick={() => this.handleRotate()}
                                        />
                                        <ItemButton primary
                                            icon="circle outline"
                                            label={t('planning.activityFinalize.selectDocument.brighten')}
                                            loading={this.selectedDocument.isLoading}
                                            disabled={this.isCropping || this.isLoading}
                                            onClick={() => this.handleBrighten(1.1)}
                                        />
                                        <ItemButton primary
                                            icon="circle"
                                            label={t('planning.activityFinalize.selectDocument.darken')}
                                            loading={this.selectedDocument.isLoading}
                                            disabled={this.isCropping || this.isLoading}
                                            onClick={() => this.handleBrighten(0.9)}
                                        />
                                        <ItemButton primary
                                            icon="chess board"
                                            label={t('planning.activityFinalize.selectDocument.sharpen')}
                                            loading={this.selectedDocument.isLoading}
                                            disabled={this.isCropping || this.isLoading}
                                            onClick={() => this.handleSharpen(0.5)}
                                        />
                                        <ItemButton primary
                                            icon="crop"
                                            label={this.isCropping ? t('planning.activityFinalize.selectDocument.cropSaveButton') : t('planning.activityFinalize.selectDocument.cropStartButton')}
                                            onClick={() => this.handleCrop()}
                                        />
                                    </React.Fragment>
                                )}
                                {activity && (
                                    <Button negative data-test-reject-button
                                        icon="thumbs down"
                                        content={t('planning.activityFinalize.selectDocument.rejectDocumentButton')}
                                        onClick={() => this.handleReject()}
                                        loading={this.selectedDocument.isLoading}
                                    />
                                )}
                                {activity && (
                                    <Button primary data-test-approve-button
                                        icon="thumbs up"
                                        content={t('planning.activityFinalize.selectDocument.approveDocumentButton')}
                                        disabled = {this.disableApproveButton(this.isCropping, this.selectedDocument.status)}
                                        onClick={() => this.selectedDocument.status !== 'rejected by customer' && this.handleAccept()}
                                        loading={this.selectedDocument.isLoading}
                                    />
                                )}
                                {this.selectedDocument && this.selectedDocument.activity && this.selectedDocument.activity.id && (
                                    <Button primary data-test-detach-button
                                        icon="unlink"
                                        content={t('planning.activityFinalize.selectDocument.detach')}
                                        disabled={this.isCropping}
                                        onClick={() => this.detachDoc()}
                                        loading={this.selectedDocument.isLoading}
                                    />
                                )}
                            </React.Fragment>
                        )
                    }
                </ContentScollModal.Actions>
            </ContentScollModal>
        );
    }
}
