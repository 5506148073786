import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Text } from 're-cy-cle';
import { Table } from 'semantic-ui-react';
import { DATE_FORMAT } from 'helpers';
import { KIND_BOEK_STOP } from 'store/Activity';

@observer
export default class ActivityFinalizeStops extends Component {
    static propTypes = {
        activities: PropTypes.object.isRequired,
    };

    render() {
        const { activities } = this.props;
        if (activities.length < 1) {
            return (
                <Text tone="danger">
                    {t('planning.activityFinalize.noStopsFound')}
                </Text>
            );
        }
        return (
            <Table celled compact definition>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>{t('activity.field.id.label')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('planning.activityFinalize.stopHeadings.stop')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('activity.field.type.label')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('activity.field.location.label')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('planning.activityFinalize.dateLabel')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('planning.activityFinalize.stopHeadings.extraKm')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('activity.field.remarks.label')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {activities.map(activity => {
                        if (activity.deleted) {
                            return null;
                        }

                        return (
                            <Table.Row key={activity.id}>
                                <Table.Cell collapsing>{activity.id}</Table.Cell>
                                <Table.Cell>{t(`activity.field.kind.longOptions.${activity.kind}`)}</Table.Cell>
                                <Table.Cell>{t(`activity.field.type.options.${activity.type}`)}</Table.Cell>
                                <Table.Cell>{activity.location.city || '-'}</Table.Cell>
                                <Table.Cell>{activity.orderedArrivalDatetimeFrom.format(DATE_FORMAT)}</Table.Cell>
                                <Table.Cell data-test-detour-km-recalculation>{activity.isLoading || activity.kind !== KIND_BOEK_STOP ? '-': activity.detourKm}</Table.Cell>
                                <Table.Cell>{activity.remarks}</Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    }
}
