// Very similar to ModalEnitityInvoicePdf!
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Button, Col, Row } from 're-cy-cle';
import { Invoice } from 'store/Invoice';
import { PaginationControls } from 'component/AdminOverview';
import RightDivider from 'spider/component/RightDivider';
import InvoiceDownloadPdfButton from 'component/InvoiceDownloadPdfButton';
import { SendButton } from 'spider/semantic-ui/Button';
import { TargetDatePicker } from 'spider/semantic-ui/Target';
import ComposeEmail from 'component/ComposeEmail';
import { Model } from 'store/Base';
import { hasLowLevelPermission } from 'helpers/permission';
import { ContactStore } from 'store/Contact';
import { Document, Page }  from 'react-pdf';
import { Form, Icon, Modal, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

// Fake model to handle sending emails.
export class Email extends Model {
    @observable id = null;
    @observable recipients = [];
    @observable subject = '';
    @observable content = '';

    @action
    generate(invoiceContacts, invoice) {
        this.subject = invoice.tariff.contract.invoiceEmailSubject;
        this.content = invoice.tariff.contract.invoiceEmailContent;
        this.recipients = invoiceContacts;
    }
}

const DocumentContainer = styled.div`
    position: relative;
    background-color: #EEE;
    height: ${props => props.height ? props.height: '710px'};
`;

const DocumentScroll = styled.div`
    height: 100%;
    overflow-y: scroll;
    > div {
        > div {
            margin: 2.5rem 0;
            canvas {
                box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.25);
                margin: 0 auto;
            }
        }
        margin-bottom: 4rem;
    }
`;

const PaginationBar = styled.div`
    height: 4rem;
    line-height: 4rem;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
`;


const PdfMessage = styled.div`
    text-align: center;
    font-size: 120%;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
`;

@observer export class Pdf extends Component {
    static propTypes = {
        url: PropTypes.string.isRequired,
        height: PropTypes.string,
    };

    @observable currentPage = 1;
    @observable totalPages = 0;

    setPage = (page) => {
        if (page < 1 || page > this.totalPages) {
            return;
        }

        const container = ReactDOM.findDOMNode(this).childNodes[0];
        const pages = container.childNodes[0].childNodes;

        pages[page - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    setCurrentPage = () => {
        const container = ReactDOM.findDOMNode(this).childNodes[0];
        const pages = container.childNodes[0].childNodes;

        const target = container.scrollTop + container.offsetHeight / 2;
        for (let i = 0; i < pages.length; i++) {
            if (pages[i].offsetTop + pages[i].offsetHeight >= target) {
                this.currentPage = i + 1;
                break;
            }
        }
    };

    render() {
        const { url, height, ...rest } = this.props
        const pages = [];

        for (let i = 1; i <= this.totalPages; i++) {
            pages.push((
                <Page
                    key={i}
                    pageNumber={i}
                    onRenderError={(...args) => console.log('onRenderError', args)}
                    onLoadError={(...args) => console.log('onLoadError', args)}
                />
            ));
        }

        return (
            <DocumentContainer data-test-pdf-container height={height} {...rest}>
                <DocumentScroll
                    onScroll={this.setCurrentPage}
                    onResize={this.setCurrentPage}
                >
                    <Document
                        file={url}
                        options={{ disableWorker: false }}
                        onLoadSuccess={({ numPages }) => this.totalPages = numPages}
                        loading={<PdfMessage>{t('form.pdfLoading')} <Icon loading name="spinner" /></PdfMessage>}
                        error={<PdfMessage>{t('form.pdfError')}</PdfMessage>}
                    >
                        {pages}
                    </Document>
                </DocumentScroll>
                <PaginationBar>
                    <PaginationControls store={{
                        currentPage: this.currentPage,
                        totalPages: this.totalPages,
                        hasPreviousPage: this.currentPage > 1,
                        getPreviousPage: () => this.setPage(this.currentPage - 1),
                        hasNextPage: this.currentPage < this.totalPages,
                        getNextPage: () => this.setPage(this.currentPage + 1),
                        setPage: this.setPage,
                    }} />
                </PaginationBar>
            </DocumentContainer>
        );
    }
}

@observer
export default class ModalInvoicePdf extends Component {
    static propTypes = {
        invoice: PropTypes.instanceOf(Invoice).isRequired,
        viewStore: PropTypes.object.isRequired,
        trigger: PropTypes.func.isRequired,
        email: PropTypes.object,
        contactStore: PropTypes.object,
        onSendDraft: PropTypes.func,
        onFinalize: PropTypes.func,
        onClose: PropTypes.func,
        hideEmail: PropTypes.bool,
        recipients: PropTypes.array.isRequired,
    };

    static defaultProps = {
        recipients: [],
    };

    @observable email = new Email();
    @observable contactStore = new ContactStore({
        params: {
            '.customer.contracts.tariffs.id': this.props.invoice.tariff.id,
            '.contracts': this.props.invoice.tariff.contract.id,
            '.types:contains': 'invoice',
            '.email:not': '', // hide contacts with empty email
        }
    });
    @observable isOpen = false;
    open = () => this.isOpen = true
    close = () => {
        const { onClose } = this.props;

        this.isOpen = false;

        if (onClose) {
            onClose();
        }
    }

    getEmail() {
        return this.props.email || this.email;
    }

    componentDidMount() {
        this.contactStore.fetch().then(() => {
            const invoiceContacts = this.contactStore.map(c => c.email);

            if (this.shouldSendEmail) {
                this.getEmail().generate(invoiceContacts, this.props.invoice);
            }
        });
    }

    shouldSendEmail = () => {
        const { invoice } = this.props;

        return invoice.tariff.contract.sendInvoice === 'email'
    }

    handleFinalize = () => {
        const { onFinalize } = this.props;
        this.shouldSendEmail() ? onFinalize({ email: this.getEmail(), close: this.close }) : onFinalize({ close: this.close });
    };

    renderFinalizeButton = () => {
        const { invoice } = this.props;

        let button = (
            <Button data-test-finalize-button onClick={this.handleFinalize}>
                {t('invoice.finalizeButton')}
            </Button>
        );

        if (hasLowLevelPermission('invoice.overridedate_invoice')) {
            button = (
                <Popup open flowing hoverable
                    position="top right"
                    trigger={button}
                >
                    <Form>
                        <TargetDatePicker
                            target={invoice}
                            name="invoiceDate"
                            label={t('invoice.field.invoiceDate.overrideLabel')}
                        />
                    </Form>
                </Popup>
            );
        }

        return button;
    }

    render() {
        const { invoice, onSendDraft, onFinalize, trigger, hideEmail, ...rest } = this.props;
        const showEmail = !hideEmail && this.shouldSendEmail();

        return (
            <Modal
                centered={false}
                size="fullscreen"
                open={this.isOpen}
                onClose={this.close}
                trigger={trigger({ open: this.open, close: this.close })}
                {...rest}
            >
                <Modal.Content>
                    <Row>
                        <Col xs={showEmail ? 8 : 12}>
                            <Pdf url={`/api/invoice/${invoice.id}/pdf/`} />
                        </Col>
                        {showEmail && (
                            <Col xs={4}>
                                <Form>
                                    <ComposeEmail wysiwyg
                                        invoice={invoice}
                                        viewStore={this.props.viewStore}
                                        model={this.getEmail()}
                                        contactStore={this.props.contactStore ? this.props.contactStore : this.contactStore}
                                    />
                                </Form>
                            </Col>
                        )}
                    </Row>
                </Modal.Content>
                <Modal.Actions style={{ display: 'flex' }}>
                    <Button onClick={this.close}>
                        {t('form.closeButton')}
                    </Button>
                    <RightDivider />
                    <InvoiceDownloadPdfButton invoice={invoice} />
                    {onSendDraft && (
                        <SendButton primary compact data-test-send-draft-button
                            loading={invoice.isLoading}
                            content={t('invoice.sendDraftButton')}
                            onClick={() => onSendDraft({ email: this.getEmail(), close: this.close })}
                        />
                    )}
                    {onFinalize && this.renderFinalizeButton()}
                </Modal.Actions>
            </Modal>
        );
    }
}
