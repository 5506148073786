import { observable } from 'mobx';
import { Model, Store, Casts, FileField } from 'store/Base';
import { Supplier } from './Supplier';
import { Entity } from './Entity';
import { Truck } from './Truck';
import { LeaseContract } from './LeaseContract';

export const TYPE_LIABILITY = 'liability';
export const TYPE_CASCO = 'casco';
export const TYPE_LIABILITY_AND_CASCO = 'liability + casco';

export class InsuranceContract extends Model {
    static backendResourceName = 'insurance_contract';

    @observable id = null;
    @observable startDate = null;
    @observable endDate = null;
    @observable contractNumber = '';
    @observable type = TYPE_LIABILITY;
    @observable remarks = '';
    @observable maximumAmountPerIncident = 0;
    @observable maximumAmountPerYear = 0;
    @observable nonDeductibleAmountPerIncident = 0;
    @observable nonDeductibleAmountPerYear = 0;
    @observable monthlyPayment = 0;
    @observable contractScan = new FileField({ name: 'contractScan' });

    relations() {
        return {
            truck: Truck,
            insuranceCompany: Supplier,
            insuranceBroker: Supplier,
            leaseContract: LeaseContract,
            entity: Entity,
        };
    }

    casts() {
        return {
            startDate: Casts.date,
            endDate: Casts.date,
            contractScan: Casts.file,
        };
    }

    saveAsCopy(leaseContract) {
        return this.save({ url: leaseContract.url + 'copy/' });
    }
}

export class InsuranceContractStore extends Store {
    Model = InsuranceContract;
    static backendResourceName = 'insurance_contract';
}
