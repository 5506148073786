import { observable, computed } from 'mobx';
import { Model, Store, Casts } from './Base';
import { Driver } from './Driver'
import { debounce } from 'lodash';
import { ACTION_DELAY } from 'helpers';
import axios from 'axios';
import moment from 'moment';

/**
 * Saving driver availability requires special care. When confirming, the
 * backend will check if driver availabilities are overlapping. In case this
 * happens, you have to manually catch that exception in the frontend and handle
 * it yourself. See also tag {driver-availability-special-save}.
 */
export function saveDriverAvailability(driverAvailability, extraData) {
    const confirmArrival = driverAvailability.isArrivalConfirmedChanged && !!driverAvailability.arrivalConfirmedAt;
    const unconfirmArrival = driverAvailability.isArrivalConfirmedChanged && !driverAvailability.arrivalConfirmedAt;
    const confirmDeparture = driverAvailability.isDepartureConfirmedChanged && !!driverAvailability.departureConfirmedAt;
    const unconfirmDeparture = driverAvailability.isDepartureConfirmedChanged && !driverAvailability.departureConfirmedAt;

    const p = driverAvailability.save({ onlyChanges: true, data: extraData })
        .then(() => {
            // Backend gives confirmed back as null, so the checkbox get unchecked.
            if (confirmArrival) {
                driverAvailability.setInput('arrivalConfirmedAt', moment());
            }

            if (unconfirmArrival) {
                driverAvailability.setInput('arrivalConfirmedAt', null);
            }

            if (confirmDeparture) {
                driverAvailability.setInput('departureConfirmedAt', moment());
            }

            if (unconfirmDeparture) {
                driverAvailability.setInput('departureConfirmedAt', null);
            }
        });

    return p
        .then(() => confirmArrival ?  driverAvailability.confirmArrival() : null)
        .then(() => unconfirmArrival ?  driverAvailability.unconfirmArrival() : null)
        .then(() => confirmDeparture ?  driverAvailability.confirmDeparture() : null)
        .then(() => unconfirmDeparture ?  driverAvailability.unconfirmDeparture() : null)
        // Since multiple requests are sent, response clobbers confirm
        // arrival / departure... refetch to make it mentally easy.
        .then(() => driverAvailability.fetch());
}

export class DriverAvailability extends Model {
    static backendResourceName = 'driver_availability';

    @observable id = null;

    @observable travelToWorkDepartureDatetime = null;
    @observable travelToWorkDeparturePlace = '';

    @observable arrivalDatetime = null;
    @observable arrivalTravelMethod = '';
    @observable arrivalPlace = '';
    @observable arrivalConfirmedAt = null;
    @observable _wasArrivalConfirmed = false;

    @observable departureDatetime = null;
    @observable departureTravelMethod = '';
    @observable departurePlace = '';
    @observable departureConfirmedAt = null;
    @observable _wasDepartureConfirmed = false;

    @observable travelToHomeArrivalDatetime = null;
    @observable travelToHomeArrivalPlace = '';

    parse(data) {
        const result = super.parse(data);

        this._wasArrivalConfirmed = !!this.arrivalConfirmedAt;
        this._wasDepartureConfirmed = !!this.departureConfirmedAt;

        return result;
    }

    @computed get isArrivalConfirmedChanged() {
        return this.__changes.includes('arrivalConfirmedAt') &&
            this._wasArrivalConfirmed !== !!this.arrivalConfirmedAt;
    }

    @computed get isDepartureConfirmedChanged() {
        return this.__changes.includes('departureConfirmedAt') &&
            this._wasDepartureConfirmed !== !!this.departureConfirmedAt;
    }

    getTypeOnDay(day) {
        const travelToWorkDepartureDatetime = this.travelToWorkDepartureDatetime.format('YYYY-MM-DD');
        const arrivalDatetime = this.arrivalDatetime.format('YYYY-MM-DD');
        const departureDatetime = this.departureDatetime.format('YYYY-MM-DD');
        const travelToHomeArrivalDatetime = this.travelToHomeArrivalDatetime.format('YYYY-MM-DD');

        if (arrivalDatetime <= day && day <= departureDatetime) {
            return !this.arrivalConfirmedAt ? 'maybeWorking' : 'working';
        }

        if (travelToWorkDepartureDatetime <= day && day < arrivalDatetime) {
            return !this.arrivalConfirmedAt ? 'maybeTraveling' : 'traveling';
        }

        if (day > departureDatetime && day <= travelToHomeArrivalDatetime) {
            return !this.departureConfirmedAt ? 'maybeTraveling' : 'traveling';
        }

        if (day < travelToWorkDepartureDatetime) {
            return !this.arrivalConfirmedAt ? 'maybeAtHome' : 'atHome';
        }

        if (day > travelToHomeArrivalDatetime) {
            return !this.departureConfirmedAt ? 'maybeAtHome' : 'atHome';
        }

        return 'atHome';
    }

    driverAvailabilityMatchTachoEvent(day, tachoMeasuredAt){
        if(tachoMeasuredAt >= day && tachoMeasuredAt <= day){
            return 'match'
        }
        return 'notMatch'
    }

    casts() {
        return {
            travelToWorkDepartureDatetime: Casts.datetime,
            arrivalDatetime: Casts.datetime,
            arrivalConfirmedAt: Casts.datetime,
            departureDatetime: Casts.datetime,
            departureConfirmedAt: Casts.datetime,
            travelToHomeArrivalDatetime: Casts.datetime,
        };
    }

    relations() {
        return {
            driver: Driver,
        };
    }

    confirmArrival() {
        const p = this.api.post(`${this.url}confirm/arrival/`);

        p.catch(err => {
            const valErrors = this.api.parseBackendValidationErrors(err.response);

            if (valErrors) {
                this.parseValidationErrors(valErrors);
            }

            throw err;
        });

        return this.wrapPendingRequestCount(p);
    }

    unconfirmArrival() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}unconfirm/arrival/`)
        );
    }

    confirmDeparture() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}confirm/departure/`)
        );
    }

    unconfirmDeparture() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}unconfirm/departure/`)
        );
    }

    autosave() {
        if (this.cancelRequest) {
            this.cancelRequest();
            this.cancelRequest = undefined;
        }

        this.saveDebounced();
    }

    saveDebounced = debounce(() => {
        this.save({
            onlyChanges: true,
            cancelToken: new axios.CancelToken(c => {
                this.cancelRequest = c;
            }),
        });
    }, ACTION_DELAY);
}

export class DriverAvailabilityStore extends Store {
    Model = DriverAvailability;
    static backendResourceName = 'driver_availability';

    getByIsoWeek(isoWeek) {
        return this.models.find(m => m.travelToWorkDepartureDatetime.format('GGGG-WW') <= isoWeek && isoWeek <= m.travelToHomeArrivalDatetime.format('GGGG-WW'));
    }
}
