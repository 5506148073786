import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export const BRAND_BP = 'BP';
export const BRAND_SHELL = 'Shell';
export const BRAND_PETROMIRALLES = 'Petromiralles';
export const BRAND_G_AND_V = 'G&V'; // Basically this is BP in Belgium
export const BRANDS = [BRAND_BP, BRAND_SHELL, BRAND_PETROMIRALLES, BRAND_G_AND_V];

export class PetrolStation extends Model {
    static backendResourceName = 'petrol_station';

    @observable id = null;
    @observable name = '';
    @observable brand = BRAND_BP;
    @observable price = 0;
    @observable color = '';
    @observable maximumDistance = 500000; // In meters
    @observable point = {
        lat: null,
        lng: null,
    };
    @observable country = '';
    @observable emailAddress = null;
    @observable deleted = false;
}

export class PetrolStationStore extends Store {
    Model = PetrolStation;
    static backendResourceName = 'petrol_station';
}
