import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Truck } from './Truck';
import { Driver } from './Driver';
import { Allocation } from './Allocation';
import { ActivityStore } from './Activity';

export class Assignment extends Model {
    static backendResourceName = 'assignment';

    @observable id = null;
    @observable startDatetime = null;
    @observable activatedAt = null;
    @observable finishedAt = null;
    @observable endDatetime = null;
    @observable remarks = '';
    @observable deleted = false;
    @observable isImmutable = false;
    @observable createdAt = null;
    @observable updatedAt = null;

    casts() {
        return {
            startDatetime: Casts.datetime,
            endDatetime: Casts.datetime,
            activatedAt: Casts.datetime,
            finishedAt: Casts.datetime,
            driver1ArrivalDatetime: Casts.datetime,
            driver2ArrivalDatetime: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            allocation: Allocation,
            activities: ActivityStore,
            truck: Truck,
            driver1: Driver,
            driver2: Driver,
            reallocatedAssignment: Assignment,
        };
    }

    /**
     * Finds previous activity. Will skip deleted activities.
     *
     * Different from backend, since the activity.previousActivity also skips
     * stops.
     *
     * {copy-paste-findPreviousActivity}
     */
    findPreviousActivity(activity) {
        const i = this.activities.map(a => a.id).indexOf(activity.id);

        try {
            const prevIndex = i - 1;

            if (prevIndex < 0) {
                return null;
            }

            const prev = this.activities.at(prevIndex);

            if (prev.deleted) {
                return this.findPreviousActivity(prev);
            }

            return prev;
        } catch (e) {
            return null;
        }
    }

    /**
     * {copy-paste-findNextActivity}
     */
    findNextActivity(activity) {
        const i = this.activities.map(a => a.id).indexOf(activity.id);

        try {
            const next = this.activities.at(i + 1);

            if (next.deleted) {
                return this.findNextActivity(next);
            }

            return next;
        } catch (e) {
            return null;
        }
    }
}

export class AssignmentStore extends Store {
    Model = Assignment;
    static backendResourceName = 'assignment';

    comparator = (a, b) => {
        if (a.startDatetime === null || b.startDatetime === null) {
            return 0;
        }

        if (a.startDatetime.isSame(b.startDatetime)) {
            if (a.id < b.id) {
                return -1;
            }

            if (a.id > b.id) {
                return 1;
            }

            return 0
        }

        if (a.startDatetime.isBefore(b.startDatetime)) {
            return -1;
        }

        if (a.startDatetime.isAfter(b.startDatetime)) {
            return 1;
        }
    };
}
