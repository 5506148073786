import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { computed } from 'mobx';
import { CourseStore } from './Course';
import { GroupStore } from './User';
import { kebabCase } from 'lodash';
import moment from 'moment';
import { Driver } from './Driver';

export const ICON_COLOR_RED = 'red';
export const ICON_COLOR_ORANGE = 'orange';
export const ICON_COLOR_YELLOW = 'yellow';
export const ICON_COLOR_OLIVE = 'olive';
export const ICON_COLOR_GREEN = 'green';
export const ICON_COLOR_TEAL = 'teal';
export const ICON_COLOR_BLUE = 'blue';
export const ICON_COLOR_VIOLET = 'violet';
export const ICON_COLOR_PURPLE = 'purple';
export const ICON_COLOR_PINK = 'pink';
export const ICON_COLOR_BROWN = 'brown';
export const ICON_COLOR_GREY = 'grey';
export const ICON_COLOR_BLACK = 'black';
export const ICON_COLORS = [ICON_COLOR_RED, ICON_COLOR_ORANGE, ICON_COLOR_YELLOW, ICON_COLOR_OLIVE, ICON_COLOR_GREEN, ICON_COLOR_TEAL, ICON_COLOR_BLUE, ICON_COLOR_VIOLET, ICON_COLOR_PURPLE, ICON_COLOR_PINK, ICON_COLOR_BROWN, ICON_COLOR_GREY, ICON_COLOR_BLACK];

export const OPTIONAL_FIELD_DATE_OBTAINED = 'date_obtained';
export const OPTIONAL_FIELD_DATE_OF_EXPIRY = 'date_of_expiry';
export const OPTIONAL_FIELD_DOCUMENT_NUMBER = 'document_number';
export const OPTIONAL_FIELD_HAS_DIPLOMA = 'has_diploma';
export const OPTIONAL_FIELD_DOCUMENT_SCAN = 'document_scan';
export const OPTIONAL_FIELDS = [OPTIONAL_FIELD_DATE_OBTAINED, OPTIONAL_FIELD_DATE_OF_EXPIRY, OPTIONAL_FIELD_DOCUMENT_NUMBER, OPTIONAL_FIELD_HAS_DIPLOMA, OPTIONAL_FIELD_DOCUMENT_SCAN];

export const KIND_RECRUITMENT_DOCUMENT = 'recruitment_document';
export const KIND_PERMIT = 'permit';
export const KIND_TRAINING = 'training';
export const KIND_HR_DOCUMENT = 'hr_document';
export const KINDS = [KIND_RECRUITMENT_DOCUMENT, KIND_PERMIT, KIND_TRAINING, KIND_HR_DOCUMENT];

export const PRIORITY_BLOCKING ='blocking';
export const PRIORITY_REQUIRED ='required';
export const PRIORITY_OPTIONAL ='optional';
export const PRIORITIES = [PRIORITY_BLOCKING, PRIORITY_REQUIRED, PRIORITY_OPTIONAL];

export class Certification extends Model {
    static backendResourceName = 'certification';

    @observable id = null;
    @observable kind = null;
    @observable name = '';
    @observable slug = '';
    @observable priority = '';
    @observable iconName = null;
    @observable iconColor = ICON_COLOR_GREY;

    @observable availableFields = [];
    @observable requiredFields = [];
    @observable requiredOncePresent = false;
    @observable isSystem = false;
    @observable addCertificationBulkAction = false;
    @observable winspedCode = '';
    @observable deactivatedAt = null;


    relations() {
        return {
            dependsOn: CertificationStore,
            blocks: CertificationStore,
            courses: CourseStore,
            groupReminders: GroupStore,
        };
    }

    /**
     * In some cases, TMS ties functionality to the slug of a certification.
     *
     * These still stems from the old times... we keep this around to make
     * sure that works. For newer created certifications, we auto generate the
     * slug.
     *
     * @return {string} Slugify version of name.
     */
    generateSlug() {
        return kebabCase(this.name);
    }

    @computed get priorityColor() {
        if (this.priority === PRIORITY_BLOCKING) {
            return 'red';
        }

        if (this.priority === PRIORITY_REQUIRED) {
            return 'yellow';
        }

        return 'green';
    }
}

export class CertificationStore extends Store {
    Model = Certification;
    static backendResourceName = 'certification';
}

export class DriverCertification extends Model {
    static backendResourceName = 'driver_certification';

    @observable id = null;
    @observable dateObtained = null;
    @observable dateOfExpiry = null;
    @observable hasDiploma = false;
    @observable documentNumber = '';
    @observable documentScan = null;
    @observable deleted = null;
    @observable isFuelCard = false;
    @observable _order = null; // only for ordering purpose 

    casts() {
        return {
            dateObtained: Casts.date,
            dateOfExpiry: Casts.date,
        };
    }

    relations() {
        return {
            certification: Certification,
            driver: Driver
        };
    }

    @computed
    get downloadDocumentScanUrl() {
        return `${this.api.baseUrl.replace(/\/$/, '')}${
            this.url
            }document_scan/?download`;
    }

    @computed
    get isExpired() {
        if (this.dateOfExpiry) {
            return this.dateOfExpiry.clone().startOf('day') < moment().startOf('day');
        }

        return false;
    }
}

export class DriverCertificationStore extends Store {
    Model = DriverCertification;
    static backendResourceName = 'driver_certification';
}
