import { observable, computed } from 'mobx';
import { pick, isArray } from 'lodash';
import { Model, Store, Casts } from 'store/Base';
import { EntityStore } from './Entity';
import { CustomerStore } from './Customer';
import { Driver } from './Driver';
import { showSaveNotification } from 'helpers/notification';

export const customerGroups = ['customer', 'customer administration']

export class Permission extends Model {
    static backendResourceName = 'permission';

    @observable id = null;
    @observable codename = '';
}

export class PermissionStore extends Store {
    Model = Permission;
    static backendResourceName = 'permission';
}

export class Group extends Model {
    static backendResourceName = 'group';

    @observable id = null;
    @observable name = '';

    relations() {
        return {
            permissions: PermissionStore,
        };
    }
}

export class GroupStore extends Store {
    Model = Group;
    static backendResourceName = 'group';
}

export class User extends Model {
    static backendResourceName = 'user';

    @observable id = null;
    // TODO: username and email are always the same. Hopefully we can remove username soon.
    @observable username = '';
    @observable email = '';
    @observable firstName = '';
    @observable lastName = '';
    @observable lastActive = null;
    @observable password = '';
    @observable groupNames = [];
    @observable isSuperuser = false;
    @observable deleted = false;
    @observable emailSignature = '';
    @observable internalPhoneNumber = '';
    @observable activateSsoLogin = false;
    @observable _isMasqueraded = false;

    // ['activity.manage_activity:planner', 'activity.view_activity:customer_service']
    @observable highLevelPermissions = [];

    // {
    //      'activity.view_allocation': ['contract_assignee', 'entity'],
    //      'asset.view_truck': ['contract_assignee', 'entity'],
    // }
    @observable lowLevelPermissions = [];

    @computed
    get fullName() {
        if (this.firstName || this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }
        return this.username;
    }

    @computed
    get isCustomer() {
        return this.groups.map(g => g.name).some(x=>customerGroups.includes(x));
    }

    casts() {
        return {
            lastActive: Casts.datetime,
        };
    }

    relations() {
        return {
            groups: GroupStore,
            entities: EntityStore,
            customers: CustomerStore,
            driver: Driver,
        };
    }

    toBackend(options) {
        const output = super.toBackend(options);
        const newData = pick(output, [
            'id',
            'email',
            'first_name',
            'last_name',
            'groups',
            'entities',
            'customers',
            'email_signature',
            'internal_phone_number',
            'activate_sso_login',
        ]);

        if (output.password) {
            newData.password = output.password;
        }

        return newData;
    }

    masquerade() {
        return this.api.post(`/user/${this.id}/masquerade/`).then(() => {
            window.location = '/';
        });
    }

    resetRequest(username) {
        return this.api.post(`/user/reset_request/`, { username });
    }

    changePassword({ passwordOld, passwordNew }) {
        return this.api.put(`/user/change_password/`, {
            old_password: passwordOld,
            new_password: passwordNew,
        });
    }

    resetPassword({ id, password, resetCode }) {
        return this.api
            .put(`/user/${id}/reset_password/`, {
                password,
                reset_code: resetCode,
            })
            .then(() => {
                window.location = '/';
            });
    }

    undelete() {
        return this.api.post(`/user/${this.id}/`).then(() => {
            window.location = '/admin/user';
        });
    }

    /**
     * Alias for hasHighLevelPermissions
     */
    hasPermission(...args) {
        return this.hasHighLevelPermission(...args);
    }

    hasHighLevelPermission(permissions) {
        if (this.isSuperuser) {
            return true;
        }

        if (!isArray(permissions)) {
            return this.highLevelPermissions.includes(permissions);
        }

        return this.highLevelPermissions.some(groupName =>
            permissions.includes(groupName)
        );
    }

    /**
     * If user has lowLevelPermissions:
     * {
     *     activity.change_activity': ['entity', 'contract_assignee'],
     * }
     *
     * Then:
     *
     * hasLowlevelPermission('activity.change_activity') -> true
     * hasLowlevelPermission('activity.change_activity', 'entity') -> true
     * hasLowlevelPermission('activity.change_activity', 'own') -> false
     *
     */
    hasLowLevelPermission(permission, scope) {
        if (this.isSuperuser) {
            return true;
        }

        const hasKey = Object.keys(this.lowLevelPermissions).includes(permission);
        const scopes = this.lowLevelPermissions[permission];

        if (!scope) {
            return hasKey;
        }

        return scopes.includes(scope);
    }

    inGroup(groups) {
        if (!isArray(groups)) {
            return this.groupNames.includes(groups);
        }
        return this.groupNames.some(groupName => groups.includes(groupName));
    }
}

export class UserStore extends Store {
    Model = User;
    static backendResourceName = 'user';
    @observable ssoUserExist;
    @observable
    params = {
        order_by: 'first_name',
        '.groups.name:not': 'customer, driver, driver recruiter, driver care manager',
    };
    
    checkUserSsoLogin() {
        return this.wrapPendingRequestCount(this.api.get('/user/check_if_exist_sso_login/'));
    }
    
    activateAllSsoUsers() {
        const UPDATE_CONFIRMED = 'update confirmed';
        return this.wrapPendingRequestCount(this.api.post('/user/activate_sso_users/', {
            activate: this.ssoUserExist
        }).then(result => {
            this.fetch();
            this.ssoUserExist = !this.ssoUserExist;
            if (result['result'] === UPDATE_CONFIRMED) {
                showSaveNotification();
            }
        }).catch(error => {
            const errors = error.response.data.errors;
            console.log(errors);
        }))
    }
}
