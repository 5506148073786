// [TODO] clean up index

// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './container/App';
// import { observable } from 'mobx';
// import ViewStore from 'spider/store/View';
// import { theme } from './styles.js';
// import { t } from './i18n';
// import { BrowserRouter } from 'react-router-dom';
// import { ReCyCleTheme } from 're-cy-cle';
// import configureSentry from './sentry';
import { configOverride } from 'helpers';
// import { configureModal } from 'helpers/modal';
import { configureViewStore } from 'helpers/viewStore';
// import { configureNotification } from 'helpers/notification';
import { configurePermission as configurePermissionHelper } from 'helpers/permission';
import {  hasLowLevelPermission } from 'spider/component/Permission';
// import { configureBasename, configureAfterSave } from 'spider/helpers/bindUrlParams';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
// import { api } from 'store/Base';
// import { User } from 'store/User';
import { fleetvisorFormStore } from 'store/FleetvisorForm';
import show, { clear } from 'helpers/modal';
import { Login } from 'container/App';
import { get } from 'lodash';

// Not ideal, but works.
import 'style/semantic-ui/semantic.css';
import 'style/semantic-ui.css';
import 'style/react-image-crop.css';
import 'daycy/dist/daycy.css';





import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './container/App';
import ViewStore from 'spider/store/View';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { theme } from './styles.js';
import { t } from './i18n';
import i18next from 'i18next';
// use router instead of browserRouter for sentry, see
// https://github.com/getsentry/sentry-javascript/issues/3107#issuecomment-741431381 
import { Router } from 'react-router-dom';
import { ReCyCleTheme } from 're-cy-cle';
import * as Sentry from '@sentry/react';
import { PUBLIC_URL } from 'helpers';
import { configureModal } from 'helpers/modal';
import { configureNotification } from 'helpers/notification';
import { configurePermission } from 'spider/component/Permission';
import { configureCurrentUser } from 'helpers/currentUser';
import { CancelButton, ApplyButton } from 'spider/semantic-ui/Button';
import { configureBasename, configureAfterSave } from 'spider/helpers/bindUrlParams';
import { Modal } from 'semantic-ui-react';
import RightDivider from 'spider/component/RightDivider';
import 'moment-duration-format';
import { api } from 'store/Base';
import { User } from 'store/User';
// import { configureTranslation } from 'daycy';
// For time being sentry is off
// import configureSentry from './sentry';
import { createBrowserHistory } from 'history';

import 'style/semantic-ui.css';
import 'style/extra-icons.css';
import 'style/custom-icons/cy-custom-icons.css';

momentDurationFormatSetup(moment);
window.t = t;

class BoekViewStore extends ViewStore {
    @observable routeOptimizationLevels = [];

    @action
    handleBootstrap(res) {
        configOverride(res);
        return super.handleBootstrap(res);
    }

    
    fetchBootstrap() {
        return super.fetchBootstrap()
            .then(() => hasLowLevelPermission('boekestijn.view_fleetvisorform') && fleetvisorFormStore.fetch({ supressError: true }));
    }

    parseCurrentUserFromBootstrap(res) {
        this.allowedRooms = res.allowed_rooms || [];

        this.currentUser.fromBackend({ data: res.user, repos: res.with, relMapping: res.with_mapping });
        this.currentUser._isMasqueraded = res.is_masqueraded;
    }
};

const viewStore = new BoekViewStore({
	api,
	user: new User(null, { relations: ['entities', 'groups'] }),
	socketUrl: `${PUBLIC_URL || ''}/ws/`
});


@observer
export class LoginModal extends Login {
    renderForgotPasswordLink() {
        return null;
    }

    render() {
        return (
            <Modal open
                centered={false}
                size="mini"
            >
                <Modal.Header onClick={this.toggleShowLoginForm} style={{cursor: 'pointer'}} data-test-show-modal-login-panel>{t('user.login.title')}</Modal.Header>
                <Modal.Content>
                    <p>{t('user.login.reloginMessage')}</p>
                    {this.renderForm()}
                </Modal.Content>
            </Modal>
        );
    }
}

/**
 * Currently test newAppVersionNotification abuses the fact that viewStore is
 * globally available. We should only expose this when debug = true. BOEK has
 * a debug mode, where you can see more in the interface (like calculations)
 * and have access to scary buttons.
 */
window.viewStore = viewStore;


export const history = createBrowserHistory();

configureModal(viewStore);
configureViewStore(viewStore);
configureNotification(viewStore);
configurePermission(viewStore);
configurePermissionHelper(viewStore);
configureBasename(PUBLIC_URL);
configureAfterSave({ goBack: false, createUrl: '/add' });
configureCurrentUser(viewStore);

// [TODO] Sentry issues when deploying, As sentry has problem on it's own now this can stay off for now
// configureSentry(viewStore);

let notAuthenticatedRequests = [];
let isLoginModalShown = false;

// [TODO] move interceptors to separate file
api.axios.interceptors.response.use(null, err => {
    const status = get(err, 'response.status');
    const statusErrCode = get(err, 'response.data.code');

    if (status === 403 && statusErrCode === 'NotAuthenticated' && err.response.config.url !== '/api/user/login/') {
        if (!isLoginModalShown) {
            isLoginModalShown = true;
            show(LoginModal, {
                viewStore,
                usernameField: 'username',
                afterLogin: () => {
                    clear();
                    isLoginModalShown = false;
                    notAuthenticatedRequests.map(pending => {
                        if (pending.err.response.config.headers['X-Csrftoken']) {
                            pending.err.response.config.headers['X-Csrftoken'] = api.csrfToken;
                        }

                        api.axios({ ...pending.err.response.config, baseURL: '' })
                            .then(pending.resolve)
                            .catch(pending.reject);
                    });

                    notAuthenticatedRequests = [];
                }
            });
        }

        return new Promise((resolve, reject) =>
            notAuthenticatedRequests.push({ resolve, reject, err })
        );
    }

    return Promise.reject(err);
});

@observer
class Root extends Component {
    @observable showAlert = false;
    @observable alertMessage = '';
    @observable alertConfirm = null;

    // Custom alert callbacks.
    @observable alertOnApply = null;
    @observable alertOnCancel = null;

    componentDidMount() {
        i18next.on('languageChanged', () => this.forceUpdate());
    }

    componentWillUnmount() {
        i18next.off('languageChanged');
    }

    cancel = () => {
        this.showAlert = false;

        if (this.alertOnCancel) {
            this.alertOnCancel();
        }

        this.alertConfirm(false);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    confirm = () => {
        this.showAlert = false;

        if (this.alertOnApply) {
            this.alertOnApply();
        }

        this.alertConfirm(true);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    render() {
        return (
            <Sentry.ErrorBoundary showDialog>
                <React.Fragment key={i18next.language}>
                    <Modal size="tiny" open={this.showAlert} centered={false}>
                        <Modal.Content style={{ textAlign: 'center' }}>
                            <p>{this.alertMessage}</p>
                            <p>{t('form.confirmQuestion')}</p>
                        </Modal.Content>
                        <Modal.Actions style={{ display: 'flex' }}>
                            <CancelButton negative onClick={this.cancel} />
                            <RightDivider />
                            <ApplyButton positive onClick={this.confirm} content={t('form.continueButton')} />
                        </Modal.Actions>
                    </Modal>
                    <ReCyCleTheme theme={theme}>
                        <Router history={history} basename={PUBLIC_URL} getUserConfirmation={(message, confirm, ...args) => {
                            this.showAlert = true;
                            this.alertConfirm = confirm;

                            if (typeof message === 'object') {
                                this.alertMessage = message.message;
                                this.alertOnApply = message.onApply;
                                this.alertOnCancel = message.onCancel;
                            } else {
                                this.alertMessage = message;
                                this.alertOnApply = null;
                                this.alertOnCancel = null;
                            }

                        }}>
                            <App store={viewStore} />
                        </Router>
                    </ReCyCleTheme>
                </React.Fragment>
            </Sentry.ErrorBoundary>
        );
    }
}

ReactDOM.render(
    <Root />,
    document.getElementById('root')
);