import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { OrderConfirmationStore } from 'store/OrderConfirmation';
import { FormFile } from 'spider/semantic-ui/Form';
import { Accordion, Icon } from 'semantic-ui-react';
import HeaderRight from 'spider/semantic-ui/HeaderRight';
import { IconButton } from 'spider/semantic-ui/Button';
import RightDivider from 'spider/component/RightDivider';

@observer
export default class OrderConfirmationCards extends Component {
    static propTypes = {
        orderConfirmationStore: PropTypes.instanceOf(OrderConfirmationStore).isRequired,
        className: PropTypes.string,
    };

    @observable activeIndex = null;

    toggleActive = index => {
        this.activeIndex = this.activeIndex === index ? -1 : index;
    }

    render() {
        const { orderConfirmationStore, className } = this.props;
        const nonDeletedOrderConfirmations = orderConfirmationStore.filter(oc => !oc.deleted);

        return (
            <div style={{ marginTop: '20px' }} className={className}>
                <HeaderRight content={t('activity.field.orderConfirmations.label')} store={nonDeletedOrderConfirmations}>
                    <IconButton data-test-add-order-confirmation
                        title={t('activity.field.orderConfirmations.add')}
                        name="add circle"
                        onClick={() => orderConfirmationStore.add({})}
                    />
                </HeaderRight>
                {nonDeletedOrderConfirmations.length > 0 && (
                    <Accordion fluid styled data-test-order-confirmations>
                        {nonDeletedOrderConfirmations.map((orderConfirmation, i) => {
                            if (orderConfirmation.deleted) {
                                return null;
                            }

                            const isPreviewable = FormFile.Preview.isPreviewable(orderConfirmation.file);

                            return (
                                <React.Fragment key={orderConfirmation.id || orderConfirmation.cid}>
                                    <Accordion.Title
                                        style={{ display: 'flex', alignItems: 'center', color: 'unset' }}
                                        index={i}
                                        active={this.activeIndex === i}
                                        onClick={isPreviewable ? () => this.toggleActive(i) : null}
                                    >
                                        <Icon name="dropdown" style={{ visibility: isPreviewable ? 'unset' : 'hidden' }} />
                                        <Icon
                                            name={!orderConfirmation.file.isEmpty ? FormFile.Preview.getIconName(orderConfirmation.file) || 'file outline' : ''}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingRight: '3px',
                                            }}
                                        />
                                        #{i + 1}
                                        <RightDivider />
                                        {/* Show delete button even if file is not yet saved. */}
                                        {!orderConfirmation.file.downloadUrl && (
                                            <IconButton
                                                name="trash"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    orderConfirmationStore.remove(orderConfirmation)
                                                }}
                                            />
                                        )}
                                        <FormFile.Buttons
                                            model={orderConfirmation}
                                            name="file"
                                            onDelete={() => orderConfirmationStore.remove(orderConfirmation)}
                                        />
                                    </Accordion.Title>
                                    <Accordion.Content active={this.activeIndex === i}>
                                        <FormFile.Preview file={orderConfirmation.file} centered/>
                                    </Accordion.Content>
                                </React.Fragment>
                            );
                        })}
                    </Accordion>
                )}
            </div>
        );
    }
}
