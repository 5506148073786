import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { getMoneyForUser, getCurrencySymbolFromCode } from 'helpers';
import { TYPE_LOAD, TYPE_UNLOAD, TYPE_TRAILER_CHANGE } from './../Activity';

export class TariffWaitingRate extends Model {
    static backendResourceName = 'tariff_waiting_rate';

    @observable id = null;
    @observable price = 0; // cents / hour
    @observable smallestTimeUnit = 15; // In minutes
    @observable roundingMode = 'nearest'; // Up, down, nearest
    @observable maxAmount = null; // Max amount per day
    @observable billingThreshold = 0; // After how many minutes we can start billing.
    @observable useOrderedArrivalDatetime = false;
    @observable applyForActivityTypes = [TYPE_LOAD, TYPE_UNLOAD, TYPE_TRAILER_CHANGE];

    calcApprovedWaitingAmount(activity) {
        return activity.preliminaryWaitingAmount;
    }

    /**
     * First call calcPriceForActivity to see if there is an amount to be
     * invoiced, before calling this function to check when the invoicing has
     * started.
     */
    calcStartForActivity(activity) {
        let arrivalDatetime = activity.actualArrivalDatetime;

        // See T19179.
        if (this.useOrderedArrivalDatetime && activity.orderedArrivalDatetimeFrom > activity.actualArrivalDatetime) {
            arrivalDatetime = activity.orderedArrivalDatetimeFrom;
        }

        if (arrivalDatetime) {
            return arrivalDatetime.clone();
        }

        return undefined;
    }

    getFinishedDateTime(activity) {
        const finishedDatetime = activity.finishedDatetime || activity._finishedDatetime;

        if (finishedDatetime) {
            return finishedDatetime.clone();
        }

        return undefined;
    }

    calcInitialMinutes(activity) {
        const arrivalDatetime = this.calcStartForActivity(activity);
        const finishedDatetime = this.getFinishedDateTime(activity);

        return finishedDatetime.diff(
            arrivalDatetime,
            'minutes',
            true
        );
    }

    calcPriceForActivity(activity, includingMinutes = false) {
        const arrivalDatetime = this.calcStartForActivity(activity);
        const finishedDatetime = this.getFinishedDateTime(activity);
        const currencySymbol = getCurrencySymbolFromCode(activity.allocation.contract.fcCode)

        if (!this.applyForActivityTypes.includes(activity.type)) {
            activity._tariffWaitingRateExplaination = `Activity type ${activity.type} does not apply for waiting rate according to tariff settings.`;
            return 0;
        }

        if (!finishedDatetime || !arrivalDatetime) {
            activity._tariffWaitingRateExplaination = 'Missing start or actualArrival';
            return 0;
        }

        let minutes = this.calcInitialMinutes(activity);

        activity._tariffWaitingRateExplaination = `finishedDatetime: ${finishedDatetime.format('DD-MM HH:mm:ss')}\n`;
        activity._tariffWaitingRateExplaination += `actualArrivalDatetime: ${arrivalDatetime.format('DD-MM HH:mm:ss')}\n`;
        activity._tariffWaitingRateExplaination += `minutes: ${minutes}\n`;
        activity._tariffWaitingRateExplaination += `billingThreshold in minutes: ${this.billingThreshold}\n`;

        minutes -= this.billingThreshold;
        activity._tariffWaitingRateExplaination += `Minutes after billingThreshold: ${minutes.toFixed(2)}\n`;

        if (minutes <= 0) {
            activity._tariffWaitingRateExplaination += `Minutes 0 or lower`;

            return 0;
        }

        const remainder = minutes % this.smallestTimeUnit;
        activity._tariffWaitingRateExplaination += `Rounding ${this.roundingMode} using smallestTimeUnit of ${this.smallestTimeUnit}\n`;

        if (remainder) {
            switch (this.roundingMode) {
                case 'up':
                    minutes += this.smallestTimeUnit - remainder;
                    activity._tariffWaitingRateExplaination += `Rounding minutes up to ${minutes}\n`;
                    break;
                case 'down':
                    minutes -= remainder;
                    activity._tariffWaitingRateExplaination += `Rounding minutes down to ${minutes}\n`;
                    break;
                case 'nearest':
                    minutes -= remainder;
                    minutes += Math.round(remainder / this.smallestTimeUnit) * this.smallestTimeUnit;
                    activity._tariffWaitingRateExplaination += `Rounding minutes nearest to ${minutes}\n`;
                    break;
                default:
                    // NOP.
                    break;
            }
        }

        const amount = this.price / 60 * minutes;

        activity._tariffWaitingRateExplaination += `Amount: ${currencySymbol}${getMoneyForUser(amount)}\n`;

        if (this.maxAmount !== null && this.maxAmount > 0 && amount > this.maxAmount) {
            activity._tariffWaitingRateExplaination += `Amount > maxAmount, use maxAmount = ${currencySymbol}${getMoneyForUser(this.maxAmount)}`;
            return this.maxAmount;
        }

        if (includingMinutes) {
            return { amount, minutes, startCountingDatetime: arrivalDatetime.clone().add(this.billingThreshold, 'minutes') };
        }

        return amount;
    }

    explainForActivity(activity) {
        if (!activity._tariffWaitingRateExplaination) {
            this.calcPriceForActivity(activity);
        }

        return activity._tariffWaitingRateExplaination;
    }
}

export class TariffWaitingRateStore extends Store {
    Model = TariffWaitingRate;
    static backendResourceName = 'tariff_waiting_rate';
}
