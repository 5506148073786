import React from 'react';
import PropTypes from 'prop-types';
import IconLoad from './Icon/Load';
import IconUnload from './Icon/Unload';
import IconTanking from './Icon/Tanking';
import IconSecureParking from './Icon/SecureParking';
import IconDriverChange from './Icon/DriverChange';
import IconTruckChange from './Icon/TruckChange';
import IconTrailerChange from './Icon/TrailerChange';
import IconFerrytunnel from './Icon/Ferrytunnel';
import IconEquipmentPickup from './Icon/EquipmentPickup';
import IconTruckSwap from './Icon/TruckSwap';
import IconAllocationExit from './Icon/AllocationExit';
import { IconAdjust, IconHome, IconBuild } from 're-cy-cle';
import { Icon } from 'spider/semantic-ui/Button';
import { theme } from 'styles';
import { TYPE_CHANGE, TYPE_BORDER, TYPE_TACHO_CALIBRATION, TYPE_TUV, TYPE_TRUCK_DROP, TYPE_ALLOCATION_EXIT } from 'store/Activity';

const TypeIcon = ({ type, width, height, color = theme.primaryColor, price }) => {

    const props = { width, height, color };
    switch (type) {
        case 'load':
            return <IconLoad {...props} />;
        case 'unload':
            return <IconUnload {...props} />;
        case 'tanking':
            return <IconTanking data-test-icon-tanking  price={price} {...props} />;
        case 'secure parking':
            return <IconSecureParking {...props} />;
        case 'ferry-tunnel':
            return <IconFerrytunnel {...props} />;
        case 'equipment pick-up':
            return <IconEquipmentPickup {...props} />;
        case 'truck change':
            return <IconTruckChange {...props} />;
        case 'driver change':
            return <IconDriverChange {...props} />;
        case 'trailer change':
            return <IconTrailerChange {...props} />;
        case 'homebase':
            return <IconHome {...props} />;
        case 'service':
            return <IconBuild {...props} />;
        case 'truck swap':
            return <IconTruckSwap {...props} />;
        case 'other':
            return <IconAdjust {...props} />;
        case TYPE_ALLOCATION_EXIT:
            return <IconAllocationExit {...props} />;
        case TYPE_CHANGE:
            return <Icon fitted name="refresh" {...props} />;
        case TYPE_BORDER:
            return <Icon fitted name="map signs" {...props} />;
        case TYPE_TRUCK_DROP:
            return <Icon fitted name="sign-out" {...props} />;
        case TYPE_TACHO_CALIBRATION:
            return <span style={{ color: 'white', fontSize: '10px', fontWeight: 'bold' }}>TACH</span>;
        case TYPE_TUV:
            return <span style={{ color: 'white', fontSize: '10px', fontWeight: 'bold' }}>TUV</span>;
        case 'crosshairs':
            return <Icon fitted name="crosshairs" style={{ color: 'white' }} {...props} />;
        default:
            return null;
    }
};

TypeIcon.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    price: PropTypes.string,
};

TypeIcon.defaultProps = {
    color: theme.primaryColor, // Should use @withTheme...
    width: '30px',
    height: '30px',
};

export default TypeIcon;
