import { Casts, Model } from 'store/Base';
import { observable, computed } from 'mobx';

const COLOR_OK = 'green'
const COLOR_WARNING = 'yellow';
const COLOR_DANGER = 'red';

export class SystemHealth extends Model {
    static backendResourceName = 'system_health';

    // Fake ID, since system health is not really a model and to skip the
    // annoying message fetching model without id.
    @observable id = 1;
    @observable lastGeoApiCall = null;
    @observable lastMeasuredPosition = null;
    @observable lastSuccessfulGeoApiCall = null;
    @observable fleetvisorUnprocessedMsgCount = null;
    @observable lastWebsocketUpdateForFleetvisorUnprocessedMsgCount = null;

    /**
     * Last few minutes of ptv health check. Data structure (newest on top):
     *
     * [
     *   {
     *       'geo_api_call': {
     *           'type': 'routing',
     *           'status': 'success',
     *           'created_at': ,
     *        },
     *        'time': 0.211878,
     *        'success': true,
     *        'measured_at': '2020-11-10T19:26:07.263792+0100',
     *   },
     *   {
     *        ...
     *   }
     *
     */
    @observable ptvHealth = [];
    @observable ptvHealthSummary = {}
    @observable fleetvisor = []
    @observable fleetvisorSummary = {}

    @observable recalculateKmsNonFinishedActivities = []
    @observable recalculateKmsFinishedActivities = []

    @observable pollPtvSummary = {}
    @observable pollPtvActivities = []

    @observable pollPtvNewSummary = {}
    @observable pollPtvNewActivities = []
    @observable activityAutoFinalization = []

    @computed
    get ptvHealthAvgRequestTime() {
        return this.ptvHealthSummary.average_request_time
    }

    // @computed
    // get fleetvisor() {

    // }

    @computed
    get ptvHealthErrorCount() {
        return this.ptvHealthSummary.error_count
    }

    @computed
    get fleetvisorErrorCount() {
        return this.fleetvisorSummary.error_count
    }

    @computed
    get fleetvisorAvgRequestTime() {
        return this.fleetvisorSummary.average_request_time
    }

    @computed
    get fleetvisorAvgMailboxCount() {
        return this.fleetvisorSummary.average_mailbox_count
    }

    @computed
    get fleetvisorCurrentMailboxSize() {
        return this.fleetvisor.length > 0 ? this.fleetvisor[0].count : 0
    }

    /**
     * Returns:
     *
     * any non status 'success' => 'danger'
     * avg request > 5 sec => 'warning'
     * avg request > 10 sec => 'danger'
     *
     * otherwise => 'ok'
     *
     *
     */
    @computed
    get ptvHealthColor() {
        if (this.ptvHealthSummary.status === "warning") {
            return COLOR_WARNING
        }

        if (this.ptvHealthSummary.status === "danger") {
            return COLOR_DANGER
        }

        return COLOR_OK
    }

    @computed
    get ptvHealthTotalRequestTracked() {
        return this.ptvHealthSummary.total_requests_tracked || 0;
    }

    @computed
    get fleetvisorColor() {
        if (this.fleetvisorSummary.status === "warning") {
            return COLOR_WARNING
        }

        if (this.fleetvisorSummary.status === "danger") {
            return COLOR_DANGER
        }

        return COLOR_OK
    }

    @computed
    get recalculateKmsColor() {
        // if (this.ptvHealthSummary.status === "warning") {
        //     return COLOR_WARNING
        // }

        // if (this.ptvHealthSummary.status === "danger") {
        //     return COLOR_DANGER
        // }

        return COLOR_OK
    }

    casts() {
        return {
            lastGeoApiCall: Casts.datetime,
            lastMeasuredPosition: Casts.datetime,
            lastSuccessfulGeoApiCall: Casts.datetime,
            lastWebsocketUpdateForFleetvisorUnprocessedMsgCount: Casts.datetime,
        }
    }

    // Overwrite to specify url so it won't use the id.
    fetch(options = {}) {
        return super.fetch({ ...options, url: this.urlRoot() });
    }
}
