import React from 'react';
import BaseAdminOverview, { SidebarToggle, Sidebar } from 'spider/semantic-ui/Admin/Overview';
import { theme } from 'styles';

export default class AdminOverview extends BaseAdminOverview {
    toggleActiveFgColor = theme.primaryColor;
    toggleFgColor = theme.primaryColor;
    toggleBgColor = 'white';

    renderSidebars() {
        return (
            <React.Fragment>
                {this.finalSidebars.map(({ trigger }, index) => (
                    <SidebarToggle
                        data-test-floating-sidebar-toggle={index}
                        index={index}
                        isActive={this.sidebarActiveIndex === index}
                        activeFgColor={this.toggleActiveFgColor}
                        fgColor={this.toggleFgColor}
                        activeBgColor={this.toggleActiveBgColor}
                        bgColor={this.toggleBgColor}
                        offset={this.sidebarsToggleTopOffset}
                    >
                        {trigger({ onClick: () => this.toggleSidebars(index) })}
                    </SidebarToggle>
                ))}
                {/* Ugly hack for spider issue with StyledAside negative margin with transition, resulting in whole view being moved to the left */}
                {this.sidebarActiveIndex !== -1 && <Sidebar data-test-sidebar={this.sidebarActiveIndex} show={this.sidebarActiveIndex !== null} small={!!this.modal}>
                    {/* This line is changed so that sidebar isn't rerendered when only one sidebar in finalsidebars list. Without this autofocus on a filter prop generates jumping sidebar effect */}
                    {this.sidebarActiveIndex !== null ? this.finalSidebars[this.sidebarActiveIndex].content() : this.finalSidebars.length > 1 ? null : this.finalSidebars[0]?.content()}
                </Sidebar>}
            </React.Fragment>
        );
    }
}

export * from 'spider/semantic-ui/Admin/Overview';
