import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'spider/semantic-ui/Button';
import { Table } from 'semantic-ui-react';
import { DATE_SHORT_FORMAT } from 'helpers';
import { InvoiceLineItem } from 'store/InvoiceLineItem';
import { formatMoney } from 'helpers';

@observer
export default class ActivityFinalizeInvoices extends Component {
    static propTypes = {
        activity: PropTypes.object.isRequired,
    };

    render() {
        const { activity } = this.props;
        const isFc = activity.allocation.contract.isFc
        const fcCode = activity.allocation.contract.fcCode
        const invoices = {};

        activity.invoiceLineItems.forEach(invoiceLineItem => {
            const invoiceId = invoiceLineItem.invoiceLine.invoice.id;

            if (!invoices[invoiceId]) {
                invoices[invoiceId] = [];
            }

            invoices[invoiceId].push(invoiceLineItem);
            invoiceLineItem.invoiceLine.disputes.forEach(dispute => dispute.corrections.forEach(correction => {
                const correctionInvoiceId = correction.invoice.id;

                if (!invoices[correctionInvoiceId]) {
                    invoices[correctionInvoiceId] = [];
                }
                invoices[correctionInvoiceId].push({ correction, invoiceLineItem });
            }));
        });

        return (
            <Table data-test-invoices celled compact>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell collapsing>{t('planning.activityFinalizeModal.invoice.invoiceNumber')}</Table.HeaderCell>
                        <Table.HeaderCell collapsing>{t('planning.activityFinalizeModal.invoice.type')}</Table.HeaderCell>
                        <Table.HeaderCell collapsing>{t('planning.activityFinalizeModal.invoice.invoiceDate')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('planning.activityFinalizeModal.invoice.invoiceReference')}</Table.HeaderCell>
                        <Table.HeaderCell collapsing textAlign="right">{t('planning.activityFinalizeModal.invoice.totalAmount')}</Table.HeaderCell>
                        <Table.HeaderCell collapsing textAlign="right">{t('planning.activityFinalizeModal.invoice.activityAmount')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {Object.keys(invoices).map(invoiceId => (
                    <Table.Body key={invoiceId}>
                        {invoices[invoiceId].map((invoiceLineItemOrCorrection, i) => {
                            if (invoiceLineItemOrCorrection instanceof InvoiceLineItem) {
                                const invoiceLineItem = invoiceLineItemOrCorrection;

                                return (
                                    <Table.Row>
                                        {i === 0 && (
                                            <Table.Cell verticalAlign="top" rowSpan={invoices[invoiceId].length}>
                                                <Link to={`/administration/invoice/${invoiceLineItem.invoiceLine.invoice.id}/edit`}>
                                                    {invoiceLineItem.invoiceLine.invoice.invoiceNumber}
                                                 </Link>
                                            </Table.Cell>
                                        )}
                                        <Table.Cell style={{ borderLeft: '1px solid rgba(34, 36, 38, 0.1)' }}>{invoiceLineItem.invoiceLine.customLine ? t('planning.activityFinalizeModal.invoice.typeCustom') : t('planning.activityFinalizeModal.invoice.typeActivity')}</Table.Cell>
                                        <Table.Cell>{invoiceLineItem.invoiceLine.invoice.invoiceDate.format(DATE_SHORT_FORMAT)}</Table.Cell>
                                        <Table.Cell>{invoiceLineItem.invoiceLine.invoice.customerReference}</Table.Cell>
                                        <Table.Cell data-test-amount textAlign="right">
                                            {isFc
                                                ? invoiceLineItem.invoiceLine.invoice.sentAt
                                                    ? formatMoney(invoiceLineItem.invoiceLine.amountFc, 2, fcCode)
                                                    : formatMoney(invoiceLineItem.invoiceLine.amount, 2, fcCode)
                                                : formatMoney(invoiceLineItem.invoiceLine.amount, 2, fcCode)
                                            }
                                        </Table.Cell>
                                        <Table.Cell data-test-activity-amount textAlign="right">
                                            {formatMoney(invoiceLineItem.amount, 2, fcCode)}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            }

                            const { correction } = invoiceLineItemOrCorrection;

                            return (
                                <Table.Row key={correction.id}>
                                    {i === 0 && (
                                        <Table.Cell verticalAlign="top" rowSpan={invoices[invoiceId].length}>
                                            <Link to={`/administration/invoice/${correction.invoice.id}/edit`}>
                                                {correction.invoice.invoiceNumber}
                                             </Link>
                                        </Table.Cell>
                                    )}
                                    <Table.Cell style={{ borderLeft: '1px solid rgba(34, 36, 38, 0.1)' }}>{t('planning.activityFinalizeModal.invoice.typeCorrection')}</Table.Cell>
                                    <Table.Cell>{correction.invoice && correction.invoice.invoiceDate ? correction.invoice.invoiceDate.format(DATE_SHORT_FORMAT) : '-'}</Table.Cell>
                                    <Table.Cell>{correction.invoice && correction.invoice.invoiceDate ? correction.invoice.customerReference : '-'}</Table.Cell>
                                    <Table.Cell data-test-amount textAlign="right">
                                        { isFc
                                            ? formatMoney(correction.amountFc, 2, fcCode)
                                            : formatMoney(correction.amount, 2, fcCode)
                                        }
                                    </Table.Cell>
                                    <Table.Cell data-test-activity-amount textAlign="right">
                                        {formatMoney(activity.invoiceLineCorrectionItems.models.reduce((res, item) => item.invoiceLineCorrection.id === correction.id ? res + item.correctedAmount : res, 0), 2, fcCode)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                ))}
            </Table>
        );
    }
}
