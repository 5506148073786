import React from 'react';
import styled from 'styled-components';
import { PUBLIC_URL } from 'helpers';
import { Map, TileLayer as RTileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix leaflet marker images
// https://github.com/PaulLeCam/react-leaflet/issues/255
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('image/marker-icon-2x.png'),
    iconUrl: require('image/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export const TileLayer = () => (
    <RTileLayer
        url={`${PUBLIC_URL || ''}/api/location/tile/?z={z}&x={x}&y={y}`}
    />
);

// Currently unused, see T19355
export const SatelliteTileLayer = () => (
    <RTileLayer
        url={`${PUBLIC_URL || ''}/api/location/tile/?z={z}&x={x}&y={y}&layer=satellite`}
    />
);

export const HybridTileLayer = () => (
    <RTileLayer
        url={`${PUBLIC_URL || ''}/api/location/tile/?z={z}&x={x}&y={y}&layer=hybrid`}
    />
);

export const MapContainer = styled.div`
    height: 100%;
    display: flex;
`;

export default styled(Map)`
    width: 100%;
    flex: 1;
    font-family: inherit !important;
    font-size: 14px !important;

    .leaflet-top {
        z-index: 410;
    }
`;

export const truckIcon = L.icon({
    iconRetinaUrl: require('image/marker-icon-truck-2x.png'),
    iconUrl: require('image/marker-icon-truck.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});
