import { observable } from 'mobx';
import { Model, Store, Casts, FileField } from 'store/Base';
import { User } from './User';
import { BEPTicket } from './BEPTicket';

export class BEPDocument extends Model {
    static backendResourceName = 'bepdocument';

    @observable id = null;
    @observable title = '';
    @observable file = new FileField({ name: 'file' });

    @observable deleted = false;
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            bepTicket: BEPTicket,
            createdBy: User,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            file: Casts.file,
        };
    }
};

export class BEPDocumentStore extends Store {
    Model = BEPDocument;
    static backendResourceName = 'bepdocument';
};
