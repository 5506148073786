import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action, reaction } from 'mobx';
import { Location } from 'store/Location';
import { Tooltip } from 're-cy-cle';
import { FormField, TextArea, IconDone, IconCloudUpload, IconWarning } from 're-cy-cle';
import RightDivider from 'spider/component/RightDivider';

export const StatusSign = observer(({ location }) => {
    // Zaic: WTF is this! How unreadable do you want to code? This is really
    // putting effort in making it unreadable...
    return location.geoStatus === 'loading' ? (
        <IconCloudUpload />
    ) : location.geoStatus !== 'success' ? (
        <IconWarning color="#ffa500" />
    ) : location.preciseMatch ? (
        <IconDone />
    ) : (
        <Tooltip
            message={t('form.validationErrors.geo.not_precise')}
            direction="sw"
        >
            <IconWarning color="#ffa500" />
        </Tooltip>
    );
});

@observer
export default class LocationEditFreeForm extends Component {
    static propTypes = {
        location: PropTypes.instanceOf(Location).isRequired,
        label: PropTypes.string,
        error: PropTypes.object,
        skipGeocode: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        changeOnEmpty: PropTypes.bool,
        helpText: PropTypes.node,
        required: PropTypes.bool.isRequired,
        shown: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        required: true,
        shown: true,
    };

    @observable location = new Location(this.props.location.toJS());

    constructor(props) {
        super(props);

        this.reaction = reaction(
            () => this.props.location.id,
            () => this.location.parse(this.props.location.toJS()),
        );
    }

    componentWillUnmount() {
        this.reaction();
    }

    @action
    handleChange = (name, value) => {
        this.location.geoStatus = '';
        this.location.setInput(name, value);
    };

    geocode = () => {
        const { skipGeocode, onChange, changeOnEmpty } = this.props;

        if (!skipGeocode && (this.location.address !== '' || changeOnEmpty)) {
            this.location.geocode().then(() => {
                if (onChange) {
                    onChange(this.location);
                }
            });
        }
    };

    render() {
        const { label, helpText, required, shown } = this.props;
        const location = this.location;

        const help = (
            <React.Fragment>
                <RightDivider />
                {helpText}
                <StatusSign location={location} />
            </React.Fragment>
        );

        return (
            <FormField
                label={label || t('location.field.address.label')}
                error={!['', 'loading', 'success'].includes(location.geoStatus) ? [`geo.${location.geoStatus}`] : this.props.error}
                helpText={shown ? help : null}
                required={required}
            >
                {shown && (
                    <TextArea
                        name="address"
                        onChange={this.handleChange}
                        onBlur={this.geocode}
                        value={location.address}
                        disabled={this.props.disabled}
                        placeholder={t('location.field.address.placeholder')}
                    />
                )}
            </FormField>
        );
    }
}
