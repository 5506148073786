import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Form, Header } from 'semantic-ui-react';
import { Body, ContentContainer, Content, Toolbar, Link } from 're-cy-cle';
import RightDivider from 'spider/component/RightDivider';
import { EntityStore } from 'store/Entity';
import { RoadCost } from 'store/RoadCost';
import { CostSupplierStore } from 'store/CostSupplier';
import { CostProductStore } from 'store/CostProduct';
import { TruckStore } from 'store/Truck';
import { DriverStore } from 'store/Driver';
import { LedgerCostStore } from 'store/LedgerCost';
import { LedgerVatStore } from 'store/LedgerVat';
import { FormLabel, ErrorLabel, TargetSelect, TargetRadioButtons, TargetTextInput } from 'spider/semantic-ui/Target';
import TargetMoneyInput from 'component/TargetMoneyInput';
import TargetCurrencySelect from 'component/TargetCurrencySelect';
import { showSaveNotification } from 'helpers/notification';
import { EditButton, CancelButton, SaveButton, IconButton } from 'spider/semantic-ui/Button';
import { Modal, Popup, Loader } from 'semantic-ui-react';
import { fetchLocationByTruck } from 'store/Location';
import LocationEditFreeForm from 'container/LocationEditFreeForm';
import DatetimePicker from 'component/form/DatetimePicker';
import { showNotification } from 'helpers/notification';
import Activity, { Container, BACKGROUND_COLOR } from 'component/Activity';
import { IconSide, ContentSide } from 'component/Compact/ActivityBlock';
import styled from 'styled-components';
import ModalActivityFinalize from 'screen/ModalActivityFinalize';
import { getViewStore } from 'helpers/viewStore';

const StyledContainer = styled(Container)`
    justify-content: center;
    align-items: center;
`;

export const REQUIRED_RELATIONS = [
    'entity',
    'costInvoice',
    'costSupplier',
    'costProduct',
    'truck.entity',
    'driver',
    'ledgerCost',
    'ledgerCost.codeReports',
    'ledgerVat',
    'location',
    'activity.location',
];

@observer
export default class RoadCostEdit extends Component {
    static propTypes = {
        roadCost: PropTypes.instanceOf(RoadCost).isRequired,
        afterSave: PropTypes.func.isRequired,

        /**
         * Hide entity field. Useful when entity is given, and not needed
         * to fill in.
         */
        showEntity: PropTypes.bool.isRequired,

        /**
         * Hide supplier field. Useful when supplier is given, and not needed
         * to fill in.
         */
        showSupplier: PropTypes.bool.isRequired,

        /**
         * Disable nettOc / nettFc input fields. Also let net follow gross.
         */
        disableNet: PropTypes.bool.isRequired,

        /**
         * T25643: ledger vat calculation depends on entity and cost supplier.
         * In modal vue there is neither entity nor cost supplier.
         */
        getLedgerVat: PropTypes.bool.isRequired,

        /**
         * T25861
         */
        showHaveReceipt: PropTypes.bool.isRequired,
    };

    // Defaults should match how an edit without a cost invoice / non modal
    // should render.
    static defaultProps = {
        showEntity: true,
        showSupplier: false,
        disableNet: true,
        getLedgerVat: false,
        showHaveReceipt: true,
    };

    @observable entityStore = new EntityStore({ params: { order_by: 'name' } });
    @observable costProductStore = new CostProductStore({ params: {
        '.cost_type': 'road',
        order_by: 'description'
    } });
    @observable costSupplierStore = new CostSupplierStore({ params: { order_by: 'name' } });
    @observable driverStore = new DriverStore({ params: { order_by: 'name' } });
    @observable truckStore = new TruckStore({
        relations: ['entity'],
        params: { order_by: 'license_plate' },
        limit: null,
    });
    @observable ledgerCostStore = new LedgerCostStore({
        relations: ['entity', 'costProduct', 'codeReports', 'currentCodeReport'],
        params: { order_by: 'ledger_code_accounting' },
        limit: null,
    });
    @observable ledgerVatStore = new LedgerVatStore({
        relations: ['entity'],
        params: { order_by: 'ledger_code' },
        limit: null,
    });
    @observable fetchingActivity = false;

    constructor(...args) {
        super(...args);
        this.save = this.save.bind(this);
    }

    save() {
        const { roadCost, afterSave } = this.props;

        const isNew = roadCost.isNew;

        return roadCost
            .save({ onlyChanges: true })
            .then(showSaveNotification)
            .then(afterSave)
            .then(()=>{
                if(isNew){
                    showNotification({
                        type: 'info',
                        dismissAfter: 4000,
                        message: t('roadCost.previousId', {id: roadCost.id}),
                    });
                    roadCost.clear();
                }
            })
    };

    findLocation = () => {
        const { roadCost } = this.props;

        this.fetchingActivity = true;

        if (roadCost.location) {
            roadCost.location.clear();
        }

        fetchLocationByTruck(roadCost.truck.id, roadCost.transactionDatetime)
            .then(({ activity, location }) => {
                if (activity) {
                    roadCost.setInput('activity', activity);
                    roadCost.setInput('driver', activity.assignment.driver1);
                }

                if (location) {
                    roadCost.setInput('location', location);
                }
            })
            .catch(() => {}).then(() => this.fetchingActivity = false);
    }

    /**
     * Ledger Cost can be automatically attached based on entity and truck
     * category.
     */
    findLedgerCost = () => {
        const { roadCost } = this.props;
        this.ledgerCostStore.fetch()
        .then( ()=> {
            roadCost.setLedgerCost(this.ledgerCostStore);
        })
    }

    /**
     * Ledger Cost can be automatically attached based on entity and country.
     */
    findLedgerVat = () => {
        const { roadCost } = this.props;

        // T25630: Ledger VAT can be linked to transaction only if transaction is linked to the Invoice.
        if(roadCost.costInvoice.id){
            this.ledgerVatStore.fetch()
            .then( ()=> {
                roadCost.setLedgerVat(this.ledgerVatStore);
            })
        }
    }

    afterChangeFc = () => {
        const { roadCost } = this.props;

        if (roadCost.isOwnCurrency) {
            roadCost.syncOc();
        }

        roadCost.recalculateVat();
    }

    afterChangeGross = () => {
        const { roadCost, disableNet } = this.props;

        if (disableNet) {
            roadCost.syncNet();
        }

        roadCost.recalculateVat();
    }

    afterChangeCostSupplier = () => {
        const { roadCost } = this.props;

        roadCost.ledgerCost.clear();

        if(!roadCost.id){
            roadCost.costProduct.clear();
        }
        this.costProductStore.clear();
        this.costProductStore.params['.cost_product_supplier_codes.cost_supplier'] = roadCost.costSupplier.id;
        this.costProductStore.fetch();
    }

    renderForm() {
        const { roadCost, showEntity, showSupplier, disableNet, showHaveReceipt } = this.props;

        return (
            <Form>
                {/* <Form.Group widths="equal"> */}
                    {/* <TargetSelect remote fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="ledgerCost" */}
                    {/*     store={this.ledgerCostStore} */}
                    {/*     toOption={ledgerCost => ({ */}
                    {/*         text: `${ledgerCost.ledgerCodeAccounting} - ${ledgerCost.ledgerCodeReporting} - ${ledgerCost.assetTruckCategory}`, */}
                    {/*         value: ledgerCost.id, */}
                    {/*     })} */}
                    {/* /> */}
                {/* </Form.Group> */}
                <Form.Group widths="equal">
                    {showEntity && (
                        <TargetSelect remote fluid
                            target={roadCost}
                            name="entity"
                            store={this.entityStore}
                            toOption={entity => ({
                                text: entity.name,
                                value: entity.id,
                            })}
                            disabled={!roadCost.unassignable}
                            afterChange={() => {
                                this.findLedgerCost();
                            }}
                        />
                    )}
                    {showSupplier && (
                        <TargetSelect remote fluid
                            target={roadCost}
                            name="costSupplier"
                            store={this.costSupplierStore}
                            toOption={costSupplier => ({
                                text: costSupplier.name,
                                value: costSupplier.id,
                            })}
                            afterChange={() => {
                                this.findLedgerVat();
                            }}
                        />
                    )}
                    <TargetSelect remote fluid
                        target={roadCost}
                        name="costProduct"
                        store={this.costProductStore}
                        toOption={costProduct => ({
                            text: costProduct.description,
                            value: costProduct.id,
                        })}
                        afterChange={() => {
                            this.findLedgerCost();
                        }}
                    />
                    <TargetTextInput
                        target={roadCost}
                        name="originalProductDescription"
                        placeholder={t('roadCost.field.originalProductDescription.placeholder')}
                    />
                    {/* <TargetSelect remote fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="ledgerVat" */}
                    {/*     store={this.ledgerVatStore} */}
                    {/*     toOption={ledgerVat => ({ */}
                    {/*         text: `${ledgerVat.ledgerCode} - ${ledgerVat.country}`, */}
                    {/*         value: ledgerVat.id, */}
                    {/*     })} */}
                    {/* /> */}
                    {/* <TargetNumberInput fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="transactionId" */}
                    {/* /> */}
                    <TargetCurrencySelect fluid
                        target={roadCost}
                        name="fcCode"
                    />
                    <TargetRadioButtons
                        target={roadCost}
                        name="unassignable"
                        options={[
                            { value: false, text: t('form.no') },
                            { value: true, text: t('form.yes') },
                        ]}
                        afterChange={() => {
                            roadCost.truck.clear();
                            roadCost.ledgerCost.clear();
                            this.findLedgerCost();
                        }}
                    />
                    {showHaveReceipt && (
                        <TargetRadioButtons required data-test-road-cost-have-receipt
                            target={roadCost}
                            name="haveReceipt"
                            options={[
                                { value: false, text: t('form.no') },
                                { value: true, text: t('form.yes') },
                            ]}
                            afterChange={() => {
                                this.findLedgerCost();
                            }}
                        />
                    )}
                    {!showHaveReceipt && (
                        <TargetRadioButtons required data-test-road-cost-paid-by
                            target={roadCost}
                            name="paidBy"
                            options={[
                                { value: 'company', text: t('costSupplier.field.paidBy.company') },
                                { value: 'driver', text: t('costSupplier.field.paidBy.driver') },
                            ]}
                        />
                    )}
                </Form.Group>
                <Form.Group widths="equal">
                    {/* <TargetTextInput fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="originalProductDescription" */}
                    {/* /> */}
                    {/* <TargetTextInput fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="originalCardNumber" */}
                    {/* /> */}
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetSelect remote search fluid
                        target={roadCost}
                        name="truck"
                        store={this.truckStore}
                        toOption={truck => ({
                            value: truck.id,
                            text: truck.licensePlate
                        })}
                        afterChange={() => {
                            roadCost.entity = roadCost.truck.entity;
                            this.findLocation();
                            this.findLedgerCost();
                        }}
                        disabled={roadCost.unassignable}
                    />
                    {/* <TargetSelect remote fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="trailer" */}
                    {/*     store={this.trailerStore} */}
                    {/*     toOption={trailer => ({ */}
                    {/*         value: trailer.id, */}
                    {/*         text: trailer.licensePlate */}
                    {/*     })} */}
                    {/* /> */}
                    <TargetSelect remote fluid
                        target={roadCost}
                        name="driver"
                        store={this.driverStore}
                        toOption={driver => ({
                            value: driver.id,
                            text: driver.name
                        })}
                    />
                    <DatetimePicker fluid required
                        model={roadCost}
                        name="transactionDatetime"
                        afterChange={this.findLocation}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field width={3}>
                        <FormLabel required>{t('incident.field.activity.label')}</FormLabel>
                        {roadCost.activity.isNew
                            ? (
                                <React.Fragment>
                                    {/* {copy-paste-empty-activity} */}
                                    <StyledContainer data-test-empty-activity warning>
                                        <IconSide>
                                            ?
                                        </IconSide>
                                        <ContentSide>
                                            <Loader disabled={!this.fetchingActivity} inline="centered" />
                                        </ContentSide>
                                    </StyledContainer>
                                    {roadCost.__backendValidationErrors.activity && roadCost.__backendValidationErrors.activity.length > 0 && (
                                        <ErrorLabel>
                                            {roadCost.__backendValidationErrors.activity.map(error => (
                                                <div>{error}</div>
                                            ))}
                                        </ErrorLabel>
                                    )}
                                </React.Fragment>
                            ) : (
                                <ModalActivityFinalize
                                    activityId={roadCost.activity.id}
                                    viewStore={getViewStore()}
                                    trigger={props => (
                                        <Activity
                                            activity={roadCost.activity}
                                            backgroundColor={BACKGROUND_COLOR}
                                            {...props}
                                        />
                                    )}
                                />
                            )
                        }
                    </Form.Field>
                    <Form.Field width={13}>
                        <LocationEditFreeForm
                            onChange={(location) => {
                                // Prevent the old location from being used if the location is cleared
                                if (location.address === '') {
                                    location = null;
                                }
                                // Manually input address
                                roadCost.setInput('location', location);

                                this.findLedgerVat();
                            }}
                            changeOnEmpty={true}
                            label={t('activity.field.location.label')}
                            location={roadCost.location}
                            error={roadCost.backendValidationErrors.location}
                        />
                    </Form.Field>
                    {/* <TargetTextInput fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="originalAddress" */}
                    {/* /> */}
                    {/* <TargetTextInput fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="originalCountryCode" */}
                    {/* /> */}
                    {/* <TargetNumberInput fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="mautKm" */}
                    {/* /> */}
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetMoneyInput fluid allowNegative
                        target={roadCost}
                        name="netFc"
                        disabled={disableNet}
                        afterChange={this.afterChangeFc}
                    />
                    <TargetMoneyInput fluid allowNegative
                        target={roadCost}
                        name="grossFc"
                        afterChange={() => {
                            this.afterChangeFc();
                            this.afterChangeGross();
                        }}
                    />
                    <TargetMoneyInput fluid disabled allowNegative
                        target={roadCost}
                        name="vatFc"
                    />
                    <TargetSelect fluid disabled remote search
                        target={roadCost}
                        name="ledgerVat"
                        store={this.ledgerVatStore}
                        toOption={lv => ({
                            value: lv.id,
                            text: lv.getLedgerVatText(),
                        })}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetMoneyInput fluid allowNegative
                        target={roadCost}
                        name="netOc"
                        disabled={roadCost.isOwnCurrency || disableNet}
                        afterChange={() => roadCost.recalculateVat()}
                    />
                    <TargetMoneyInput fluid allowNegative
                        target={roadCost}
                        name="grossOc"
                        disabled={roadCost.isOwnCurrency}
                        afterChange={() => this.afterChangeGross()}
                    />
                    <TargetMoneyInput fluid disabled allowNegative
                        target={roadCost}
                        name="vatOc"
                    />
                    <TargetSelect fluid disabled remote search
                        target={roadCost}
                        name="ledgerCost"
                        store={this.ledgerCostStore}
                        toOption={lc => ({
                            value: lc.id,
                            text: lc.getLedgerCostText(roadCost.haveReceipt, roadCost.transactionDatetime),
                        })}
                    />
                </Form.Group>
                {/* <TargetNumberInput allowDecimal disabled */}
                {/*     target={roadCost} */}
                {/*     name="vatPercentage" */}
                {/*     decimalLimit={2} */}
                {/* /> */}
            </Form>
        );
    }

    render() {
        const { roadCost } = this.props;

        return (
            <Body>
                <ContentContainer>
                    <Content>
                        <Header as="h1">
                            {roadCost.id
                                ? t('roadCost.edit.title')
                                : t('roadCost.create.title')}
                            {roadCost.costInvoice.id && (
                                <Link to={`/administration/cost-invoice/${roadCost.costInvoice.id}/edit` } icon >
                                    <IconButton title="View related Cost invoice" name='eye'/>
                                </Link>
                            )}
                        </Header>
                        {this.renderForm()}
                    </Content>
                </ContentContainer>
                <Toolbar>
                    <RightDivider />
                    {!!roadCost.finalizedAt && (
                        <SaveButton compact data-test-unfinalize-button
                            color="purple"
                            content={t('form.unfinalizeButton')}
                            onClick={()=>roadCost.unfinalize()}
                            loading={roadCost.isLoading}
                        />
                    )}
                    <SaveButton primary compact data-test-save-and-finalize-button
                        content={t('costInvoice.edit.saveAndFinalizeButton')}
                        onClick={()=>this.save().then(()=>roadCost.finalize())}
                        loading={roadCost.isLoading}
                        disabled={!roadCost.ledgerCost.id || (roadCost.location && !roadCost.location.id)}
                    />

                    <Popup hoverable
                        content={!roadCost.ledgerCost.id? t('roadCost.create.ledgerCostWarning'):((roadCost.location && !roadCost.location.id) && t('roadCost.create.locationWarning'))}
                        trigger={
                            <span data-test-save-button-container>
                                <SaveButton primary compact
                                    onClick={this.save}
                                    loading={roadCost.isLoading}
                                    content={roadCost.id?t('roadCost.create.saveButton'):t('roadCost.create.saveAndNewButton')}
                                    disabled={!roadCost.ledgerCost.id || (roadCost.location && !roadCost.location.id)}
                                />
                            </span>}
                    />
                </Toolbar>
            </Body>
        );
    }
}

@observer
export class EditModal extends RoadCostEdit {
    static propTypes = {
        roadCost: PropTypes.instanceOf(RoadCost).isRequired,
        afterSave: PropTypes.func.isRequired,
        trigger: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...RoadCostEdit.defaultProps,
        showEntity: false,
        showSupplier: false,
        disableNet: false,
        getLedgerVat: true,
        showHaveReceipt: false,
        trigger(props) {
            return <EditButton {...props} />;
        },
    }

    constructor(...args) {
        super(...args);
        this.save = this.save.bind(this);
    }

    @observable isOpen = false;
    open = () => this.isOpen = true;
    close = () => this.isOpen = false;

    save() {
        const { afterSave } = this.props;

        super.save()
            .then(afterSave)
            .then(this.close);
    }

    saveAndFinalize() {
        const { afterSave, roadCost } = this.props;

        super.save()
            .then(()=>roadCost.finalize())
            .then(afterSave)
            .then(this.close);
    }

    render() {
        const { roadCost, trigger, ...props } = this.props;

        return (
            <Modal {...props}
                centered={false}
                open={this.isOpen}
                onClose={this.close}
                trigger={trigger({ onClick: this.open })}
            >
                <Modal.Content>
                    {this.renderForm()}
                </Modal.Content>
                <Modal.Actions>
                    <CancelButton onClick={this.close} />
                    <RightDivider />
                    <Popup hoverable
                        content={!roadCost.ledgerCost.id? t('roadCost.create.ledgerCostWarning'):((roadCost.location && !roadCost.location.id) && t('roadCost.create.locationWarning'))}
                        trigger={
                            <span data-test-save-button>
                                <SaveButton primary compact
                                    onClick={this.save}
                                    loading={roadCost.isLoading}
                                    disabled={!roadCost.ledgerCost.id || (roadCost.location && !roadCost.location.id)}
                                />
                            </span>}
                    />
                    <SaveButton primary compact data-test-save-and-finalize-button
                        content={t('costInvoice.edit.saveAndFinalizeButton')}
                        onClick={()=>this.saveAndFinalize()}
                        loading={roadCost.isLoading}
                        disabled={!roadCost.ledgerCost.id || (roadCost.location && !roadCost.location.id)}
                    />
                    {!!roadCost.finalizedAt && (
                        <SaveButton compact data-test-unfinalize-button
                            color="purple"
                            content={t('form.unfinalizeButton')}
                            onClick={()=>roadCost.unfinalize()}
                            loading={roadCost.isLoading}
                        />
                    )}
                </Modal.Actions>
            </Modal>
        );
    }
}
