import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Store } from 'store/Base';
import { TYPES } from 'store/Claim/Document';
import { Accordion, Button } from 're-cy-cle';
import { TargetFile, TargetSelect } from 'spider/semantic-ui/Target';
import { IconDelete } from 're-cy-cle';
import { format } from 'helpers/date';
import { DATE_FORMAT } from 'helpers';
import { camelCase, sortBy } from 'lodash';
import RightDivider from 'spider/component/RightDivider';
import { AddButton } from 'spider/semantic-ui/Button';
import HeaderRight from 'spider/semantic-ui/HeaderRight';
import moment from 'moment';
import Dropzone from 'component/Dropzone';
import { TYPE_EVIDENCE } from 'store/Incident/Document';

/**
 * This component is used by both claim documents and incident documents. They
 * are copy paste atm, so we take a shortcut here because I wrote it in Poznan
 * at Friday 17:00.
 */
@observer
export default class DocumentList extends Component {
    static propTypes = {
        documentStore: PropTypes.instanceOf(Store).isRequired,
    };

    @observable openItems = [];

    accordionChange = value => {
        if (this.openItems.includes(value)) {
            this.openItems.clear();
        } else {
            this.openItems.clear();
            this.openItems.push(value);
        }
    };

    renderDocument = doc => {
        return (
            <Accordion data-test-claim-status-change
                key={doc.cid}
                title={(
                    <div data-test-claim-status-change style={{ display: 'flex' }}>
                        <div data-test-status>{t(`claimDocument.field.type.value.${camelCase(doc.type)}`)}</div>
                        <RightDivider />
                        <div data-test-date>{format(doc.date, DATE_FORMAT)}</div>
                    </div>
                )}
                opened={this.openItems.includes(doc.cid)}
                onChange={() => this.accordionChange(doc.cid)}
                action={
                    <Button icon onClick={() => this.handleDelete(doc)}>
                        <IconDelete />
                    </Button>
                }
            >
                <TargetSelect required
                    target={doc}
                    name="type"
                    options={sortBy(TYPES.map(type => ({
                        value: type,
                        text: t(`claimDocument.field.type.value.${camelCase(type)}`),
                    })), (entry) => entry.text )}
                />
                <TargetFile noLabel
                    target={doc}
                    name="file"
                />
                {!doc.file && doc.type === TYPE_EVIDENCE &&
                    <Dropzone
                        onDrop={ files => {
                            if (files.length === 1) {
                                // bug in mobx ==> file does not go through casting if assigned directly.
                                doc.setInput('file', files[0]);
                            }
                        }}
                        multiple={false}
                    >
                        {t('claimDocument.field.dropText')}
                    </Dropzone>
                }
            </Accordion>
        );
    };

    handleDelete = contract => {
        this.props.documentStore.remove(contract);
    };

    addStatusChange = () => {
        const model = this.props.documentStore.add({
            date: moment(),
        });

        this.accordionChange(model.cid);
    };

    render() {
        const { documentStore } = this.props;

        return (
            <div data-test-document-list>
                <HeaderRight content={t('incident.edit.documents.header')}>
                    <AddButton data-test-add-claim-status-change-button compact primary
                        onClick={this.addStatusChange}
                    />
                </HeaderRight>
                {documentStore.filter(d => !d.deleted).map(this.renderDocument)}
            </div>
        );
    }
}
