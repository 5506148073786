import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Body, ContentContainer, Content, Toolbar } from 're-cy-cle';
import RightDivider from 'spider/component/RightDivider';
import { Form, Header, Segment, Feed, Card, Grid, Radio, Loader } from 'semantic-ui-react';
import { Incident, STATUS_FINALIZED, FOLLOWUP_ACTIONS, TYPES as INCIDENT_TYPES } from 'store/Incident';
import { IncidentComment, IncidentCommentStore } from 'store/Incident/Comment';
import { EntityStore } from 'store/Entity';
import { User, UserStore } from 'store/User';
import { TruckStore } from 'store/Truck';
import { fetchLocationByTruck } from 'store/Location';
import { TruckPositionStore } from 'store/TruckPosition';
import { ActivityStore } from 'store/Activity';
import { Claim, TYPES, TYPE_THIRD_PARTY } from 'store/Claim';
import { History } from 'store/History';
import { IncidentFollowup } from 'store/Incident/Followup';
import { FormLabel, ErrorLabel, TargetNumberInput, TargetTextInput, TargetTextArea, TargetSelect, TargetRadioButtons, TargetDatePicker } from 'spider/semantic-ui/Target';
import { AddButton, SaveButton } from 'spider/semantic-ui/Button';
import HeaderRight from 'spider/semantic-ui/HeaderRight';
import { DATETIME_FORMAT } from 'helpers';
import { currentUser } from 'helpers/permission';
import { showNotification } from 'helpers/notification';
import FeedSummary from 'component/SemanticUi/FeedSummary';
import { format } from 'helpers/date';
import styled from 'styled-components';
import { FeedUserAvatar } from 'component/UserAvatar';
import { first, camelCase } from 'lodash';
import DatetimePicker from 'component/form/DatetimePicker';
import ClaimActionList from './Edit/ClaimActionList';
import DocumentList from './Edit/DocumentList';
import HistoryItem from 'component/History/Item';
import TargetWysiwygInput from 'component/TargetWysiwygInput';
import moment from 'moment';
import ModalActivityFinalize from 'screen/ModalActivityFinalize';
import Activity, { Container, BACKGROUND_COLOR } from 'component/Activity';
import { IconSide, ContentSide } from 'component/Compact/ActivityBlock';
import LocationEditFreeForm from 'container/LocationEditFreeForm';
import TargetMoneyInput from 'component/TargetMoneyInput';
import { CONTEXT_TYPE_INCIDENT, SUBJECT_TYPE_DRIVER } from 'store/BEPTicket';
import BEPContextButtons from 'component/BEPTicket/BEPContextButtons';
import { TYPE_EVIDENCE } from 'store/Incident/Document';
import { CustomDroppableButton } from 'screen/FuelCost/Overview';
import axios from 'axios';

const StyledContainer = styled(Container)`
    justify-content: center;
    align-items: center;
`;

const StyledContent = styled(Content)`
    main {
        padding-bottom: 0px !important;
    }
`;

// Mostly copy paste from Content to center.
const StickyFeed = styled(Feed)`
    margin: 0 auto !important;
    padding-left: calc(1em + 25px);
    padding-right: calc(1em + 25px);
    padding-bottom: 1em;
    padding-top: 1em;
    width: 1500px;
    display: block;
`;

const StyledReadOnlyTargetWysiwygInput = styled(TargetWysiwygInput)`
    .DraftEditor-root {
        border-color: transparent !important;
    }
`;

const NoMinWidthTargetSelect = styled(TargetSelect)`
    .ui.selection.dropdown {
        min-width: unset !important;
    }
`;

const StyledTargetRadioButtons = styled(TargetRadioButtons)`
    div.ui.primary.buttons {
        box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset;
        border-radius: .28571429rem;
    }

    .ui.primary.buttons .button:not(.active) {
        background-color: transparent;
        color: inherit;
    }
`;


class ReadOnlyTargetTextInput extends TargetTextInput {
    static defaultProps = {
        ...TargetTextInput.defaultProps,
        disabled: true,
        fluid: true,
    }
}

const StyledReadOnlyTargetTextInput = styled(ReadOnlyTargetTextInput)`
    .ui.disabled.input {
        opacity: 0.60;

        input {
            opacity: 1;
        }
    }
`;

class ReadOnlyTargetDatePicker extends TargetDatePicker {
    static defaultProps = {
        ...TargetTextInput.defaultProps,
        disabled: true,
        fluid: true,
    }
}

const StyledReadOnlyTargetDatePicker = styled(ReadOnlyTargetDatePicker)`
    .ui.disabled.input {
        opacity: 0.60;

        input {
            opacity: 1;
        }
    }
`;

class ReadOnlyTargetMoneyInput extends TargetMoneyInput {
    static defaultProps = {
        ...TargetMoneyInput.defaultProps,
        disabled: true,
        fluid: true,
        toFixed: true,
    }
}

const StyledReadOnlyTargetMoneyInput = styled(ReadOnlyTargetMoneyInput)`
    .ui.disabled.input {
        opacity: 0.60;

        input {
            opacity: 1;
        }
    }
`;

const ClaimCard = styled(Card)`
    width: 400px !important;
`;

@currentUser
@observer
class FollowupItem extends Component {
    static propTypes = {
        followup: PropTypes.instanceOf(IncidentFollowup).isRequired,
        afterSave: PropTypes.func.isRequired,
        currentUser: PropTypes.instanceOf(User).isRequired,
    };

    @observable userStore = new UserStore({ params: { order_by: 'first_name' } });

    render() {
        const { followup } = this.props

        return (
            <Feed.Event key={followup.id}>
                <FeedUserAvatar user={followup.performedBy} />
                <Feed.Content style={{ marginTop: '0px', borderLeft: '1px solid #d5d8e1', border: '1px solid #d5d8e1', borderRadius: '3px' }}>
                    <FeedSummary own={followup.performedBy.id === currentUser.id}>
                        <Feed.User>{followup.createdBy.fullName}</Feed.User>
                        <RightDivider />
                        <Feed.Date>{followup.createdAt.format(DATETIME_FORMAT)}</Feed.Date>
                    </FeedSummary>
                    <Feed.Extra style={{ padding: '5px', margin: '0px' }}>
                        <Form.Group>
                            <TargetSelect select remote
                                width={6}
                                target={followup}
                                name="performedBy"
                                store={this.userStore}
                                toOption={user => ({
                                    value: user.id,
                                    text: user.fullName,
                                })}
                            />
                            <DatetimePicker fluid
                                model={followup}
                                name="performedAt"
                            />
                            <TargetSelect
                                target={followup}
                                name="description"
                                options={FOLLOWUP_ACTIONS.map(action => ({
                                    value: action,
                                    text: action,
                                }))}
                            />
                        </Form.Group>
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

@observer
class ClaimItem extends Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        claim: PropTypes.instanceOf(Claim).isRequired,
        afterSave: PropTypes.func.isRequired,
    };

    render() {
        const { index, claim } = this.props

        return (
            <ClaimCard data-test-claim-item={claim.id || claim.cid}>
                 <Card.Content>
                    <Card.Header>{(index + 10).toString(36).toUpperCase()}: {t(`incident.field.status.value.${camelCase(claim.status)}`) }</Card.Header>
                    <Card.Description>
                        <StyledTargetRadioButtons primary required
                            target={claim}
                            name="type"
                            options={TYPES.map(type => ({
                                text: t(`claim.field.type.value.${camelCase(type)}`),
                                value: type,
                            }))}
                        />
                        <Form.Group widths="equal">
                            <TargetDatePicker required fluid
                                target={claim}
                                name="claimDate"
                            />
                            <TargetNumberInput fluid
                                target={claim}
                                name="instance"
                            />
                        </Form.Group>
                        <TargetTextInput
                            label={t(`claim.field.${claim.type === TYPE_THIRD_PARTY ? 'reference.thirdPartyLabel' : 'reference.label'}`)}
                            target={claim}
                            name="reference"
                        />
                        <Form.Group widths="equal">
                            <TargetMoneyInput fluid
                                target={claim}
                                name="cost"
                            />
                            <TargetMoneyInput fluid
                                target={claim}
                                name="ownRisk"
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <StyledReadOnlyTargetMoneyInput
                                target={claim}
                                name="net"
                            />
                            <StyledReadOnlyTargetDatePicker
                                target={claim}
                                name="paymentDate"
                            />
                        </Form.Group>
                        <DocumentList documentStore={claim.documents} />
                        <ClaimActionList
                            claimActionStore={claim.actions}
                        />
                    </Card.Description>
                  </Card.Content>
            </ClaimCard>
        );
    }
}

@observer
class IncidentCommentItem extends Component {
    static propTypes = {
        incidentComment: PropTypes.instanceOf(IncidentComment).isRequired,
        afterSave: PropTypes.func.isRequired,
        currentUser: PropTypes.instanceOf(User).isRequired,
    };

    render() {
        const { incidentComment } = this.props;

        return (
            <Feed.Event key={incidentComment.id}>
                <FeedUserAvatar user={incidentComment.createdBy} />
                <Feed.Content style={{ marginTop: '0px', borderLeft: '1px solid #d5d8e1', border: '1px solid #d5d8e1', borderRadius: '3px' }}>
                    <FeedSummary own={incidentComment.createdBy.id === currentUser.id}>
                        <Feed.User>{incidentComment.createdBy.fullName}</Feed.User>
                        <RightDivider />
                        <Feed.Date>{incidentComment.createdAt.format(DATETIME_FORMAT)}</Feed.Date>
                    </FeedSummary>
                    <Feed.Extra style={{ padding: '5px', margin: '0px' }}>
                        <StyledReadOnlyTargetWysiwygInput readOnly
                            value={incidentComment.content}
                        />
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}

@currentUser
@observer
export default class IncidentEdit extends Component {
    static propTypes = {
        incident: PropTypes.instanceOf(Incident).isRequired,
        afterSave: PropTypes.func.isRequired,
        currentUser: PropTypes.instanceOf(User).isRequired,
        viewStore: PropTypes.object.isRequired,
    };

    @observable userStore = new UserStore({ params: { order_by: 'first_name' } });
    @observable fetchingActivity = false;
    @observable showSystemHistory = false;
    @observable entityStore = new EntityStore({ params: { order_by: 'name' } });
    @observable truckPositionStore = new TruckPositionStore();
    @observable activityStore = new ActivityStore({ relations: ['location', 'assignment.driver1', 'assignment.driver2']});
    @observable incidentComment = new IncidentComment();
    @observable incidentCommentStore = new IncidentCommentStore({
        relations: ['createdBy'],
        params: { order_by: '-created_at', '.incident': this.props.incident.id }
    });
    @observable truckStore = new TruckStore({
        params: { order_by: 'license_plate' }
    });

    componentDidMount() {
        const { incident } = this.props;

        if (!incident.isNew) {
            this.incidentCommentStore.fetch();
            incident.fetchHistory();
        }

        this.entityReactionDisposer = reaction(
            () => incident.entity.id,
            id => {
                if (id) {
                    this.truckStore.params['.entity'] = id;
                } else {
                    delete this.truckStore.params['.entity'];
                }

                if (id !== incident.activity.assignment.truck.entity.id) {
                    incident.activity.clear();
                    incident.activity.assignment.truck.clear();
                }

                if (this.cancelTokenTruck) {
                    this.cancelTokenTruck();
                }

                this.truckStore.fetch({
                    cancelToken: new axios.CancelToken(c => {
                        this.cancelTokenTruck = c;
                    }),
                });
            },
            { fireImmediately: true }
        )
    }

    componentWillUnmount() {
        this.entityReactionDisposer();
    }

    toggleSystemHistory = () => {
        this.showSystemHistory = !this.showSystemHistory;
    }

    save = () => {
        const { incident, afterSave } = this.props;

        if (incident.activity.isNew) {
            incident.__backendValidationErrors.activity = [t('form.validationErrors.blank')];
            showNotification({ type: 'info', message: t('form.notifications.saveValError') });
        } else {
            incident
                .saveAll({ onlyChanges: true, relations: ['claims.documents', 'claims.actions', 'documents'] })
                .then(() => {
                    if (this.incidentComment.content !== '<p></p>') {
                        return this.incidentComment.save({ data: { incident: incident.id }}).then(() => {
                            this.incidentComment.clear();
                        });
                    }
                })
                .then(afterSave)
                .then(() => {
                    incident.fetch();
                    incident.fetchHistory();
                    this.incidentCommentStore.params['.incident'] = incident.id; // Initial save of new incident sets id
                    this.incidentCommentStore.fetch();
                });
        }

    };

    renderClaims() {
        const { incident } = this.props;
        let content = t('incident.edit.claims.empty');

        if (incident.claims.length !== 0) {
            content = (
                <Card.Group>
                    {incident.claims.map((claim, i) => (
                        <ClaimItem
                            key={claim.id}
                            index={i}
                            claim={claim}
                        />
                    ))}
                </Card.Group>
            );
        }

        return (
            <Segment data-test-claims>
                <React.Fragment>
                    <HeaderRight as="h2" content={t('incident.edit.claims.header')}>
                        <AddButton data-test-add-claim-button primary onClick={() => incident.claims.add({})} />
                    </HeaderRight>
                    {content}
                </React.Fragment>
            </Segment>
        );
    }

    renderHistory() {
        const { incident, currentUser } = this.props;
        const interleaved = this.showSystemHistory ? [...incident._history.models, ...this.incidentCommentStore.models] : this.incidentCommentStore.models.slice();

        interleaved.sort((a, b) => {
            if (a.createdAt > b.createdAt) {
                return -1;
            }

            if (a.createdAt < b.createdAt) {
                return 1;
            }

            return a.id > b.id;
        });

        return (
            <Segment data-test-history-segment>
                <Radio toggle data-test-toggle-history-button
                    label={t('incident.edit.toggleSystemHistory')}
                    onChange={() => this.toggleSystemHistory()}
                    value={this.showSystemHistory}
                />
                <Feed>
                    {interleaved.map(commentOrHistory => {
                        if (commentOrHistory instanceof IncidentComment) {
                            return (
                                <IncidentCommentItem
                                    currentUser={currentUser}
                                    incidentComment={commentOrHistory}
                                />
                            );
                        }

                        if (commentOrHistory instanceof History) {
                            return (
                                <HistoryItem
                                    currentUser={currentUser}
                                    history={commentOrHistory}
                                />
                            );
                        }
                    })}
                </Feed>
            </Segment>
        );
    }

    renderFollowups() {
        const { incident, currentUser } = this.props;

        return (
            <Segment data-test-followups>
                <HeaderRight as="h2" content={t('incident.edit.followups.header')}>
                    <AddButton data-test-add-followup-button primary onClick={() => incident.followups.add({
                        createdAt: moment(),
                        createdBy: currentUser.toJS(),
                        performedAt: moment(),
                        performedBy: currentUser.toJS(),
                    })} />
                </HeaderRight>
                <Feed>
                    {incident._history.length === 0
                        ? t('incident.edit.followups.empty')
                        : incident.followups.map(followup =>
                            <FollowupItem followup={followup} />
                        )
                    }
                </Feed>
            </Segment>
        );
    }

    fetchLocation = () => {
        const { incident } = this.props;

        fetchLocationByTruck(incident.activity.assignment.truck.id, incident.incidentDatetime)
            .then(({ activity, location }) => {
                if (activity) {
                    incident.setInput('activity', activity);
                }

                if (location) {
                    incident.setInput('location', location);
                }
            })
            .catch(() => {}).then(() => this.fetchingActivity = false);
    }

    render() {
        const { incident, currentUser, viewStore } = this.props;
        const lastChange = first(incident._history.models);
        const createdAt = incident.createdAt ? format(incident.createdAt, DATETIME_FORMAT) : '';

        return (
            <Body>
                <ContentContainer>
                    <StyledContent>
                        <Form>
                            <Segment>
                                <Header as="h1">
                                    {incident.id
                                        ? t('incident.edit.title', { id: incident.id, status: incident.statusTranslated })
                                        : t('incident.create.title')}
                                </Header>
                                {/* Spider TargetDatePicker + TargetTimePicker still lack mask + tests. */}
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <Form.Group widths="equal">
                                            <StyledReadOnlyTargetTextInput
                                                label={t('incident.field.createdBy.label')}
                                                target={incident.createdBy}
                                                name="fullName"
                                            />
                                            <StyledReadOnlyTargetTextInput
                                                label={t('incident.field.createdAt.label')}
                                                name="createdAt"
                                                value={createdAt}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <TargetSelect remote
                                                target={incident}
                                                name="entity"
                                                store={this.entityStore}
                                                options={this.entityStore.models.filter(entity => !!currentUser.entities.get(entity.id)).map(entity => ({
                                                    text: entity.name,
                                                    value: entity.id,
                                                }))}
                                                width={8}
                                            />
                                            <TargetSelect
                                                target={incident}
                                                name="type"
                                                options={INCIDENT_TYPES.map(type => ({
                                                    text: t(`incident.field.type.value.${camelCase(type)}`),
                                                    value: type,
                                                }))}
                                                width={5}
                                            />
                                            <TargetMoneyInput toFixed
                                                target={incident}
                                                name="penalty"
                                                width={3}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <NoMinWidthTargetSelect remote search skipFetch
                                                target={incident.activity.assignment}
                                                name="truck"
                                                store={this.truckStore}
                                                searchKey=".license_plate:icontains"
                                                toOption={truck => ({
                                                    value: truck.id,
                                                    text: truck.licensePlate,
                                                })}
                                                width={3}
                                            />
                                            <DatetimePicker required
                                                model={incident}
                                                name="incidentDatetime"
                                                width={5}
                                                afterChange={this.fetchLocation}
                                            />
                                            <StyledReadOnlyTargetTextInput
                                                label={t('assignment.field.driver1.label')}
                                                subLabel={<BEPContextButtons fitted
                                                    size="small"
                                                    disabled={!incident.activity.assignment.driver1}
                                                    currentUser={currentUser}
                                                    entity={incident.entity}
                                                    contextType={CONTEXT_TYPE_INCIDENT}
                                                    contextIncident={incident}
                                                    subjectToType={SUBJECT_TYPE_DRIVER}
                                                    subjectToDriver={incident.activity.assignment.driver1}
                                                />}
                                                target={incident.activity.assignment.driver1}
                                                name="name"
                                                width={4}
                                            />
                                            <StyledReadOnlyTargetTextInput
                                                label={t('assignment.field.driver2.label')}
                                                subLabel={<BEPContextButtons fitted
                                                    size="small"
                                                    disabled={!incident.activity.assignment.driver2}
                                                    currentUser={currentUser}
                                                    entity={incident.entity}
                                                    contextType={CONTEXT_TYPE_INCIDENT}
                                                    contextIncident={incident}
                                                    subjectToType={SUBJECT_TYPE_DRIVER}
                                                    subjectToDriver={incident.activity.assignment.driver2}
                                                />}
                                                target={incident.activity.assignment.driver2}
                                                name="name"
                                                width={4}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Field width={5}>
                                                <FormLabel required>{t('incident.field.activity.label')}</FormLabel>
                                                {incident.activity.isNew
                                                    ? (
                                                        <React.Fragment>
                                                            {/* {copy-paste-empty-activity} */}
                                                            <StyledContainer data-test-empty-activity warning>
                                                                <IconSide>
                                                                    ?
                                                                </IconSide>
                                                                <ContentSide>
                                                                    <Loader disabled={this.fetchingActivity} inline="centered" />
                                                                </ContentSide>
                                                            </StyledContainer>
                                                            {incident.__backendValidationErrors.activity && incident.__backendValidationErrors.activity.length > 0 && (
                                                                <ErrorLabel>
                                                                    {incident.__backendValidationErrors.activity.map(error => (
                                                                        <div>{error}</div>
                                                                    ))}
                                                                </ErrorLabel>
                                                            )}
                                                        </React.Fragment>
                                                    ) : (
                                                        <ModalActivityFinalize
                                                            activityId={incident.activity.id}
                                                            viewStore={viewStore}
                                                            trigger={props => (
                                                                <Activity
                                                                    activity={incident.activity}
                                                                    backgroundColor={BACKGROUND_COLOR}
                                                                    {...props}
                                                                />
                                                            )}
                                                        />
                                                    )
                                                }
                                            </Form.Field>
                                            {incident.status !== STATUS_FINALIZED && (
                                                <React.Fragment>
                                                    <TargetSelect select remote required
                                                        width={6}
                                                        target={incident}
                                                        name="followupActionTaker"
                                                        store={this.userStore}
                                                        toOption={user => ({
                                                            value: user.id,
                                                            text: user.fullName,
                                                        })}
                                                    />
                                                    <TargetDatePicker required
                                                        width={5}
                                                        target={incident}
                                                        name="followupActionDate"
                                                        autoComplete="off"
                                                    />
                                                </React.Fragment>
                                            )}
                                        </Form.Group>
                                        <Form.Group>
                                            <StyledTargetRadioButtons primary
                                                width={4}
                                                target={incident}
                                                name="hasSurveyor"
                                                options={[
                                                    {
                                                        text: t('form.no'),
                                                        value: false,
                                                    }, {
                                                        text: t('form.yes'),
                                                        value: true,
                                                    },
                                                ]}
                                            />
                                            {incident.hasSurveyor && (
                                                <React.Fragment>
                                                    <TargetTextInput
                                                        width={6}
                                                        target={incident}
                                                        name="surveyorName"
                                                    />
                                                    <TargetTextInput
                                                        width={6}
                                                        target={incident}
                                                        name="surveyorPhoneNumber"
                                                    />
                                                    <TargetDatePicker
                                                        width={4}
                                                        target={incident}
                                                        name="surveyorDate"
                                                    />
                                                </React.Fragment>
                                            )}
                                        </Form.Group>
                                        <CustomDroppableButton data-test-drop-files
                                            name="uploadEvidences"
                                            onDrop={files => files.forEach(file =>
                                                incident.documents.add({
                                                    type: TYPE_EVIDENCE,
                                                    file,
                                                })
                                            )}
                                            title={t('claimDocument.field.multipleDropText')}
                                        />
                                        <DocumentList documentStore={incident.documents} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Group widths="equal">
                                            <StyledReadOnlyTargetTextInput data-test-last-changed-by
                                                label={t('incident.field.updatedBy.label')}
                                                target={lastChange ? lastChange.user : incident.createdBy}
                                                name="fullName"
                                            />
                                            <StyledReadOnlyTargetTextInput
                                                label={t('incident.field.updatedAt.label')}
                                                name="updatedAt"
                                                value={lastChange ? format(lastChange.date, DATETIME_FORMAT) : createdAt}
                                            />
                                        </Form.Group>
                                        <Form.Group widths="equal">
                                            <StyledReadOnlyTargetMoneyInput
                                                target={incident}
                                                name="cost"
                                            />
                                            <StyledReadOnlyTargetMoneyInput
                                                target={incident}
                                                name="ownRisk"
                                            />
                                            <StyledReadOnlyTargetMoneyInput
                                                target={incident}
                                                name="net"
                                            />
                                        </Form.Group>
                                        <TargetTextInput
                                            target={incident}
                                            name="reference"
                                        />
                                        <TargetTextArea required
                                            target={incident}
                                            name="description"
                                        />
                                        <LocationEditFreeForm
                                            onChange={(location) => {
                                                // Prevent the old location from being used if the location is cleared
                                                if (location.address === '') {
                                                    location = null;
                                                }
                                            }}
                                            changeOnEmpty={true}
                                            label={t('activity.field.location.label')}
                                            location={incident.location}
                                            error={incident.backendValidationErrors.location}
                                        />
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                            {this.renderClaims()}
                            {/*this.renderFollowups()*/}
                            {this.renderHistory()}
                        </Form>
                    </StyledContent>
                </ContentContainer>
                <StickyFeed>
                    <Feed.Event>
                        <FeedUserAvatar user={currentUser} />
                        <Feed.Content style={{ marginTop: '0px', borderLeft: '1px solid #d5d8e1', border: '1px solid #d5d8e1', borderRadius: '3px' }}>
                            <FeedSummary own>
                                <b>{t('incidentComment.addCommentLabel')}</b>
                                <RightDivider />
                                <Feed.Date>{moment().format(DATETIME_FORMAT)}</Feed.Date>
                            </FeedSummary>
                            <Feed.Extra style={{ padding: '5px', margin: '0px' }}>
                                <TargetWysiwygInput noLabel
                                    target={this.incidentComment}
                                    name="content"
                                />
                            </Feed.Extra>
                        </Feed.Content>
                    </Feed.Event>
                </StickyFeed>
                <Toolbar>
                    <RightDivider />
                    <SaveButton primary compact
                        loading={incident.isLoading}
                        onClick={this.save}
                    />
                </Toolbar>
            </Body>
        );
    }
}
