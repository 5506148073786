export default {
    crash: {
        heading: 'Application crashed',
        subHeading1: "We’re very sorry, but the application has crashed.",
        subHeading2: 'Our team has been notified.',
        reload: 'Reload page'
    },
    enterHtml: 'Use HTML for markup',
    brandingMessage: 'Powered by Code Yellow',
    accordionEdit: {
        new: 'New',
    },
    tooltips: {
        view: 'View',
        edit: 'Edit',
        delete: 'Delete',
    },
    debug: {
        title: 'Debug options',
        label: 'Debug mode',
        enabled: 'Enabled',
        disabled: 'Disabled',
    },
    startError: {
        maintenance: 'Server maintenance is being performed right now.',
    },
    nav: {
        main: {
            operation: 'Operation',
            administration: 'Administration',
            driverCare: 'Asset',
            archive: 'Archive',
            masterdata: 'Masterdata',
            admin: 'Admin',
            manageFleetvisorMailbox: 'Fleetvisor connection lagging behind: {{count}}',

            bep: {
                idea: 'Submit a BEP idea',
                negative: 'Supply negative BEP feedback',
                positive: 'Supply positive BEP feedback',
                ownTickets: 'Your own BEP tickets',
            },
        },
        operation: {
            activityPlanning: 'Planning',
            dispatcher: 'Dispatchers',
            assetPlanning: 'Trucks & Drivers',
            maps: 'Maps',
            dispute: 'Your Disputes requests'
        },
        archive: {
            activities: 'Activities',
            messages: 'Messages',
            truckPositions: 'Truck Positions',
            costInvoiceOverview: 'Cost Invoices',
            fuelCostOverview: 'Fuel Costs',
            roadCostOverview: 'Road Costs',
        },
        administration: { // This is the invoice administration/customer service stuff
            invoiceOverview: 'Invoicing',
            creditOverview: 'Credit',
            csEmployee: 'CS Employees',
            bepTickets: 'BEP tickets',
            markedActivities: 'Disputes',
            entityInvoices: 'Entity invoicing',
            costOverview: 'Cost registration',
            // TODO: Cost registration
            exactAuth: 'Authorize TMS to connect with Exact', // Special (icon)
        },
        masterdata: {
            customerOverview: 'Customers',
            driverOverview: 'Drivers',
            trailerOverview: 'Trailers',
            truckOverview: 'Trucks',
            rejectionReason: 'Rejection Reason',
            driverBonus: 'Driver Bonus',
            supplierOverview: 'Suppliers',
            entityOverview: 'Entities',
            fleetvisorFormOverview: 'Fleetvisor forms',
            transicsFormOverview: 'Transics forms',
            homebaseOverview: 'Homebases',
            petrolStationOverview: 'Petrol Stations',
            conditionInstructionOverview: 'Condition Instructions',
            equipmentTypeOverview: 'Equipment Types',
            bepTicketCategories: 'BEP ticket categories',
            bepTicketSubcategoryOverview: 'BEP ticket subcategories',
            bepFollowupActionCategories: 'BEP follow-up action categories',
            ledgerCostOverview: 'Ledger Costs',
            ledgerVatOverview: 'Ledger VATs',
            costSupplierOverview: 'Cost Supplier',
            costProductOverview: 'Cost Product',
            lisIntegrationOverview: 'LIS Integrations',
            ecsIntegrationOverview: 'ECS Integration',
        },
        admin: { // This is not the same as administration!  This is application admin
            userOverview: 'Users',
            truckBrandOverview: 'Truck models',
            certificationOverview: 'Certification',
            licenseOverview: 'License',
            cashRequest: 'Cash Request',
        },
        account: {
            account: 'Account',
            changePassword: 'Change password',
            systemHealth: 'System health',
            changelog: 'Changelog',
        },
    },
    common: {
        delete: {
            error: 'Kon niet verwijderen!',
            success: 'Item is verwijderd',
        },
        filter: {
            deleted: 'Include deleted',
        },
    },
    loading: 'Loading…',
    form: {
        iconInput: {
            noneSelected: 'No icon selected',
        },
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Other',
            none: 'None',
        },
        pdfLoading: 'Loading pdf...',
        pdfError: 'Error loading pdf...',
        fileSaveBeforeDownload: 'Please save before downloading',
        ok: 'Ok',
        yes: 'Yes',
        no: 'No',
        hide: 'Hide',
        show: 'Show', 
        showAll: 'Show all',
        refreshButton: 'Refresh',
        enabledWhenExisiting: 'Only available after saving',
        saveButton: 'Save',
        restoreButton: 'Restore',
        copyButton: 'Copy',
        saveAndNewButton: 'Save & new',
        saveLoadingButton: 'Saving…',
        sendButton: 'Send',
        editButton: 'Edit',
        selectAllButton: 'Select all',
        addButton: 'Add',
        searchModalButton: 'Pick',
        activateButton: 'Activate',
        deactivateButton: 'Deactivate',
        activateSsoButton: 'Activate SSO Login',
        deactivateSsoButton: 'Deactivate SSO Login',
        deleteButton: 'Delete',
        clearButton: 'Clear',
        cancelButton: 'Cancel',
        deletedWaivers: 'Deleted waivers:',
        closeButton: 'Close',
        unfinalizeButton: 'Unfinalize',
        searchLabel: 'Search',
        applyButton: 'Apply',
        selectPlaceholder: 'Select…',
        dateRangePlaceholder: 'Select date…',
        unfinalizeConfirmationTitle: 'Unfinalize',
        unfinalizeConfirmation: 'Are you sure you want to unfinalize activity id {{id}}?',
        hardDeleteConfirmation: 'Are you sure you want to delete {{name}}?',
        deleteConfirmation: 'Are you sure you want to deactivate {{name}}?',
        unDeleteConfirmation: 'Are you sure you want to reactivate {{name}}?',
        typeToSearchText: 'Type to search…',
        copyToClipboardTooltip: 'Copy to clipboard',
        notifications: {
            saveSuccess: 'Saved successfully',
            deleteSuccess: 'Deleted successfully',
            saveError: 'Error with saving ({{status}})',
            saveValError: 'Not all information is filled in correctly',
            saveAuthError: 'Invalid credentials',
            requestScanSuccess: 'Request scan successfully',
            newAppVersion:
                'New version available, click to reload page as soon as possible',
            mailUpdateSentNotification:
                'Email update sent to customer successfully',
            mailUpdateNoTemplateNotification:
                "Can't send update e-mail without a template for this customer",
            isDeploying: 'New version is being deployed, restarting server in a moment'
        },
        validationErrors: {
            blank: 'Field is required',
            null: 'Field is required',
            max_digits: 'Maximum field value exceeded',
            max_whole_digits: 'Maximum field value exceeded',
            max_value: 'Maximum value exceeded',
            invalid: 'Invalid value',
            reset_code_invalid: 'Invalid reset code',
            password_too_common: 'Password is too common',
            invalid_token: 'Invalid token',
            existing_invoices: 'There are existing invoices with these settings',
            invoice_number_duplicate: 'Invoice with this Invoice number already exists. Automatically changed the Invoice number and saved it again.',
            unique: 'Field is not unique',
            unique_together: 'Field is not unique',
            unknown: 'Unknown error in field', // Fallback translation, this should never happen.
            date_mismatch: 'End date may not lie before start date',
            routing_error:
                'Could not calculate route between this activity and previous one',
            geo: {
                'failed-error': 'Unknown error with finding location',
                'failed-no-results': 'Could not find location',
                'failed-multiple-results': 'Found too many locations',
                'failed-no-housenumber': 'Did not find specific house number',
                not_precise: 'Found location, but not precise',
            },
        },
        multiPick: {
            noneSelectedText: 'No selected',
            selectNoneButton: 'Unselect',
            selectAllButton: 'Select all',
            searchPlaceholder: 'Type here...',
            selectedText: 'Selected',
        },
        startDate: {
            label: 'Start date',
        },
        endDate: {
            label: 'End date',
        },
    },
    planning: {
        customer: {
            fuel: {
                low: 'Low ({{count}})',
                driveBy: 'Drive By ({{count}})',
            },
            sort: {
                default: 'Default',
                unread: 'Unread',
                arrival: 'Arrival',
                truckLicense: 'Truck license plate'
            },
            selectAll: 'Select all',
            emptyList: 'Currently there are no activities assigned to you.',
            fetchAllAllocationsButton: 'Allow fetching all allocations',
            showAllActivities: 'show all',
            hideAllActivities: 'hide all',
            sendMailUpdateButton: 'Send update',
            filterCustomersLabel: 'Customers',
            filterContractsLabel: 'Customer contracts',
            filterDriversLabel: 'Drivers',
            filterDispatcherLabel: 'Dispatchers',
            filterTrucksLabel: 'Trucks',
            filterReturnHomeLabel: 'Homebase visit on',
            actionOptions: {
                markOk: 'Mark as OK',
                markNeutral: 'Mark as Neutral',
                markPrio: 'Mark as Priority',
            },
            csEmployee: {
                title: 'CS employee',
            },
            filterNoActivityLabel: 'No unfinished activities',
            filterHighValue: 'Only High Value',
            headings: {
                customer: 'Customer',
                contract: 'Contract',
                currentActivity: 'Current activity',
                nextActivity: 'Next activity',
                comments: 'Comments',
                drivers: 'Drivers',
                truck: 'Truck',
                activities: 'Activities',
            },
            panelTitles: {
                overdueReminders: 'Overdue reminders',
                upcomingReminders: 'Upcoming reminders',
                completedReminders: 'Completed reminders',
                reminders: 'Reminders',
                customerMailUpdate: 'Email update',
                customerInfo: 'Customer info',
                customerUpdateHistory: 'Customer update history',
                customerUpdateForm: 'Customer update',
                customerCode: 'Customer ID',
                addActivity: 'Add',
                editActivity: 'Edit',
                _current: 'Current',
                _next: 'Next',
                driverInfo: 'Driver info',
                driver1Info: 'Driver 1 info',
                driver2Info: 'Driver 2 info',
                finalizeActivity: 'Finalise activity',
                activityDetails: 'Activity details',
                routeDetails: 'Route details',
                trucks: 'Trucks',
                times: 'Times',
                stops: 'Stops',
                details: 'Details',
                invoicing: 'Invoicing',
                invoices: 'Invoices',
                customLines: 'Custom Lines',
                waivers: 'Waivers',
                costs: 'Registered Costs',
                truckChat: 'Truck chat',
                otherCosts: 'Other costs',
            },
        },
        history: {
            unfinalizedTitle: 'Unfinalized activities',
            pastTitle: 'Past activities',
            unfinalizedEmptyText: 'No unfinalized activities for this customer ID.',
            pastEmptyText: 'No historic activities that are finalized for this customer ID.',
            mapNotAvailable: 'Map is not available, since no truck assigned.',
        },
        selectTruck: {
            title: 'Pick a suitable truck',
        },
        selectDriver: {
            title: 'Pick a suitable driver',
        },
        selectAllocation: {
            title: 'Pick a new customer ID',
        },
        asset: {
            filterTruckTypeLabel: 'Truck type',
            filterDriver1DeparturePlaceLabel: 'Driver 1 departure at',
            filterDriver1ArrivalPlaceLabel: 'Driver 1 arrives at',
            filterDriver2DeparturePlaceLabel: 'Driver 2 departure at',
            filterDriver2ArrivalPlaceLabel: 'Driver 2 arrives at',
            filterHomeBaseLabel: 'Truck to home base',
            modalSelectAllocation: {
                title: 'Pick allocation',
                customerCode: 'Customer ID',
                customer: 'Customer',
                contract: 'Contract',
                truck: 'Current Truck',
                entity: 'Entity',
                drivers: 'Current Driver(s)',
                filters: {
                    customer: 'Customer',
                    entity: 'Entity',
                    truckType: 'Truck Type',
                    contract: 'Contract',
                },
            },
            bulkActionButton: {
                choose: 'Choose Bulk Action ({{count}})',
                unfinalize: 'Unfinalize',
                finalize: 'Finalize',
                remarks: 'Remarks',
                markColor: '(Un)mark {{color}}',
            },
        },
        assetDetails: {
            currentTitle: 'Current',
            newTitle: 'New',
            newTruckTitle: 'New truck',
            newDriver1Title: 'New driver 1',
            newDriver2Title: 'New driver 2',
            newCustomerTitle: 'New customer',
            truckTitle: 'Truck',
            driver1Title: 'Driver 1',
            driver2Title: 'Driver 2',
            customerTitle: 'Customer',
            startChangeLabel: 'Start / Change',
            startDatetimeLabel: 'Start',
            customerContractLabel: 'Contract',
            customerAllocationLabel: 'Customer ID',
            truckDueHomeDateLabel: 'Truck to home base',
            homeBaseLabel: 'Home base',
            remarksLabel: 'Remarks',
            changeAllocationLabel: 'Change customer ID',
            changedAllocationText:
                'Planned as {{customerCode}} for {{customerName}} from {{startDate}}.',
            headings: {
                truck: 'Truck',
                truckType: 'Type',
                truckToBase: 'To base',
                drivers: 'Drivers',
                driversHome: 'Home',
                customer: 'Customer',
                customerId: 'ID',
                newDrivers: 'New drivers',
                newDriversChange: 'Change',
                newAt: 'At',
                newBy: 'By',
            },
        },
        codeDetails: {
            statusTitle: 'Status {{customerCode}}',
            truckTitle: 'Truck {{licensePlate}}',
            truckStatusLabel: 'Truck status',
            statusLabel: 'Status',
            truckStatusOptions: {
                driving: 'Driving',
                paused: 'Ignition',
                stopped: 'Stopped',
                unknown: 'Unknown',
            },
            lastStatusUpdateLabel: 'Last status update',
            latlngLabel: 'Current location',
            tachoLabel: 'Tacho',
            locationLabel: 'Location (measured at: {{measuredAt}})',
            gpsLabel: 'GPS',
            adresLabel: 'Address',
            dispatcherLabel: 'Current Dispatcher',
            dispatcherNameLabel: 'Name',
            noGpsSignal: 'no GPS signal',
        },
        driverDetails: {
            driverDossierButton: 'Go to driver dossier',
        },
        activityDetails: {
            dateAndTimeLabel: 'Date & times',
            edit: 'Edit activity',
            resendUpdateButton: 'Update',
            isAsapLabel: 'Ordered arrival time',
            createdAtAsapLabel: 'Activity created at',
            nextActivityCommunicatedArrivalDatetime:
                'Communicated ETA next activity',
        },
        truckChat: {
            noActivity: 'No activity assigned',
        },
        fuelDetails: {
            title: 'Fuel info',
        },
        activityFinalizeModal: {
            invoice: {
                invoiceNumber: 'Invoice number',
                invoiceDate: 'Invoice date',
                type: 'Type',
                typeActivity: 'Activity',
                typeCorrection: 'Correction',
                typeCustom: 'Custom',
                invoiceReference: 'Invoice reference',
                totalAmount: 'Total amount',
                activityAmount: 'Activity amount',
            },
            customLines: {
                invoiceNumber: 'Invoice number',
                invoiceDate: 'Invoice date',
                customLineDescription: 'Custom line description',
                totalAmount: 'Total amount',
                activityRatio: 'Activity ratio',
                activityAmount: 'Activity amount',
            },
            waivers: {
                waiversAmount: 'Waived amount',
                waiversAt: 'Waived at',
                waiversBy: 'Waived by',
                reason: 'Reason',
                remarks: 'Remarks',
            },
        },
        activityFinalize: {
            selectDocumentButton: 'Find',
            requestScan: 'Request scan',
            selectDocument: {
                title: 'Find document',
                revert: 'Revert',
                approveDocumentButton: 'Approve',
                rejectDocumentButton: 'Reject',
                goBackButton: 'Go back',
                detach: 'Detach',
                approvedDocumentsTitle: 'Linked for {{type}}',
                attachedDocumentsTitle: 'Attached to activity',
                otherDocumentsTitle: 'Other documents',
                rotate: 'Rotate',
                brighten: 'Brighten',
                darken: 'Darken',
                sharpen: 'Sharpen',
                cropStartButton: 'Crop',
                cropSaveButton: 'Save crop',
                restoreButton: 'Restore',
            },
            previousActivityLabel: 'Previous activity',
            currentActivityLabel: 'Current activity',
            activityTypeLabel: 'Activity type',
            dateLabel: 'Date',
            noTrucksFound: 'No trucks could be coupled to this activity.',
            noStopsFound: 'No stops could be coupled to this activity.',
            truckHeadings: {
                truck: 'Truck',
                driver: 'Driver',
                trailer: 'Trailer',
            },
            stopHeadings: {
                stop: 'Stop',
                extraKm: 'Extra KM',
            },
        },
        dispatcher: {
            selectUserLabel: 'Select dispatcher…',
            selectActionLabel: 'Select action…',
            actionOptions: {
                add: 'Add dispatcher to customer IDs',
                clear: 'Clear dispatchers from customer IDs',
            },
            headings: {
                users: 'Users',
            },
        },
        approveDocument: {
            activityDetails: 'Activity details',
            previousLoad: 'Previous Load',
            driver1Details: 'Drivers 1 details',
            driver2Details: 'Drivers 2 details',
            rejectByCustomer: 'Rejected by customer',
            rejectByCustomerReason: 'Reason',
            activity: {
                type: 'Type',
                finishedTime: 'Finished time',
                location: 'Location',
            },
            driver1: {
                dataExternalId: 'Card id',
                name: 'Name',
            },
            driver2: {
                dataExternalId: 'Card id',
                name: 'Name',
            },
        },
        csEmployee: {
            title: 'Customer Service employee',
            selectUserLabel: 'Select CS Employee…',
            selectActionLabel: 'Select action…',
            actionOptions: {
                add: 'Add CS Employee to customer IDs',
                replace: 'Replace CS Employees for customer IDs',
                clear: 'Clear CS Employees from customer IDs',
            },
            headings: {
                users: 'CS Employees',
            },
        },
        maps: {
            filterDispatcherLabel: 'Dispatchers',
            filterCustomerLabel: 'Customers',
            filterAllocationLabel: 'Customer IDs',
            popupDestinationLabel: 'Destination',
            popupEtaLabel: 'ETA',
            popupNoDriverText: 'No driver',
            popupDispatcherLabel: 'Dispatcher',
            driverChange: 'Driver change date',
            type: 'TYPE',
            layers: {
                'satellite': 'Satellite',
                'map': 'Map',
                'hybrid': 'Satellite + map hybrid',
            },
            adrDriver: {
                title: 'Driver(s):',
                no: 'ALL',
                yes: 'ADR CERTIFIED',
            },
            ukDriver: {
                title: '',
                no: 'ALL',
                yes: 'UK ALLOWED',
            },
            weekendBreak: {
                title: '',
                no: 'ALL',
                yes: '24h WEEKEND',
            },
            isPlanned: {
                title: 'Planned:',
                yes: 'ALL',
                no: 'WITHOUT PLAN',
            }
        },
    },
    reassignmentChange: {
        field: {
            newTruck: {
                label: 'New Truck',
            },
            remarks: {
                label: 'Remarks',
            },
            newDriver1: {
                label: 'New Driver 1',
            },
            newDriver2: {
                label: 'New Driver 2',
            },
        },
    },
    reassignmentTruckswap: {
        field: {
            datetime: {
                label: 'Planned at',
            },
            assignment1: {
                label: 'This assignment',
            },
            assignment2: {
                label: 'Other assignment',
            },
        },
    },
    assignment: {
        immutable: 'Assignment has activities, and can\'t be changed.',
        field: {
            truck: {
                label: 'Truck',
            },
            driver1: {
                label: 'Driver 1',
            },
            driver2: {
                label: 'Driver 2',
            },
        },
    },
    allocation: {
        addAssignmentButton: 'Add assignment',
        needsAttention: 'Needs attention ({{count}})',
        withoutAssignment: 'Without assignment',
        withAssignment: 'With assignment',
        createButton: 'Add customer ID',
        field: {
            customerCode: {
                label: 'Customer Code',
            },
            truckType: {
                label: 'Truck Type',
            },
            startDate: {
                label: 'Start Date',
            },
            endDate: {
                label: 'End Date',
            },
            remarks: {
                placeholder: 'Type any comments here…',
            },
        },
    },
    tachoEvent: {
        overview: {
            createButton: 'New Tacho Event',
        },
        add: {
            title: 'Add Tacho Event',
        },
        edit: {
            title: 'Edit Tacho Event',
        },
        field: {
            id: {
                label: 'ID'
            },
            driver: {
                label: 'Driver',
                filter: 'Drivers',
            },
            dataSource: {
                label: 'Data Source',
                filter: 'Data Sources',
            },
            measuredAt: {
                label: 'Measured At'
            },
            dataExternalId: {
                label: 'External ID'
            },
            action: {
                label: 'Action',
                value: {
                    none: 'None',
                    drive: 'Drive',
                    work: 'Work',
                    wait: 'Wait',
                    rest: 'Rest',
                    availability: 'Availability',
                },
            },
        },
    },
    dataSources: {
        'fleetvisor': 'Fleetvisor',
        'transics': 'Transics',
        'mobile office': 'Mobile Office',
        'fleetboard': 'Fleetboard',
        helpText: {
            'fleetboard': 'When choosing Fleetboard, messages are send to Tara, but truck positions and tacho events are read from Fleetboard. The external data source is not used for Fleetboard, but the VIN number & tacho card  number.',
        }
    },
    conditionInstruction: {
        overview: {
            createButton: 'Add',
        },
        create: {
            title: 'Create Condition Instruction',
        },
        edit: {
            title: 'Edit Instructions for',
        },
        field: {
            id: {
                label: 'ID',
            },
            condition: {
                label: 'Condition',
            },
            instructions: {
                label: 'Instructions',
            },
            language: {
                label: 'Language',
            },
        },
    },
    reminder: {
        upcoming: 'Upcoming',
        completed: 'Completed',
        empty: 'No reminders founds.',
        noneSelected: 'Please select a reminder from the list to view its details.',
        handle: 'Handle reminder',
        snoozed: 'This reminder has been snoozed {{count}} times',
        snoozeButton: 'Snooze',
        completeButton: 'Complete',
        isFinal: 'Stop Repeat',
        modal: {
            add: {
                title: 'Add a reminder',
            },
        },
        field: {
            repeatType: {
                label: 'Repeat Type',
                options: {
                    null: 'No repeat',
                    weekly: 'Weekly',
                    monthly: 'Monthly',
                    monthly_week_day: 'Monthly on x week',
                },
            },
            repeatAfterWeeks: {
                label: 'Repeat after x weeks',
            },
            repeatAfterMonths: {
                label: 'Repeat after x months',
            },
            repeatOnNthWeek: {
                label: 'Repeat on x week',
            },
            remindAt: {
                label: 'Remind at',
            },
            content: {
                label: 'Content',
            },
        },
    },
    proposedRoute: {
        acceptModal: {
            title: 'Accept Route',
        },
        rejectModal: {
            title: 'Reject Route',
        },
        field: {
            id: {
                label: 'ID',
            },
            customer: {
                label: 'Customer',
            },
            count: {
                label: '#',
            },
            _rejectionMailSubject: {
                label: 'Rejection subject',
            },
            acceptanceRejectionRemarks: {
                label: 'Rejection body',
            },
        },
    },
    contractDocument: {
        field: {
            title: {
                label: 'Name',
            },
            file: {
                label: 'File',
            },
        },
    },
    reminderLog: {
        field: {
            createdBy: {
                label: 'Created by',
            },
            createdAt: {
                label: 'Created at',
            },
            snoozeUntil: {
                label: 'Snooze until',
            },
            remarks: {
                label: 'Remarks',
            },
            action: {
                label: 'Action',
            },
        },
    },
    route: {
        field: {
            name: {
                label: 'Name',
            },
            description: {
                label: 'Description',
            },
        },
        selectRouteModal: {
            addTitle: 'Add to Route',
            selectTitle: 'Select Route',
            chooseExisting: 'Choose existing Route',
            addNew: 'Add new Route',
        },
    },
    routeStop: {
        field: {
            timeDelta: {
                label: 'Time Delta',
            },
        },
    },
    payrollCompany: {
        overview: {
            createButton: 'Add',
        },
        create: {
            title: 'Add Payroll Company',
        },
        edit: {
            title: 'Edit Payroll Company',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            entity: {
                label: 'Entity',
            },
        },
    },
    cashRequest: {
        addModal: {
            title: 'Add Cash Request',
            showModalButton: 'Add Cash Request',
        },
        field: {
            id: {
                label: 'ID',
            },
            amount: {
                label: 'Amount',
            },
            reason: {
                label: 'Reason',
                options: {
                    parking: 'Parking',
                    advance: 'Advance',
                    penalty: 'Penalty',
                    diesel: 'Diesel',
                    other: 'Other',
                }
            },
            paymentMethod: {
                label: 'Payment Method',
                options: {
                    cash: 'Cash',
                    card: 'Card',
                }
            },
            description: {
                label: 'Description',
            },
            currency: {
                label: 'Currency',
            },
            driver: {
                label: 'Driver',
            },
        },
    },
    orderConfirmation: {
        field: {
            title: {
                label: 'Name',
            },
            file: {
                label: 'File',
            },
        },
    },
    activity: {
        convertTruckChangeToDropPickupModal: {
            title: 'Truck {{licensePlate}} in use by allocation {{customerCode}}',
            arrived: 'This truck is in use, but you can convert it to a drop + pickup. Proceed?',
            notArrived: 'There is a driver/truck change planned, but it has to have status arrived before we can split it into a drop + pickup.',
        },
        truckInUseByAssignmentModal: {
            title: 'Truck {{licensePlate}} in use by allocation {{customerCode}}',
            content: 'This truck is in use by {{customerCode}} for {{customerName}}. Please create a truck drop on allocation {{customerCode}}.',
        },
        unlinkedWarning: 'Link with LIS is missing',
        deleteConfirmation: 'Are you sure you want to delete {{name}}?',
        etaInfo: {
            weekendBreaks: 'Weekend breaks',
            breaks: 'Breaks',
            activityDuration: 'Activity duration',

            shortWeekendbreaksTaken: 'Short weekend breaks taken',
            longWeekendbreaksTaken: 'Long weekend breaks taken',
            breaksTaken: 'Breaks taken',
            shortBreaksTaken: 'Short breaks taken',
            expectedWaitingTime: 'Expected waiting time',
        },
        overview: 'Show activity',
        modalChangeKm: {
            clickedRoute: 'You\'ve selected another end km {{odometer}} on the route. Please choose which activity this end km should be used for.',
        },
        regenerateTemperaturePrintButtonTitle: 'Regenerate temp. print',
        missingPlannedKmGivenKm: 'Cannot finalize, because planned km or given km are missing',
        sameTruckError: 'You are saving a truck change, but the truck seems to stay the same...',
        sameDriverError: 'You are saving a driver change, but the driver seems to stay the same...',
        diffTruckError: 'You are saving a driver change, but the truck seems to change...',
        invoicedActivity: 'This activity has already been invoiced',
        departureArrivalDateDiff: '{{days}}day',
        addNewButton: 'Add new',
        addNewButtonDisabled: 'No Truck and/or Driver assigned',
        skipFinalizeButton: 'Skip',
        saveFinalizeButton: 'Save & Finalize',
        expected: 'Exp.',
        arrived: 'ARRIVED',
        lastActivityFinishedDatetime: 'Last activity',
        lastNewPlanRequestSentDatetime: 'Last request sent:',
        noAttachDocOnContract: "There are no documents required to send for this contract. Either add documents in the contract settings or change the contract settings to don't send documents after finalize",
        noAttachDocs: 'Not all required documents have been approved.',
        newTrailerCustomer: 'Can not create new trailer for this customer!',
        disabledStatusEdit: {
            notCurrent: 'Cannot change status since it is not the currenct activity.',
            noAssignment: 'Cannot perform any actions since no active assignment is found.',
        },
        waitingCostConfrim: 'Are you sure to accept {{approved}} approved waiting cost?',
        add: {
            trailerTitle: 'Trailer',
        },
        field: {
            id: {
                label: 'ID',
            },
            custom: {
                label: 'Custom',
            },
            orderReference: {
                label: 'Order reference',
            },
            currentActivityLabel: {
                label: 'Current activity',
            },
            refuelNotificationMessage: {
                label: 'Refuel email body',
            },
            requestedFuelLevelIncrease: {
                label: 'Requested Tanking Liters',
                placeholder: 'Max capacity',
            },
            orderConfirmations: {
                label: 'Order Confirmations',
                add: 'Add Order Confirmation',
            },
            reasonNonApprovedPetrolStation: {
                label: 'Reason non-approved petrol station',
            },
            previousActivityLabel: {
                label: 'Previous activity',
            },
            isAsap: {
                label: 'ASAP',
            },
            lastStatusUpdateSentAt: {
                label: 'Last statusUpdate sent at'
            },
            reassignmentChange: {
                label: 'Truck or Driver change',
            },
            expectedTimeForActivity: {
                label: 'Expected Time (in HH:MM)',
            },
            combinedTransportId: {
                label: 'Ferry from PTV',
            },
            type: {
                label: 'Activity type',
                options: {
                    load: 'Load',
                    unload: 'Unload',
                    'ferry-tunnel': 'Ferry-Tunnel',
                    'equipment pick-up': 'Equipment pick-up',
                    'truck change': 'Truck change',
                    'truck swap': 'Truck swap',
                    'driver change': 'Driver change',
                    tanking: 'Tanking',
                    homebase: 'Homebase',
                    'trailer change': 'Trailer change',
                    'secure parking': 'Secure parking',
                    service: 'Service',
                    other: 'Other',
                    'driver/truck change': 'Truck or Driver change',
                    'tacho calibration': 'Tacho calibration',
                    'tuv': 'TUV',
                    'border/customs': 'Border/customs',
                    'truck drop': 'Truck Drop',
                    'allocation exit': 'Allocation Exit',
                },
                // Load in Maastricht
                // -----^^
                //
                // Secure parking in Eindhoven
                // ---------------^^
                in: 'in',
            },
            kind: {
                label: 'Kind',
                options: {
                    'boek stop': 'boek stop',
                    'customer stop': 'customer stop',
                    activity: 'activity',
                    route: 'Route',
                },
                longOptions: {
                    'boek stop': 'Boekestijn stop',
                    'customer stop': 'Customer stop',
                    activity: 'Activity',
                    route: 'Route',
                },
            },
            status: {
                label: 'Status',
                options: {
                    planned: 'Planned',
                    eta: 'Driving',
                    arrived: 'Arrived',
                    waiting: 'Waiting',
                    started: 'Started',
                    finished: 'Finished',
                },
                shortOptions: {
                    planned: 'Plan',
                    eta: 'Driving',
                    arrived: 'Arr',
                    waiting: 'Wait',
                    started: 'Strt',
                    finished: 'Fin',
                },
                noArrivalDisabled: 'The activity needs to be moved to arrived first.',
                otherTruckswapActivityStillPlanned: 'The other Truck & Driver change activity is still in status planned, so you cannot finish this activity yet.',
            },
            cargoDescription: {
                label: 'Cargo description',
            },
            instructions: {
                label: 'Driver Instructions',
            },
            location: {
                label: 'Location',
            },
            previousLocation: {
                label: 'Previous Location',
            },
            previousType: {
                label: 'Previous Type',
            },
            reassignmentTruckswap: {
                label: 'New truck & driver',
            },
            orderedArrivalDatetimeUntil: {
                label: 'Ordered arrival time till',
            },
            orderedArrivalDatetimeFrom: {
                label: 'Ordered arrival time from',
            },
            communicatedArrivalDatetime: {
                label: 'Communicated ETA',
            },
            actualArrivalDatetime: {
                label: 'Actual arrival',
            },
            estimatedArrivalDatetime: {
                label: 'Estimated arrival',
            },
            finishedDatetime: {
                label: 'Finished',
                filter: 'Finished on',
            },
            waitStartDatetime: {
                label: 'Started waiting',
            },
            startDatetime: {
                label: 'Actual start',
            },
            expectedArrivalDatetime: {
                label: 'Expected arrival',
            },
            preliminaryWaitingAmount: {
                label: 'Approved waiting cost',
            },
            requiredDocuments: {
                label: 'Required documents',
                upload: 'Upload documents\nYou can also use drag&drop',
                emptyText: 'No required documents for this activity.',
                downloadAll: 'Download all attached documents in a zip file',
                searchModal: 'Find & select documents in a popup',
            },
            trailer: {
                label: 'Trailer',
                previousLabel: 'Previous trailer',
                newLabel: 'New trailer',
            },
            documents: {
                label: 'Attached Documents',
            },
            waitTimeActual: {
                label: 'Waiting time actual',
            },
            plannedKm: {
                label: 'KM Plan',
            },
            deviationKm: {
                label: 'KM Deviation',
            },
            deviationKmPercent: {
                label: 'KM Deviation %',
            },
            currentOdometer: {
                label: 'Odometer',
            },
            startKm: {
                label: 'KM start',
            },
            endKm: {
                label: 'KM end',
            },
            drivenKm: {
                label: 'KM Driven',
            },
            givenKm: {
                label: 'KM to pay',
            },
            unpaidKm: {
                label: 'KM planned & not paid',
            },
            calculatedWaitingAmount: {
                label: 'Expected waiting cost',
            },
            waitCostApproved: {
                label: 'Approved Waiting cost',
            },
            tollCosts: {
                label: 'Toll costs',
            },
            remarks: {
                label: 'Remarks',
            },
            finalized: {
                label: 'Finalized',
            },
            finalizedBy: {
                label: 'Finalized by',
            },
            Invoiced: {
                label: 'Invoiced',
            },
            document: {
                label: 'Documents sent',
            },
            customer: {
                label: 'Customer',
            },
            equipmentPlanned: {
                label: 'Equipment Planned',
            },
            equipmentActual: {
                label: 'Equipment Actual',
            },
            minTemp: {
                label: 'Min. temp',
            },
            maxTemp: {
                label: 'Max. temp',
            },
            otherCosts: {
                label: 'Other Costs',
            },
            fuel: {
                label: 'Fuel %',
            },
            expectedKmSurcharge: {
                label: 'Fuel %',
            },
            expectedFixedSurcharge: {
                label: 'Fixed %',
            },
            vat: {
                label: 'VAT',
            },
            outsideEu: {
                label: 'Outside EU',
                options: {
                    unknown: 'Unknown',
                    yes: 'Outside',
                    no: 'Inside',
                },
            },
            conditions: {
                label: 'Conditions',
                options: {
                    adr: 'ADR',
                    temperature: 'Temperature',
                    'tsr1+': 'TSR1+',
                    tsr1: 'TSR1',
                    tsr2: 'TSR2',
                    'sent-geo': 'SENT-GEO',
                    aircargo: 'Aircargo',
                    glands: 'Glands',
                    overwidth: 'Overwidth',
                    overlength: 'Overlength',
                    gdp: 'GDP',
                },
            },
            routeUseRealtimeInfo: {
                label: 'Use realtime traffic info',
            },

        },
        violatedConditions: {
            label: 'Violated Conditions',
            options: {
                adr: "ADR trip while the driver doesn't have a valid ADR certificate for at least the next 7 days.",
                temperature: "Current temperature not between min and max temperature of activity.",
                'tsr1+': "TSR1+",
                tsr1: "TSR1",
                tsr2: "TSR2",
                aircargo: "Aircargo trip while the driver doesn't have a valid Aircargo certificate for at least the next 7 days.",
                glands: "Glands",
                overwidth: "Overwidth trip while the truck doesn't have a valid Overwidth license for at least the next 7 days.",
                overlength: "Overlength trip while the truck doesn't have a valid Overlength license for at least the next 7 days.",
                tollbox: "Planning trip to country without valid tollbox on truck",
            },
        },
    },
    activityOtherCost: {
        emptyText: 'No other costs for this activity yet.',
        addOtherCostModal: {
            title: 'Add other cost',
        },
        field: {
            amount: {
                label: 'Other cost',
            },
            description: {
                label: 'Other cost description',
            },
        },
    },
    otherEquipment: {
        trailer: {
            label: 'Trailer equipment',
            emptyText: 'No other Equipment for this trailer yet.',
            noTrailer: "Truck doesn't have a trailer",
        },
        truck: {
            label: 'Truck equipment',
            emptyText: 'No other Equipment for this truck yet.',
        },
        field: {
            name: {
                label: 'Name',
            },
            quantity: {
                label: 'Quantity',
            },
            kind: {
                label: 'Kind'
            },
        },
    },
    equipmentType: {
        edit: {
            title: 'Edit Equipment Type',
        },
        create: {
            title: 'Create Equipment Type',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
        },
    },
    equipmentChange: {
        dropAllAfterTrailerChange: 'After a "trailer change", all equipement will be dropped',
        field: {
            id: {
                label: 'ID',
            },
            load: {
                label: 'Load',
            },
            unload: {
                label: 'Unload',
            },
        },
    },
    trackedEquipment: {
        field: {
            equipmentType: {
                label: 'Equipment Type',
            },
            trackingTarget: {
                label: 'Tracking Target',
                value: {
                    trailer: 'Trailer',
                    allocation: 'Allocation',
                },
            },
        },
    },
    statusUpdate: {
        afterOfficeHoursModal: {
            title: 'Out of Office',
            content: 'The customer is not in office anymore. The email update will be send, but make sure you also inform them by phone.'
        },
        newPlan: 'Request plan update',
        saveAndSendRequestForNewPlan: 'Save & send request',
        saveAndSendButton: 'Save & send update',
        sendButton: 'Save status',
        addManualEmailTitle: 'Add extra email',
        addManualEmailLabel: 'Email',
        equipmentTruckLabel: 'on truck:',
        equipmentTrailerLabel: 'on trailer:',
        emptyList:
            'There are no status updates for unfinished activities in this customer ID.',
        field: {
            subject: {
                label: 'Subject',
            },
            recipients: {
                label: 'Recipients',
            },
            content: {
                label: 'Message to customer',
            },
        },
        drivingDelayReasons: 'Driving delay reasons:',
        waitingDelayReasons: 'Waiting delay Reasons:',
        waitingDelayReasonsTime: 'Until:',
        delayReason: {
            options: {
                'other': 'Other',
                'traffic jam': 'Traffic jam',
                'police control': 'Police control',
                'tacho stop': 'Tacho stop',
                'wrong reference': 'Wrong reference',
                'company closed': 'Company closed',
                'not ready': 'Not ready',
                'line': 'Line',
            },
        },
        waitingRateApplies: 'Since {{datetime}} starting to count waiting hours. Total amount: {{amount}}.',
        slot: 'SLOT: from {{from}} to {{to}}',
        weekendBreak: 'Weekend break: {{break}}',
    },
    activityIssue: {
        field: {
            condition: {
                label: 'Condition',
                // Backend now gives us nice error messages.
                // options: {
                //     adr: "ADR trip while the driver doesn't have a valid ADR certificate for at least the next 7 days.",
                //     temperature: "Current temperature not between min and max temperature of activity.",
                //     'tsr1+': "TSR1+",
                //     tsr1: "TSR1",
                //     tsr2: "TSR2",
                //     aircargo: "Aircargo trip while the driver doesn't have a valid Aircargo certificate for at least the next 7 days.",
                //     glands: "Glands",
                //     overwidth: "Overwidth trip while the truck doesn't have a valid Overwidth license for at least the next 7 days.",
                //     overlength: "Overlength trip while the truck doesn't have a valid Overlength license for at least the next 7 days.",
                // },
            },
            reason: {
                label: 'Reason',
            },
            valid: {
                label: 'Validity',
            },
        },
    },
    document: {
        rejectByCustomerButton: 'Reject',
        rejected: 'Rejected',
        notSent: 'Not yet send',
        field: {
            id: {
                label: 'ID',
            },
            scannedAt: {
                label: 'Scanned at',
            },
            truck: {
                label: 'Truck',
            },
            status: {
                label: 'Status',
            },
            rejectReason: {
                label: 'Rejection reason',
                placeholder: 'Type the reason why you rejected this document...',
            },
            type: {
                label: 'Type',
                options: {
                    cmr: 'CMR',
                    'delivery note': 'Delivery note',
                    'cargo manifest': 'Cargo manifest',
                    'pallet note': 'Pallet note',
                    'weight ticket': 'Weight ticket',
                    'commercial document': 'Commercial document',
                    fcdn: 'FCDN',
                    'temperature print': 'Temperature print',
                    'wash ticket': 'Wash ticket',
                    receipt: 'Receipt',
                    tuv: 'TUV',
                    other: 'Other',
                    't1': 'T1',
                    'ex a': 'EX A',
                    'eur1': 'EUR1',
                    'registration document' : 'Registration document',
                    unknown: 'Unknown',
                },
            },
        },
    },
    trailerLicense: {
        createButton: 'Add license',
        field: {
            license: {
                label: 'Type',
            },
            dateObtained: {
                label: 'Obtained at',
            },
            dateOfExpiry: {
                label: 'Expires at',
            },
            documentNumber: {
                label: 'Document number / Description',
            },
            valid: {
                label: 'Valid',
            },
            issuingCountries: {
                label: 'Valid in',
            },
            documentScan: {
                label: 'Trailer license:',
                dropText: 'Click or drag a file to upload a license',
                downloadExisting: 'Download existing file',
                showVariables: 'Show scan variables',
            },
        }
    },
    trailer: {
        overview: {
            createButton: 'New trailer',
            earliestServiceDateColumn: 'Earliest service date',
        },
        edit: {
            title: 'Edit trailer',
            licensesTitle: 'Licenses',
        },
        create: {
            title: 'Create trailer',
        },
        pickUpLabel: 'Pick-up',
        dropLabel: 'Drop',
        field: {
            id: {
                label: 'ID',
            },
            licensePlate: {
                label: 'License plate',
            },
            remarks: {
                label: 'Trailer remarks',
            },
            customer: {
                label: 'Customer',
            },
            fleetvisorId: {
                label: 'Fleetvisor ID',
            },
            compartments: {
                label: 'Compartments',
            },
            lock: {
                label: 'Lock',
                options: {
                    manual: 'Manual',
                    inlock: 'Inlock',
                    outlock: 'Outlock',
                },
            },
            cooler: { label: 'Cooler' },
            fashion: { label: 'Fashion' },
            intermodal: { label: 'Intermodal' },
            lightMotionSensor: { label: 'Light / motion sensor' },
            gdp: { label: 'GDP' },
            alarm: { label: 'Alarm' },
            properties: {
                coolerLabel: 'Cooler',
                fashionLabel: 'Fashion',
                intermodalLabel: 'Intermodal',
                lightMotionSensorLabel: 'Light / motion sensor',
                gdpLabel: 'GDP',
                alarmLabel: 'Alarm',
                options: {
                    'true': 'Yes',
                    'false': 'No',
                    'all': 'All',
                },
                compartmentsLabel: 'Compartments',
            },
        },
    },
    trailerCompartment: {
        field: {
            ignoreSupplyTemp: {
                label: 'Supply Temp ({{temp}}°C)',
            },
            ignoreReturnTemp: {
                label: 'Return Temp ({{temp}}°C)',
            },
            ignoreSetPointTemp: {
                label: 'SetPoint Temp ({{temp}}°C)',
            },
            ignoreSensor1Temp: {
                label: 'Temp Sensor 1 ({{temp}}°C)',
            },
            ignoreSensor2Temp: {
                label: 'Temp Sensor 2 ({{temp}}°C)',
            },
            ignoreSensor3Temp: {
                label: 'Temp Sensor 3 ({{temp}}°C)',
            },
            ignoreSensor4Temp: {
                label: 'Temp Sensor 4 ({{temp}}°C)',
            },
            ignoreDoorState: {
                label: 'Door State 1 ({{state}})',
            },
            ignoreDoorState2: {
                label: 'Door State 2 ({{state}})',
            },
        },
    },
    leaseContract: {
        createButton: 'Add Lease Contract',
        notYetSaved: '(Not Yet Saved) ',
        field: {
            id: {
                label: 'ID',
            },
            entity: {
                label: 'Entity',
            },
            type: {
                label: 'Type',
                value: {
                    operational: 'Operational',
                    financial: 'Financial',
                    rent: 'Rent',
                },
            },
            leaseCompany: {
                label: 'Lease Company',
            },
            remarks: {
                label: 'Remarks',
            },
            startDate: {
                label: 'Start Date',
            },
            endDate: {
                label: 'End Date',
            },
            contractNumber: {
                label: 'Contract Number',
            },
            downPayment: {
                label: 'Down Payment',
            },
            monthlyPayment: {
                label: 'Monthly Payment',
            },
            amountForMonth: {
                label: 'Amount for month',
            },
            finalPayment: {
                label: 'Final Payment',
            },
            roadTaxPayment: {
                label: 'Road Tax Payment'
            },
            includingServiceContract: {
                label: 'Including Service Contract?',
            },
            includingInsurance: {
                label: 'Including Insurance?',
            },
            includingRoadTax: {
                label: 'Including Road Tax?'
            },
            contractScan: {
                label: 'Contract scan: ',
                dropText: 'Click or drag a file to upload a Contract scan',
                downloadExisting: 'Download existing file',
                showVariables: 'Show scan variables',
            },
        },
    },
    insuranceContract: {
        createButton: 'Add Insurance Contract',
        field: {
            entity: {
                label: 'Entity',
            },
            type: {
                label: 'Type',
                value: {
                    liability: 'Liability',
                    casco: 'Casco',
                    'liability + casco': 'Liability + Casco',
                },
            },
            insuranceCompany: {
                label: 'Insurance Company',
            },
            insuranceBroker: {
                label: 'Insurance Broker',
            },
            remarks: {
                label: 'Remarks',
            },
            leaseContract: {
                label: 'Lease Contract',
            },
            startDate: {
                label: 'Start Date',
            },
            endDate: {
                label: 'End Date',
            },
            contractNumber: {
                label: 'Contract Number',
            },
            maximumAmountPerIncident: {
                label: 'Max. Amount / Incident',
            },
            maximumAmountPerYear: {
                label: 'Max. Amount / Year',
            },
            nonDeductibleAmountPerIncident: {
                label: 'Non-Deductable Amount / Incident',
            },
            nonDeductibleAmountPerYear: {
                label: 'Non-Deductible Amount / Year',
            },
            monthlyPayment: {
                label: 'Monthly Payment',
            },
            contractScan: {
                label: 'Contract scan: ',
                dropText: 'Click or drag a file to upload a Contract scan',
                downloadExisting: 'Download existing file',
                showVariables: 'Show scan variables',
            },
        },
    },
    serviceContract: {
        createButton: 'Add Service Contract',
        field: {
            entity: {
                label: 'Entity',
            },
            type: {
                label: 'Type',
                value: {
                    'all in': 'All In',
                    'maintenance only': 'Maintenance Only',
                    'breakdown only': 'Breakdown Only',
                },
            },
            serviceCompany: {
                label: 'Service Company',
            },
            leaseContract: {
                label: 'Lease Contract',
            },
            remarks: {
                label: 'Remarks',
            },
            startDate: {
                label: 'Start Date',
            },
            endDate: {
                label: 'End Date',
            },
            contractNumber: {
                label: 'Contract Number',
            },
            monthlyPayment: {
                label: 'Monthly Payment',
            },
            fixedLocation: {
                label: 'Fixed Location',
            },
            contractScan: {
                label: 'Contract scan: ',
                dropText: 'Click or drag a file to upload a Contract scan',
                downloadExisting: 'Download existing file',
                showVariables: 'Show scan variables',
            },
        },
    },
    codeReport: {
        listEditTitle: 'Code reporting',
        createButton: 'Add',
        field: {
            id: {
                label: 'ID',
            },

            startDate: {
                label: 'Start Date',
            },
            endDate: {
                label: 'End Date',
            },
            codeReporting: {
                label: 'Code reporting',
            },
        },
    },
    truckStatusChange: {
        listEditTitle: 'Status',
        createButton: 'Add status',
        field: {
            id: {
                label: 'ID',
            },
            date: {
                label: 'Date',
            },
            exitReason: {
                label: 'Exit Reason',
            },
            reason: {
                label: 'Reason',
            },
            status: {
                label: 'Status',
                value: {
                    null: '',
                    budgeted:'Budgeted',
                    ordered:'Ordered',
                    operational:'Operational',
                    nonoperational:'Nonoperational',
                    schooling:'Schooling',
                    'pre-exit': 'Pre-Exit',
                    exit:'Exit',
                }
            },
        },
    },
    truck: {
        modalCopyLeaseContract: {
            newData: 'Enter new data',
            chooseExisting: 'Choose existing contract',
        },
        modalConfirmBrokenSatellite: {
            header: 'Mark broken satellite',
            content: 'You are about to mark the satellite of this truck as broken. This will disable most sanity checks for this truck.',
            issueHeader: 'Issues',
        },
        modalMarkWorkingSatellite: {
            header: 'Mark working satellite',
            markWorkingButton: 'Mark working',
            saveAndMarkWorkingButton: 'Save & Mark working',
        },
        overview: {
            createButton: 'New truck',
            earliestServiceDateColumn: 'Earliest service date',
        },
        edit: {
            title: 'Edit truck {{licensePlate}}',
            licensesTitle: 'Licenses & Other',
            tollboxesTitle: 'Tollboxes',
            statusChangeTitle: 'Status',
            registrationTitle: 'Registration',
            leaseContractTitle: 'Lease Contracts',
            insuranceContractTitle: 'Insurance Contracts',
            serviceContractTitle: 'Service Contracts',
            markBrokenSatelliteButton: 'Mark broken satellite',
            markWorkingSatelliteButton: 'Mark working satellite',
        },
        create: {
            title: 'Add truck',
        },
        details: {
            label: 'Details',
        },
        service: {
            label: 'Service',
        },
        truckDossierButton: {
            label: 'Go to truck dossier',
        },
        showAllScans: {
            label: 'Show all scans',
        },
        field: {
            color: {
                label: 'Color',
                value: {
                    'white': 'White',
                    'purple': 'Purple',
                    'red': 'Red',
                    'blue': 'Blue',
                    'black': 'Black',
                },
            },
            id: {
                label: 'ID',
            },
            licensePlate: {
                label: 'License plate',
            },
            phoneNumber: {
                label: 'Phone number',
            },
            telematics:{
                label: 'TELEMATICS'
            },
            entity: {
                label: 'Entity',
            },
            certification: {
                label: 'Level of certification',
                options: {
                    tsr1: 'TSR1',
                    tsr2: 'TSR2',
                    tsr3: 'TSR3'
                }
            },
            type: {
                label: 'Type',
                options: {
                    standard: 'Standard',
                    mega: 'Mega',
                    'mega plus': 'Mega Plus',
                    bdf: 'BDF',
                },
            },
            countryOfRegistration: {
                label: 'Country Registration',
            },
            brand: {
                label: 'Brand & Type',
            },
            truckConstr: {
                label: 'Construction',
            },
            leaseCompany: {
                label: 'Lease company',
            },
            description: {
                label: 'Description',
            },
            homeBase: {
                label: 'Home base',
            },
            dataSource: {
                label: 'External data source',
            },
            dataReference: {
                label: 'External data reference (Fleetvisor MSISDN, Transics Code)',
            },
            customerSpecificGear: {
                label: 'Fixed equipment',
            },
            dateOfConstruction: {
                label: 'Construction date',
            },
            fuelCode: {
                label: 'Fuel Code',
            },
            fuelLevelMinimum: {
                label: 'Min fuel level',
                helpText: 'In litres',
            },
            fuelConsumptionTarget: {
                label: 'Target fuel consumption',
            },
            fuelConsumptionActual: {
                label: 'Actual fuel consumption',
            },
            fuelType:{
                label: 'Fuel type'
            },
            startDate: {
                label: 'Start Date',
            },
            exitDate: {
                label: 'Exit Date',
                filter: 'Include truck with Exit Date?',
            },
            exitReason: {
                label: 'Exit Reason',
            },
            vinNumber: {
                label: 'VIN',
            },
            category: {
                label: 'Category',
                filter: 'Type of vehicle',
                truck: 'Truck',
                bus: 'Bus',
                'private car': 'Private car',
            },
            currentFuelLevel: {
                label: 'Current fuel level',
                shortDisplay: '{{liter}} in tank',
            },
            currentOdometer: {
                label: 'Current Odometer',
            },
            fuelCapacity: {
                label: 'Fuel capacity',
                helpText: 'In liters',
            },
            currentSpeed: {
                format: '{{km}}km/h',
            },
            weight: {
                label: 'Weight (kg)',
                shortLabel: 'Weight',
            },
            euroClass: {
                label: 'Euro class',
            },
            daysAllowedOutsideCountryOfRegistration: {
                label: 'Days allowed outside country of registration',
            },
            lastServiceDate: {
                label: 'Last service date',
                shortLabel: 'Last',
            },
            lastServiceKm: {
                label: 'Last service KM',
                shortLabel: 'Last',
            },
            plannedNextServiceDate: {
                label: 'Next service date',
                shortLabel: 'Next',
            },
            plannedNextServiceKm: {
                label: 'Next service KM',
                shortLabel: 'Next',
            },
            tuvExpirationDate: {
                label: 'TUV expiration',
                shortLabel: 'TUV exp',
            },
            nextServiceDescription: {
                label: 'Description next service',
            },
            nextTachoRecalibrationDate: {
                label: 'Tacho recalibration',
                shortLabel: 'Next tacho',
            },
            looseEquipment: {
                label: 'Loose equipment',
            },
            equipmentTitle: {
                label: 'Equipment',
            },
            costCenter: {
                label: 'Cost Center',
            },
            serviceInterval: {
                label: 'Service interval',
                helpText: 'Example: \nP3D = 3 days\nP7W = 7 weeks\nP8M = 8 months',
            },
            serviceIntervalKm: {
                label: 'KM Interval',
                helpText: 'Service intervals should be in the warranty manual,\nbe sure you enter correctly',
            },
            hasBrokenSatellite: {
                label: 'Broken Satellite?',
            },
            sendPositionUpdatesToFela: {
                label: 'Share GPS positions with FELA?',
            },
        },
    },
    truckPosition: {
        field: {
            id: {
                label: 'ID',
            },
            dataSource: {
                label: 'Data Source',
            },
            fuelLevel: {
                label: 'Fuel Level',
            },
            measuredAt: {
                label: 'Measured At',
            },
            closestCountry: {
                label: 'Closest Country',
            },
            closestCity: {
                label: 'Closest City',
            },
            closestStreet: {
                label: 'Closest Street',
            },
            odometer: {
                label: 'Odometer',
            },
            point: {
                label: 'Point',
            },
        },
    },
    petrolStation: {
        create: {
            title: 'Create Petrol Station',
        },
        edit: {
            title: 'Edit Petrol Station',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            color: {
                label: 'Color',
            },
            emailAddress: {
                label: 'E-mail address (leave empty if an internal mail should be sent)',
            },
            brand: {
                label: 'Brand',
            },
            price: {
                label: 'Price',
            },
            maximumDistance: {
                label: 'Maximum detour',
            },
            country: {
                label: 'Country',
            },
        },
        overview: {
            maximumDistance: 'Max distance',
        }
    },
    homebase: {
        create: {
            title: 'Create Homebase',
        },
        edit: {
            title: 'Edit Homebase',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
        },
    },
    comment: {
        title: 'Comments',
        sendButtonPopup: 'Shortcut: ctrl + enter',
    },
    driver: {
        bulkActionButton: {
            approveByDCM: 'Approve by DCM',
            approveByPayroll: 'Approve by payroll',
            addDriverCareManager: 'Add Driver Care Manager',
            removeDriverCareManager: 'Remove Driver Care Manager',
            addRecruitmentManager: 'Add Recruitment Manager',
            removeRecruitmentManager: 'Remove Recruitment Manager',
            addCertification:'Add Certification',

        },
        overview: {
            createButton: 'New driver',
        },
        inProgress: {
            filter: {
                completedCertifications: 'Completed certificates',
                missingCertifications: 'Missing certificates',
                priorityStatus: 'Status',
                availabilitiesArrivalConfirmedAt: 'Office Arrival Confirmed',
                availabilitiesTravelToWorkDepartureDatetime: 'Office Arrival Date',
            },
        },
        tweakTacho: 'Manually adjust Tacho setting',
        title: {
            currentDriver1: 'Current driver 1:',
            currentDriver2: 'Current driver 2:',
            nextDriver1: 'Next driver 1:',
            nextDriver2: 'Next driver 2:',
            noCurrentDriver2: 'No current driver 2',
            noNextDriver1: 'No next driver 1',
            noNextDriver2: 'No next driver 2',
        },
        edit: {
            title: 'Edit driver',
            basicInfoTitle: 'Basic info',
            emergencyContactTitle: 'Emergency contact info',
            bepsTitle: 'BEPs',
            remindersTitle: 'Reminders',
            employmentTitle: 'Employment info',
            restrictionsTitle: 'Restrictions',
            certificationsTitle: 'Certifications',
            languagesTitle: 'Languages',
            arrival: 'Arrival',
            departure: 'Departure',
        },
        create: {
            title: 'Add driver',
        },
        filter: {
            wage: {
                label: 'Wages',
                showAll: 'Show all',
                confirmedPayroll: 'Confirmed by payroll',
                confirmedDcm: 'Confirmed by DCM',
                notConfirmedPayroll: 'Not confirmed by payroll',
                notConfirmedDcm: 'Not confirmed by DCM',
            },
            dietDay: {
                datePicker: 'Finance date picker',
                label: 'Diet days',
                showAll: 'Show all',
                onlyNotConfirmed: 'Only not confirmed',
            },
            isAvailableOn: 'Is available on date',
            reminders: {
                label: 'Reminders',
                showAll: 'Show all',
                onlyWithReminders: 'Only with reminders',
            },
            beps: {
                label: 'BEPs',
                showAll: 'Show all',
                onlyWithBeps: 'Only with BEPs',
            },
            currentStatus: {
                label: 'Current Status',
            },
            sinceTill: {
                label: 'Since/Till',
                'showAll': 'Show all',
                'onlyAtWork': 'Only at work',
                'onlyAtHome': 'Only at home',
            }
        },
        field: {
            id: {
                label: 'ID',
            },
            entity: {
                label: 'Entity',
            },
            payrollCompany: {
                label: 'Payroll Company',
            },
            careManagers: {
                label: 'Driver Care Managers',
                required: 'At least 1 Driver Care Manager must be selected.'
            },
            name: {
                label: 'Name',
            },
            iban: {
                label: 'IBAN',
            },
            dataSource: {
                label: 'Data source',
            },
            dataExternalId: {
                label: 'Drivercode',
            },
            driverTravelToWorkDepartureDatetime: {
                label: 'Date',
            },
            lmsUser: {
                label: 'Talent LMS User',
            },
            ssn: {
                label: 'SSN (Social Security Number)',
            },
            landline: {
                label: 'Landline',
            },
            landline2: {
                label: 'Landline 2',
            },
            mobile: {
                label: 'Mobile',
            },
            mobileRemarks: {
                label: 'Mobile Remarks',
            },
            email: {
                label: 'Email'
            },
            nationality: {
                label: 'Nationality',
            },
            workDays: {
                label: 'Working days',
            },
            homeDays: {
                label: 'Home days',
            },
            dateOfBirth: {
                label: 'Date of birth',
            },
            dateOfEmployment: {
                label: 'Date of employment',
            },
            driverCare: {
                label: 'Driver Care Manager'
            },
            doubleTeam: {
                label: 'Work in Double Team',
                value: {
                    true: 'yes',
                    false: 'no',
                },
            },
            exit: {
                label: 'Terminated',
                showAll: 'Show All',
                onlyExit: 'Only Terminated',
                withoutExit: 'Only Active',
            },
            isStudentDriver: {
                label: 'Student driver',
                options: {
                    true: 'Yes',
                    false: 'No',
                    all: 'N/A', // Not really an option, just for filtering
                },
            },
            inactive: {
                label: 'Inactive',
                options: {
                    true: 'Yes',
                    false: 'No',
                    all: 'N/A', // Not really an option, just for filtering
                },
            },

            reasonOfExit: {
                label: 'Reason of exit',
            },
            dueHomeDate: {
                label: 'Due home',
            },
            workingDay: {
                label: 'Working day',
            },
            lastExternalActivity: {
                label: 'Duration',
            },
            dayStart: {
                label: 'Start',
            },
            dayEnd: {
                label: 'Finish',
            },
            drivingTimeLabel: 'Driving Time',
            dayDrivingTime: {
                label: 'Done',
            },
            remainingDayDrivingTime: {
                label: 'TO-DO (DAY)',
            },
            remainingDayWorkingTime: {
                label: 'TO-DO',
                none: 'None',
            },
            canWork15Hours: {
                label: 'Extended (15h/9h)',
                options: {
                    true: 'Yes, {{times}}x',
                    false: 'No',
                    '2nddriver': 'Two drivers',
                },
            },
            canDriveExtended: {
                label: {
                    10: 'Extended (10h)',
                    20: 'Extended (20h)',
                },
                options: {
                    true: 'Yes, {{times}}x',
                    false: 'No',
                },
            },
            workingTime: {
                label: 'Done',
            },
            break: {
                label: 'Next Break',
            },
            weekendBreak: {
                label: 'Weekend Break',
            },
            weekendBreakHours: {
                label: 'Length',
            },
            weekendBreakStart: {
                label: 'Start',
            },
            nextBreakHours: {
                label: 'Length',
            },
            nextBreakStart: {
                label: 'Start',
            },
            operationalWeekStartedAt: {
                label: 'Operational Week Started',
            },
            remainingWeekDrivingTime: {
                label: 'TO-DO (week)',
            },
            arrivalPlace: {
                label: 'Arrives at',
            },
            arrivalDatetime: {
                label: 'Arrival',
            },
            arrivalTravelMethod: {
                label: 'Arrives by',
            },
            departureDatetime: {
                label: 'Departure',
            },
            departurePlace: {
                label: 'Going home from',
            },
            departureTravelMethod: {
                label: 'Going home by',
            },
            blockedCountries: {
                label: 'Blocked countries (not allowed to drive in)',
                shortLabel: 'Blocked countries',
                reverseLabel: 'Allowed countries',
            },
            driverBlockedCountries: {
                label: 'Blocked countries',
            },
            blockedCustomers: {
                label: 'Blocked customers',
                reverseLabel: 'Allowed customers',
            },
            experiences: {
                label: '{{type}} exp.',
            },
            driverExperiences: {
                label: 'Experiences',
            },
            driverCertifications: {
                label: 'Certifications',
            },
            age: {
                label: 'Age',
            },
            simpleCard: {
                label: 'Simpled Card €',
            },
            initialDriverLevel: {
                label: 'Initial driver level',
            },
        },
    },
    driverLanguage: {
        field: {
            language: {
                label: 'Language',
            },
            level: {
                label: 'Level',
            },
        },
    },
    driverLeadLanguage: {
        field: {
            language: {
                label: 'Language',
            },
            level: {
                label: 'Level',
            },
        },
    },
    driverLead: {
        overview: {
            createButton: 'Add',
            inProgressButton: 'In Progress',
            hireButton: 'Hire',
            rejectButton: 'Reject',
        },
        referralSource: {
            type: {
                'driver': 'Driver',
                'user': 'User',
                'lead_generation_campaign': 'Lead generation campaign',
            },
        },
        field: {
            id: {
                label: 'ID',
            },
            createdAt: {
                label: 'Created At',
            },
            entity: {
                label: 'Entity',
            },
            nationality: {
                label: 'Nationality',
            },
            initialDriverStatus: {
                label: 'Initial driver status',
            },
            initialDriverStatusDate: {
                label: 'Initial driver status date',
            },
            initialDriverLevel: {
                label: 'Initial driver level',
            },
            status: {
                label: 'Status',
                value: {
                    new: 'New',
                    'in progress': 'In Progress',
                    hired: 'Hired',
                    rejected: 'Rejected',
                },
            },
            rejectionReason: {
                label: 'Rejection Reason',
            },
            rejectionExplanation: {
                label: 'Rejection Explanation',
            },
            referralSourceType: {
                label: 'From',
            },
            referralSourceLeadGenerationCampaign: {
                label: 'Lead generation campaign',
            },
            referralSourceDriver: {
                label: 'Driver',
            },
            referralSourceUser: {
                label: 'User',
            },
            name: {
                label: 'Name',
            },
            landline: {
                label: 'Landline',
            },
            landline2: {
                label: 'Landline2',
            },
            mobile: {
                label: 'Mobile',
            },
            mobileRemarks: {
                label: 'Mobile Remarks',
            },
            email: {
                label: 'Email',
            },
            nextActionStep: {
                label: 'Next Action',
                value: {
                    '': 'No next action',
                    call: 'Call',
                    interview: 'Interview',
                },
            },
            nextActionAt: {
                label: 'Next Action At',
            },
            workDays: {
                label: 'Working days',
            },
            homeDays: {
                label: 'Home days',
            },
            currentComment: {
                label: 'Comment',
            },
            payrollCompany: {
                label: 'Payroll Company',
            },
            careManagers: {
                label: 'Recruitment Managers',
            },
            certifications: {
                label: 'Certifications'
            },
        },
    },
    driverAvailability: {
        confirmed: 'Confirmed?',
        availabilityRange: 'Arrival -> Departure',
        saveAndConfirmArrivalSuffix: ' & Confirm Arrival',
        saveAndUnconfirmArrivalSuffix: ' & Unconfirm Arrival',
        saveAndConfirmDepartureSuffix: ' & Confirm Departure',
        saveAndUnconfirmDepartureSuffix: ' & Unconfirm Departure',
        editModal: {
            header: 'Edit driver availability',
        },
        overlapModal: {
            title: 'Overlap detected!',
            content: 'You are trying to save an availability, but we\'ve found other overlapping availabilities.\nOverwrite the following availabilities?',
            saveButton: 'Save & Overwrite',
        },
        field: {
            id: {
                label: 'ID',
            },
            travelToWorkDeparturePlace: {
                label: 'Departure from',
            },
            arrivalPlace: {
                label: 'Arrival at',
            },
            arrivalTravelMethod: {
                label: 'Arrives by',
            },
            arrivalDatetime: {
                label: 'Arrival date',
            },
            departureDatetime: {
                label: 'Departure date',
            },
            departurePlace: {
                label: 'Departure from',
            },
            travelToHomeArrivalPlace: {
                label: 'Arrival at',
            },
            departureTravelMethod: {
                label: 'Departure by',
            },
        },
    },
    driverAvailabilityDefault: {
        field: {
            travelToWorkDeparturePlace: {
                label: 'Default Departure from',
            },
            arrivalPlace: {
                label: 'Default Arrival at',
            },
            arrivalTravelMethod: {
                label: 'Default Arrives by',
            },
            departurePlace: {
                label: 'Default Departure from',
            },
            travelToHomeArrivalPlace: {
                label: 'Default Arrival at',
            },
            departureTravelMethod: {
                label: 'Default Departure by',
            },
        },
    },
    dietDay: {
        field: {
            workSince: {
                label: 'Work Since'
            },
            workTill: {
                label: 'Work Till'
            },
        },
    },
    driverWithStudent: {
        field: {
            workSince: {
                label: 'Work Since'
            },
            workTill: {
                label: 'Work Till'
            },
        },
    },
    driverInContract: {
        field: {
            workSince: {
                label: 'Work Since'
            },
            workTill: {
                label: 'Work Till'
            },
            type: {
                label: 'Contract type'
            },
        },
    },
    driverInCountry: {
        field: {
            workSince: {
                label: 'Work Since'
            },
            workTill: {
                label: 'Work Till'
            },
            countries: {
                label: 'Countries'
            },
        },
    },
    driverStatusChange: {
        listEditTitle: 'Status',
        field: {
            choosenCalculationRuleCountry: {
                label: 'Choose country',
            },
            choosenCalculationRuleDescriptions: {
                label: 'Choose description',
            },
            dietPerDay: {
                label: 'Diet per day',
            },
            calculationRule: {
                text: '{{name}}'
            },
            id: {
                label: 'ID',
            },
            date: {
                label: 'Date',
            },
            exitReason: {
                label: 'Exit Reason',
            },
            reason: {
                label: 'Reason',
            },
            rejectionReason: {
                label: 'Rejection Reason',
            },
            rejectionExplanation: {
                label: 'Rejection Explanation',
            },
            level: {
                label: 'Level',
            },
            status: {
                label: 'Status',
                value: {
                    driver: 'Driver',
                    student: 'Student',
                    'in progress': 'In Progress',
                    exit: 'Exit',
                    rejected: 'Rejected',
                    null: 'Empty Status',
                    inactive: 'Inactive',
                }
            },
        },
    },
    claimAction: {
        listEditTitle: 'Actions',
        field: {
            id: {
                label: 'ID',
            },
            date: {
                label: 'Date',
            },
            reason: {
                label: 'Reason',
            },
            action: {
                label: 'Action',
                value: {
                    informedInsurance: 'Informed insurance',
                    sentDocuments: 'Sent documents',
                    estimateConfirmed: 'Estimate confirmed',
                    amountsConfirmed: 'Amounts confirmed',
                    receivedPayment: 'Received payment',
                    finishedWithoutPayment: 'Finished without payment',
                    '': 'Empty action',
                }
            },
        },
    },
    emergencyContact: {
        field: {
            name: {
                label: 'Emergency contact name',
            },
            relationToDriver: {
                label: 'Relation to driver',
            },
            landline: {
                label: 'Landline',
            },
            mobile: {
                label: 'Mobile',
            },
            doctorName: {
                label: 'Doctor name',
            },
            doctorPhone: {
                label: 'Doctor phone',
            },
        },
    },
    leadGenerationChannel: {
        create: {
            title: 'Create Lead Generation Channel',
        },
        edit: {
            title: 'Edit Lead Generation Channel',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
        },
    },
    leadGenerationCampaign: {
        create: {
            title: 'Create Lead Generation Campaign',
        },
        edit: {
            title: 'Edit Lead Generation Campaign',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            leadGenerationChannel: {
                label: 'Channel',
            },
        },
    },
    driverCertification: {
        title: 'Certifications',
        requiredOncePresentTitle: 'Documents',
        noName: 'Empty certificate',
        createButton: 'Add certificate',
        field: {
            certification: {
                label: 'Certificate type',
            },
            dateObtained: {
                label: 'Obtained at',
            },
            dateOfExpiry: {
                label: 'Expires at',
                expired: 'expired',
            },
            hasDiploma: {
                label: 'Has diploma',
            },
            documentNumber: {
                label: 'Document number',
            },
            documentScan: {
                label: 'Driver certification: ',
                dropText: 'Click or drag a file to upload a driver certification',
                downloadExisting: 'Download existing file',
                showVariables: 'Show scan variables',
            },
        },
    },
    dietRule: {
        needExistingDietRule: 'You have to save this diet rule before adding calculation rules',
        editButton: 'Edit diet rule',
        deleteButton: 'Delete diet rule',
        view: {
            title: 'Diet rule',
            calculationRules: 'Calculation rules'
        },
        edit: {
            title: 'Edit diet rule',
            createDietRuleButton: 'Add',
        },
        create: {
            title: 'Create diet rule',
        },
        overview: {
            createButton: 'Create diet rule',
        },
        field: {
            id: {
                label: 'ID',
            },
            description: {
                label: 'Description'
            },
            name: {
                label: 'Name',
            },
            status: {
                label: 'Status',
            },
            availableFields: {
                label: 'Available fields',
                helpText: 'Fields that the user can see and enter. If nothing is selected, then all fields are available.',
                value: {
                    description: 'description',
                    level: 'level',
                }
            },
            requiredFields: {
                label: 'Required fields',
                helpText: 'Fields that are required to enter',
                value: {
                    description: 'description',
                    level: 'level',
                }
            },
        }
    },
    certification: {
        editButton: 'Edit certification',
        deleteButton: 'Delete certification',
        view: {
            title: 'Certification',
        },
        edit: {
            title: 'Edit certification',
        },
        create: {
            title: 'Create certification',
        },
        overview: {
            createButton: 'Create certification',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            slug: {
                label: 'Slug',
            },
            availableFields: {
                label: 'Available fields',
                helpText: 'Fields that the user can see and enter. If nothing is selected, then all fields are available.',
                value: {
                    issued_at: 'Issued At',
                    date_obtained: 'Date obtained',
                    date_of_expiry: 'Date of expiry',
                    document_number: 'Document number',
                    document_scan: 'Document scan',
                    has_diploma: 'Has diploma?',
                }
            },
            requiredFields: {
                label: 'Required fields',
                helpText: 'Fields that are required to enter',
                value: {
                    issued_at: 'Issued At',
                    date_obtained: 'Date obtained',
                    date_of_expiry: 'Date of expiry',
                    document_number: 'Document number',
                    document_scan: 'Document scan',
                    has_diploma: 'Has diploma?',
                }
            },
            dependsOn: {
                label: 'Depends On',
            },
            kind: {
                label: 'Kind',
                value: {
                    'recruitment_document': 'Recruiter',
                    'permit': 'Immigrations',
                    'training': 'Trainings / Tests',
                    'hr_document': 'HR Administration',
                }
            },
            priority: {
                label: 'Priority',
                value: {
                    blocking: 'Not possible to hire & doc is required',
                    required: 'Possible to hire & doc is required',
                    optional: 'Possible to hire & doc is optional',
                }
            },
            iconName: {
                label: 'Icon',
            },
            iconColor: {
                label: 'Color',
            },
            groupReminders: {
                label: 'Reminders for roles',
            },
            addCertificationBulkAction: {
                label: 'Bulk action',
                value: {
                    true: 'Yes',
                    false: 'No',
                }
            },
            requiredOncePresent: {
                label: 'Type',
                value: {
                    true: 'Document',
                    false: 'Certification',
                }
            },
            isSystem: {
                label: 'System',
            },
            courses: {
                label: 'LMS courses (when passed, certification is created)',
            },
            winspedCode: {
                label: 'Winsped code',
                description: 'When code is set. This certificate is considered to be sent to Winsped. So if code is set for example: "A", it will be sent with other documents. Also for example if we have 5 different gdp certification - driver need only one of five to satisfy the demand '
            },
        },
        save: {
            error: '',
            success: '',
        },
    },
    license: {
        editButton: 'Edit license',
        deleteButton: 'Delete license',
        view: {
            title: 'License',
        },
        edit: {
            title: 'Edit license',
        },
        create: {
            title: 'Create license',
        },
        overview: {
            createButton: 'Create license',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            slug: {
                label: 'Slug',
            },
        },
        save: {
            error: '',
            success: '',
        },
    },
    driverBonus:{
        view: {
            title: 'Driver Bonus',
        },
        edit: {
            title: 'Edit Driver Bonus',
        },
        create: {
            title: 'Create Driver Bonus',
        },
        overview: {
            createButton: 'Create Driver Bonus',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            type: {
                label: 'Type'
            },
            slug: {
                label: 'Slug',
            },
            bonusOrDeductionFromBep: {
                label: 'From BEP'
            },
        },
    },
    bonus:{
        finalizeButton: 'Finalize',
        unfinalizeButton: 'Unfinalize',
        listEditTitle: 'Bonus',
        empty: 'No bonuses found',
        pending: 'Pending',
        completed: 'Completed',
        field: {
            id: {
                label: 'ID',
            },
            dateObtained: {
                label: 'Date Obtained',
            },
            slug: {
                label: 'Slug',
            },
            bonusRate: {
                label: 'Rate'
            },
            remark: {
                label: 'Remark'
            },
            type: {
                label: 'Type'
            },
        },
    },
    wage:{
        approveByDCMButton: 'Approve by DCM',
        unapproveByDCMButton: 'Unapprove by DCM',
        approveByPayrollButton: 'Approve by Payroll',
        unapproveByPayrollButton: 'Unapprove by Payroll',
        rejectByPayrollButton: 'Reject by Payroll',
        downloadButton: 'Download',
        listEditTitle: 'Wages calculation',
        pending: 'Pending',
        completed: 'Completed',
        approved: 'Approved',
        empty: 'No wages found',
        period: 'Period',
        lastUpdated: 'Last updated',
        lastModified: 'Last modified',
        salaryAmount: 'Salary amount',
        recalculationSalaryAmount: 'Recalculation salary amount',
        driverCode: "Driver's code",
        driverName: "Driver's name",
        euDocuments: "EU Documents",
        driverlevel: "Driver's level",
        dietDays: "Diet Days",
        dietDaysE: "Diet Days €",
        totalSalary: "Total Salary",
        bonusTotal: 'Bonus (total)',
        subtotal: 'Subtotal',
        deductions: 'Deductions',
        total: 'Total',
        takeStudent: 'Take student',
        country: 'Country',
        customer: 'Customer',
        payroll: 'Payroll name',
        category: 'Category',
        date: 'Date',
        amount: 'Amount',
        addedBy: 'Added by',
        approvedBy: 'Approved by',
        description: 'Remark',
        toggleSystemHistory: 'History',
        model: 'Model',
        field: 'Field changed',
        before: 'Before',
        after: 'After',
        id: 'Id',
        updatedAt: 'Updated at',
        updatedBy: 'Updated by',
        wageCalculation: 'Wage calculation:',
        additions: 'Additions list (bonus & deductions):',
        history: 'History logs',
        days: 'days',
        rate: 'rate',
    },
    declaration:{
        listEditTitle: 'Declarations',
        pending: 'Pending',
        completed: 'Completed',
    },
    payMeNow:{
        listEditTitle: 'Pay me now',
        pending: 'Pending',
        completed: 'Completed',
    },
    rejectionReason:{
        view: {
            title: 'Rejection Reason',
        },
        edit: {
            title: 'Edit Rejection Reason',
        },
        create: {
            title: 'Create Rejection Reason',
        },
        overview: {
            createButton: 'Create Rejection Reason',
        },
        field: {
            id: {
                label: 'ID',
            },
            description: {
                label: 'Description',
            },
            kind: {
                label: 'Kind',
            },
            slug: {
                label: 'Slug',
            },
        },
    },
    tollboxSupplier: {
        editButton: 'Edit Tollbox Supplier',
        deleteButton: 'Delete Tollbox Supplier',
        view: {
            title: 'Tollbox Supplier',
        },
        edit: {
            title: 'Edit Tollbox Supplier',
        },
        create: {
            title: 'Create Tollbox Supplier',
        },
        overview: {
            createButton: 'Create Tollbox Supplier',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
        },
    },
    contractType: {
        editButton: 'Edit Contract Type',
        deleteButton: 'Delete Contract Type',
        view: {
            title: 'Contract Type',
        },
        edit: {
            title: 'Edit Contract Type',
        },
        create: {
            title: 'Create Contract Type',
        },
        overview: {
            createButton: 'Create Contract Type',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            remarks: {
                label: 'Remarks',
            },
        },
    },
    language: {
        createButton: 'Add',
        field: {
            language: {
                label: 'Language',
            },
            level: {
                label: 'Level',
            },
        },
    },
    bepDocument: {
        create: {
            title: 'Upload new document',
        },
        edit: {
            title: 'Edit existing document',
        },
        field: {
            id: {
                label: 'ID',
            },
            title: {
                label: 'Name',
            },
            file: {
                label: 'File',
            },
        }
    },
    bepTicket: {
        upcoming: 'Upcoming',
        completed: 'Completed',
        empty: 'No BEP Tickets found',
        editButton: 'Edit BEP Ticket',
        deleteButton: 'Delete BEP Ticket',
        addProaFollowupButton: 'Add PROA',
        addCategoryFollowupButton: 'Add Follow-up',
        overview: {
            showFinishedFinalized: 'Show',
            createButton: 'New ticket',
            bulkActionButton: {
                choose: 'Choose Bulk Action ({{count}})',
                addProa: 'Add PROA',
                addFollowups: 'Add followups',
            },
        },
        edit: {
            titleWithoutContext: 'Edit {{meaning}} - Basis for Excellence in Processes',
            titleWithContext: 'Edit {{meaning}} for {{context}} - Basis for Excellence in Processes',
            finishButton: 'Finish',
            unfinishButton: 'Unfinish',
            reviewButton: 'Save, review and finalize',
            unreviewButton: 'Delete review (unfinalize)',
            bonusReviewButton: 'Reject bonus and save',
            bonusUnreviewButton: 'Unreject',
        },
        create: {
            titleWithoutContext: 'Create {{meaning}} - Basis for Excellence in Processes',
            titleWithContext: 'Create {{meaning}} for {{context}} - Basis for Excellence in Processes',
        },
        review: {
            title: 'Review {{meaning}} - Basis for Excellence in Processes',
            reviewButton: 'Review and finalize',
        },
        filter: {
            followupActionDateFrom: 'Follow-up action date from',
            followupActionDateTo: 'Follow-up action date till',
            followupActionTaker: 'Follow-up action taker',
            createdBy: 'Created by',
            all: 'All',
            unfinished: 'Unfinished',
            unfinalized: 'Unfinalized',
        },
        contextDescription: '{{contextType}} {{contextText}}',
        contextType: {
            activity: 'Activity',
            invoice: 'Invoice',
            truck: 'Truck',
            trailer: 'Trailer',
            incident: 'Incident',
            none: '<none>',
        },
        subjectType: {
            driver: 'Driver',
            customer: 'Customer',
            user: 'User',
            entity: 'Entity',
            supplier: 'Supplier',
            subcategory: 'Other',
        },
        meaning: {
            negative: 'Negative feedback',
            positive: 'Positive feedback',
            idea: 'Idea',
        },
        systemFunction: {
            'driver ticket on customer document rejection': 'driver ticket on customer document rejection',
            'dispatcher ticket on customer document rejection': 'dispatcher ticket on customer document rejection',
        },
        impact: {
            large: 'Large',
            small: 'Small',
        },
        qualitySystem: {
            iso: 'ISO',
            aeo: 'AEO',
            tapa: 'TAPA',
            gdp: 'GDP',
        },
        field: {
            id: {
                label: 'ID',
                editLabel: 'BEP ID',
            },
            meaning: {
                label: 'Meaning',
                value: {
                    positive: 'Positive',
                    negative: 'Negative',
                    idea: 'Idea'
                },
            },
            impact: {
                label: 'Impact',
            },
            description: {
                label: 'Description',
            },
            category: {
                label: 'Category',
            },
            entity: {
                label: 'Entity',
            },

            // These are pseudo-fields which don't really exist
            subjectFrom: {
                label: 'From',
            },
            subjectTo: {
                label: 'To',
            },

            subjectUser: {
                label: 'User',
            },
            subjectEntity: {
                label: 'Entity',
            },
            subjectDriver: {
                label: 'Driver',
            },
            subjectCustomer: {
                label: 'Customer',
            },
            subjectSupplier: {
                label: 'Supplier',
            },
            subjectSubcategory: {
                label: 'Other',
            },

            context: {
                label: 'Context',
            },
            contextTruck: {
                label: 'Truck',
            },
            contextTrailer: {
                label: 'Trailer',
            },
            contextActivity: {
                label: 'Activity',
            },
            contextInvoice: {
                label: 'Invoice',
            },
            contextIncident: {
                label: 'Incident',
            },

            qualitySystems: {
                label: 'Quality systems involved',
            },
            needsFollowup: {
                label: 'Needs follow-up',
            },
            followupActionCategory: {
                label: 'Requested action category',
            },
            followupActionDate: {
                label: 'Follow-up action date',
            },
            followupActionTaker: {
                label: 'Follow-up action taker',
            },
            followups: {
                label: 'Follow-up actions and PROAs',
            },
            bonusFollowUp: {
                label: 'Bonus follow up'
            },
            deductionFollowUp: {
                label: 'Deduction follow up'
            },
            documents: {
                label: 'Attached documents',
            },

            finishedAt: {
                label: 'Finished at',
            },
            finishedBy: {
                label: 'Finished by',
            },

            review: {
                label: 'Review',
            },
            reviewedAt: {
                label: 'Reviewed at',
            },
            reviewedBy: {
                label: 'Reviewed by',
            },

            createdBy: {
                label: 'Created by',
            },
            createdAt: {
                label: 'Created at',
            },
            updatedAt: {
                label: 'Updated at',
            },
        },
    },
    bepTicketCategory: {
        editButton: 'Edit BEP Ticket category',
        deleteButton: 'Delete BEP Ticket category',
        edit: {
            title: 'Edit BEP Ticket category',
        },
        create: {
            title: 'Create BEP Ticket category',
        },
        overview: {
            createButton: 'New category',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            needsFollowup: {
                label: 'Needs follow-up?',
            },
            followupActionDays: {
                label: 'Default days for follow-up action',
            },
            followupActionTaker: {
                label: 'Default follow-up action taker',
            },
            followupActionTakerIsFirstDriverCareManager: {
                label: 'Related to care manager',
            },
            relatedToSalary: {
                label: 'Related to salary'
            },
            autoFinalizeSalary: {
                label: 'Autofinalize salary'
            },
            bepAmount: {
                label: 'BEP amount'
            },
            numberOfRepetitions: {
                label: 'Number of repetitions'
            },
            fixed: {
                label: 'Fixed'
            },
            followupActionCategory: {
                label: 'Default follow-up action category',
            },
            meanings: {
                label: 'For meanings',
            },
            systemFunctions: {
                label: 'For system functions',
            },
            subjectToTypes: {
                label: 'For subject to types',
            },
            subjectToSubcategories: {
                label: 'For subject to "other"',
            },
            createdAt: {
                label: 'Created at',
            },
            updatedAt: {
                label: 'Updated at',
            },
        },
    },
    bepFollowup: {
        proaOrCategory: 'PROA or category',
        editButton: 'Edit BEP Ticket follow-up',
        field: {
            id: {
                label: 'ID',
            },
            bepTicket: {
                label: 'BEP Ticket',
            },
            proaReference: {
                label: 'PROA Reference',
            },
            performedActionCategory: {
                label: 'Performed action category',
            },
            description: {
                label: 'Description',
            },

            performedAt: {
                label: 'Action performed at',
            },
            performedBy: {
                label: 'Action performed by',
            },

            createdBy: {
                label: 'Created by',
            },
            createdAt: {
                label: 'Created at',
            },
            updatedAt: {
                label: 'Updated at',
            },
        },
    },
    bepFollowupActionCategory: {
        editButton: 'Edit BEP Follow-up action category',
        edit: {
            title: 'Edit BEP follow-up action category',
        },
        create: {
            title: 'Create BEP follow-up action category',
        },
        overview: {
            createButton: 'New category',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            createdAt: {
                label: 'Created at',
            },
            updatedAt: {
                label: 'Updated at',
            },
        },
    },
    entityInvoice: {
        editButton: 'Edit entity invoice',
        deleteButton: 'Delete entity invoice',
        invoiceTotals: 'Invoice totals',
        create: {
            title: 'Create entity invoice',
        },
        edit: {
            title: 'Edit entity invoice',
        },
        overview: {
            availableInvoicesForInvoices: 'Available invoices - Transport',
            availableInvoicesForLeaseContracts: 'Available invoices - Leasing',
            filter: {
                onlyNotSent: 'Only not sent',
                invoiceDateRange: 'Invoice date range',
                entityFrom: 'Entity from (invoicing entity)',
                entityTo: 'Entity to (invoiced entity)',
                search: 'Search',
            },
        },
        field: {
            id: {
                label: 'ID',
            },
            type: {
                label: 'Type',
                options: {
                    'invoices': 'Transport',
                    'lease contracts': 'Leasing',
                },
            },
            entityTo: {
                label: 'Entity to',
            },
            entityFrom: {
                label: 'Entity from',
            },
            invoiceDate: {
                label: 'Invoice date',
            },
            invoiceNumber: {
                label: 'Invoice number',
            },
            totalAmount: {
                label: 'Total amount to invoice',
            },
            remarks: {
                label: 'Remarks',
            },
            createdAt: {
                label: 'Created at',
            },
            updatedAt: {
                label: 'Updated at',
            },
        },
        downloadPdfButton: 'Download PDF',
        modalEntityInvoiceSelectInvoice: {
            title: 'Select invoices ({{count}})',
            totals: 'Totals',
            notFinalised: "Can't select invoice for entity invoice because it's not sent yet",
        },
        modalEntityInvoiceSelectLeaseContract: {
            title: 'Select lease contracts for {{year}}-{{month}} ({{count}})',
            totals: 'Totals',
        },
        defaultSubject: 'Entity invoice {{{entityToName}}}}',
        defaultContent: 'Dear {{{entityToName}}} team,\nIn the attachment you will find the entity invoice from {{{entityFromName}}} to {{{entityToName}}}.',
        editInvoice: {
            addAll: 'Add all to entity invoice',
            addSelection: 'Add selection to entity invoice',
            findInvoiceButton: 'Find invoice',
            findLeaseContractButton: 'Find contract',
            saveButton: 'Save',
            saveAndPreviewPdfButton: 'Save and preview PDF',
            saveAndFinalizeButton: 'Save and finalize',
            finalizeButton: 'Finalize',
        },
    },
    invoicedContractMonth: {
        field: {
            month: {
                label: 'Month',
            },
            year: {
                label: 'Year',
            },
        },
    },
    invoice: {
        agreed: 'Agreed',
        corrected: 'Corrected',
        displayType: {
            'invoiced': 'Invoiced',
            'expected': 'Expected',
        },
        modalChangeEu: {
            title: 'Change EU',
        },
        downloadPdfButton: 'Download PDF',
        approveByClientButton: 'Approve',
        rejectByClientButton: 'Reject',
        editButton: 'Edit invoice',
        viewButton: 'View invoice',
        deleteButton: 'Delete invoice',
        creditButton: 'Create credit invoice',
        exactLogo: 'Invoice exported to Exact with id:',
        finalizeButton: 'Finalize',
        sendDraftButton: 'Send Draft',
        recreatePdf: 'Recreate PDF',
        overview: {
            selectActivitiesButton: 'Select activities',
            invoicedButton: 'Invoiced',
            createActivityInvoiceButton: 'Add new invoice',
            availableInvoices: 'Available invoices',
            noActiveTariff: 'No active tariff found, please add a tariff',
            onlyNotSent: 'Only not sent',
        },
        disputed: {
            addWaiverTitle: 'Handle dispute',
            cantAddWaiverTitle: 'Handle dispute only available if invoice is finalised',
            addCorrectionTitle: 'Add correction',
            viewInvoiceTitle: 'View invoice',
            invoicedButton: 'Invoiced',
            createActivityInvoiceButton: 'Add new invoice',
            availableInvoices: 'Available invoices',
            noActiveTariff: 'No active tariff found, please add a tariff',
        },
        waivers: {
            title: 'Waivers',
        },
        modalInvoiceSelectActivity: {
            title: 'Select activities ({{count}})',
            checkAllSameOrderReference: 'Select all for this order reference',
            checkAllCurrentPage: 'Select all from current page',
            checkAllAllPages: 'Select all from all pages',
            totals: 'Selected Totals',
            invoiceTotals: 'Invoice Totals',
            orderReference: 'Order reference: {{orderReference}}',
            noOrderReference: 'No order reference',
            onlyAsBundleAllowed: 'These activities can only be bundled',
            filter: {
                to: 'City',
                searchRef: 'Order reference',
                finalizedAtFrom: 'Finalized from',
                customerId: 'Customer Id',
                orderReference: 'Order Reference',
                trailer: 'Trailer',
                finalizedAtTill: 'Finalized till',
                orderArrivalFrom: 'Order arrival from',
                orderArrivalTill: 'Order arrival till',
                date: 'Date',
                withInvoiced: 'Invoiced?',
                invoiced: 'Invoiced',
                withDisputed: 'Disputed?',
                disputed: 'Disputed',
                onlyFinalized: 'Finalized?',
                onlyInvoiceable: 'Invoiceable?',
                invoiceDateFrom: 'Invoice date from',
                invoiceDateTo: 'Invoice date till',
            },
            notFinalised: "Can't select activity for invoice because it's not finalised yet",
        },
        modalInvoicePdf: {
            title: 'Invoice PDF',
        },
        editActivity: {
            totals: 'Totals',
            customerTitle: 'Customer calculation invoice',
            boekTitle: 'Boekestijn calculation invoice',
            creditTitle: 'Credit invoice',
            customer: 'Customer',
            contract: 'Contract | Tariff',
            corrections: 'Corrections',
            activityLineItems: 'Activity line items ({{count}})',
            chooseTariff: 'Choose customer & contract',
            customLineItems: 'Custom line items ({{count}})',
            availableActivities: 'Available activities',
            expected: 'Expected',
            invoiced: 'Invoiced',
            addBundledLine: 'Bundle & Add {{count}} items to invoice',
            addBundledLineNotInvoicable: 'Cannot invoice, because not all activities are finished.',
            addLine: 'Add {{count}} items to invoice',
            addAllLine: 'Add all to invoice',
            addBundledAllLine: 'Bundle all & Add all to invoice',
            selectedActivityCount: '{{count}} selected',
            saveButton: 'Save',
            saveAndPreviewPdfButton: 'Save & preview PDF',
            saveAndSendDraftButton: 'Save & send draft',
            saveAndFinalizeButton: 'Save & finalize',
            saveButtonHooverNull: 'Total amount of the invoice is zero',
            saveButtonHoover: 'Total is not yet equal to the total customer invoice',
            basicInfo: 'Invoice details',
            addCustomLineItemButton: 'Add custom line item',
            findActivityButton: 'Find activity',
            correction: 'Correction',
            from: 'From',
            to: 'To',
            orderReference: 'Order ref',
            addCorrectionLineItemButton: 'Add Correction line item',
        },
        createActivity: {
            customerTitle: 'Create customer calculation invoice',
            boekTitle: 'Create Boekestijn calculation invoice',
        },
        field: {
            id: {
                label: 'Invoice ID',
            },
            invoiceDate: {
                label: 'Date',
                overrideLabel: 'Override Invoice Date',
            },
            invoiceNumber: {
                label: 'Number',
            },
            customerReference: {
                label: 'Reference',
                requiredOnDraft: 'When sending a draft, the reference is required.',
                requiredOnFinalize: 'When finalizing, the reference is required.',
            },
            customer: {
                label: 'Customer',
            },
            contract: {
                label: 'Contract',
            },
            amount: {
                label: 'Total',
            },
            amountFc: {
                label: '{{currencySymbol}} TOTAL',
            },
            expectedAmount: {
                label: 'Expected',
            },
            clientExpectedAmount: {
                label: 'Client expected amount',
            },
            invoicedAmount: {
                label: 'Invoiced',
            },
            invoicedCustomAmount: {
                label: 'Custom invoiced',
            },
            disputedAmount: {
                label: 'Disputed',
            },
            correctedAmount: {
                label: 'Corrected',
            },
            waivedAmount: {
                label: 'Waived',
            },
            remarks: {
                label: 'Remarks',
            },
            description: {
                label: 'Description',
            },
            selfBilling: {
                label: 'Self billing',
                options: {
                    true: 'Customer calculation',
                    false: 'Boekestijn calculation',
                },
            },
            sentAt: {
                label: 'Sent at',
            },

        },
        modalSelectCorrection: {
            title: 'Select Corrections ({{count}})',
            button: {
                addAll: 'Add all to invoice',
                addSelected: 'Add {{count}} items to invoice'
            },
            filter: {
                orderReference: {
                    label: 'Order reference'
                },
                invoiceNumber: {
                    label: 'Invoice number'
                },
                finishedOn: {
                    label: 'Finished on'
                },
                status: {
                    label: 'Current status'
                }
            }
        }
    },
    invoiceLine: {
        field: {
            id: {
                label: 'Invoice line ID',
            },
            kmAmount: {
                label: '€ KM',
            },
            fixedAmount: {
                label: '€ Fixed',
            },
            weekendAmount: {
                label: '€ Weekend',
            },
            waitingAmount: {
                label: '€ Waiting',
            },
            invoicedKm: {
                label: 'Invoiced KM',
            },
            secondDriverAmount: {
                label: '€ Second driver',
            },
            otherCostsAmount: {
                label: '€ Other costs',
            },
            tollAmount: {
                label: '€ Toll costs',
            },
            customAmount: {
                label: '€ Custom',
            },
            amount: {
                label: 'Total',
            },
            description: {
                label: 'Description',
            },
        },
        buttons: {
            recalculate: {
                label: 'Recalculate invoiceline',
            },
            selectActivities: {
                label: 'Add activities to invoiceline',
            },
            delete: {
                label: 'Remove invoiceline',
            },
            medkit: {
                label: 'Recalculate invoiceline items',
            },
            moveUp: {
                label: 'Move up'
            },
            moveDown: {
                label: 'Move down'
            }
        },
    },
    cost: {
        field: {
            id: {
                label: 'ID',
            },
            date: {
                label: 'Date',
            },
            remarks: {
                label: 'Remarks',
            },
            amount: {
                label: 'Amount',
            },
            contract: {
                label: 'Contract',
            },
            createdBy: {
                label: 'Created By',
            },
            createdAt: {
                label: 'Created At',
            },
            type: {
                label: 'Type',
                value: {
                    'costs': 'Costs',
                    'missing revenue': 'Missing revenue',
                },
            },
        },
        overview: {
            createButton: 'New Cost Registration',
        },
        create: {
            title: 'New Cost Registration',
        },
        edit: {
            title: 'Edit Cost Registration',
        },
    },
    invoiceLineCorrection: {
        field: {
            id: {
                label: 'ID',
            },
            invoicedAmount: {
                label: 'Agreed with customer',
            },
            description: {
                label: 'Correction Remarks',
            },
            createdBy: {
                label: 'Created by',
            },
        },
        disputed: 'Disputed',
        activityFinishedFilter: 'Activity finished from',
    },
    waiver: {
        customer: 'Customer',
        autoCreatedUser: 'System',
        field: {
            id: {
                label: 'ID',
            },
            waivedAmount: {
                label: 'Waived amount',
            },
            message: {
                label: 'Waiver Reason',
            },
            remarks: {
                label: 'Waiver Remarks'
            },
            createdBy: {
                label: 'Created by',
            },
        },
    },
    calculationRule:{
        ModalEditCalculationRule: {
            title: 'Calculation rule',
            copy: 'Copy Calculation rule',
            basicInfo: 'Basic info',
            addCalculationRuleDescriptionButton: 'Add',
            addCalculationRuleCountryButton: 'Add',
            addCalculationRuleContractTypeButton: 'Add'
        },

        field: {
            calculationRuleContractType:{
                text: '{{name}}',
            },
            startDate: {
                label: 'Start date',
            },
            expiryDate: {
                label: 'Expiry date',
            },
            studentPerDay:{
                label: 'Student €/Day'
            },
            name: {
                label: 'Name',
            },
            statuses: {
                label: 'Statuses',
            },
        },
    },
    calculationRuleContractType:{
        field: {
            type: {
                label: 'Type',
                type: {
                    text: '{{name}}'
                },
            },
            rate: {
                label: 'Rate',
            },
        },
    },
    calculationRuleCountry:{
        field: {
            country: {
                label: 'Country',
            },
            rate: {
                label: 'Rate',
            },
        },
    },
    calculationRuleDescription:{
        field: {
            description: {
                label: 'Description',
            },
            rate: {
                label: 'Rate',
            },
        },
    },
    tariff: {
        addKmRateButton: 'Add KM rate',
        copy: 'Copy this tariff',
        copyId: 'Copy tariff',
        immutable: 'This tariff cannot be changed, since there are already invoices using it.',
        ModalEditTariff: {
            title: 'Tariff details',
            copy: 'Copy Tariff',
            basicInfo: 'Basic info',
            kmRates: 'Km Rate',
            waitingRate: 'Waiting Rate',
        },
        field: {
            startDate: {
                label: 'Start date',
            },
            rateAgreement: {
                label: 'Rate Agreement',
                droppableButton: 'Upload & save rate agreement. You can also use drop & drop.',
            },
            name: {
                label: 'Name',
            },
            remarks: {
                label: 'Remarks',
            },
            vatRule: {
                label: 'VAT rule',
            },
            vat: {
                label: 'VAT',
            },
            fuel: {
                label: 'FUEL',
            },
            includeToll: {
                label: 'Toll paid separately',
                title: 'Toll is not included in the tariff',
            },
            realisticKmRateOverridePrice: {
                label: 'Use alternate km rate for more realistic price estimation',
                title: 'Leave empty if km rate should always be applied',
            },
            fcCode: {
                label: 'Currency',
            },
        },
    },
    tariffKmRate: {
        field: {
            price: {
                label: '€ / KM',
            },
            priceFc: {
                label: '{{currencySymbol}} / KM'
            },
            minKm: {
                label: 'Min KM',
            },
            invoiceKmField: {
                label: 'KM to pay',
                options: {
                    givenKm: 'Given KM',
                    drivenKm: 'Driven KM',
                },
            },
            fuelSurchargeFactor: {
                label: 'Fuel surcharge',
            },
            fixed: {
                label: 'Fixed',
            },

        },
    },
    tariffFixedRate: {
        field: {
            price: {
                label: 'Price / day',
            },
        },
    },
    fixedRateDay: {
        usedDaysModal: {
            title: 'Used days',
        },
        field: {
            day: {
                label: 'Day',
            },
            activity: {
                label: 'Activity',
            },
        },
    },
    tariffWeekendRate: {
        field: {
            price: {
                label: 'Weekend surcharge',
                short: 'Weekend',
            },
        },
    },
    tariffWaitingRate: {
        field: {
            price: {
                label: 'Waiting € / h',
            },
            priceFc: {
                label: 'Waiting {{currencySymbol}} / h',
            },
            smallestTimeUnit: {
                label: 'Round to',
            },
            billingThreshold: {
                label: 'Billing Threshold (in minutes)',
            },
            maxAmount: {
                label: 'Max day amount',
            },
            roundingMode: {
                label: 'Rounding mode',
                options: {
                    nearest: 'Nearest',
                    up: 'Up',
                    down: 'Down',
                },
            },
            applyForActivityTypes: {
                label: 'Apply for activity types',
                options: {
                    allTypes: 'Trailer change and (un)load',
                    onlyLoadUnload: 'Only (un)load',
                },
            },
            useOrderedArrivalDatetime: {
                label: 'Use arrival time',
                options: {
                    yes: 'Ordered',
                    no: 'Actual',
                },
            },
        },
    },
    tariffSecondDriverRate: {
        field: {
            price: {
                label: '2nd driver / day',
                short: '2nd driver',
            },
        },
    },
    lisIntegration: {
        overview: {
            title: 'LIS Integrations',
            addButton: 'Add LIS Integration',
        },
        create: {
            title: 'Create LIS Integration',
        },
        edit: {
            title: 'Edit LIS Integration',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            $url: {
                label: 'LIS upload SFTP URL',
            },
            localPath: {
                label: 'LIS local path (name)',
            },
            activityLinkRequiredForFinalize: {
                label: 'Require activities to be linked to LIS activities before finalization',
                yes: 'Yes  (only if LIS is active)',
                no: 'No',
            },
        }
    },
    ecsIntegration: {
        overview: {
            title: 'ECS Integrations',
            addButton: 'Add ECS Integration',
        },
        create: {
            title: 'Create ECS Integration',
        },
        edit: {
            title: 'Edit ECS Integration'
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            urlUpload: {
                label: 'ECS upload SFTP URL',
            },
            urlDownload: {
                label: 'ECS download SFTP URL'
            },
            localPath: {
                label: 'ECS local path (name)'
            },
            activityLinkRequiredForFinalize: {
                label: 'Require activities to be linked to ECS activities before finalization',
                    yes: 'Yes (only if ECS is active)',
                    no: 'No',
            }
        }
    },
    customer: {
        overview: {
            createButton: 'New customer',
        },
        edit: {
            title: 'Edit customer',
            basicInfoTitle: 'Basic info',
            invoiceLocationTitle: 'Invoicing address',
            visitingLocationTitle: 'Visiting address',
            contractsTitle: 'Contracts',
            contactsTitle: 'Contact persons',
            updateMailTitle: 'Update email',
            copyAddress: 'Copy address',
        },
        create: {
            title: 'Add customer',
        },
        field: {
            name: {
                label: 'Name',
            },
            lisIntegration: {
                label: 'LIS Integration',
            },
            ecsIntegration: {
                label: 'ECS Integration',
            },
            isDfdsIntegrationEnabled: {
                label: 'Enable DFDS Integration',
                description: 'When enabled, the DFDS integration will include all allocations from this customer to match instructions send with the allocation customer code.',
            },
            isKtaIntegrationEnabled: {
                label: 'Enable KTA Integration',
                description: 'When enabled, the KTA integration will include all allocations from this customer to match instructions send with the allocation customer code.',
            },
            autoFinalize: {
                label: 'Enable Auto Finalization',
                description: 'When enabled, auto finalization is working for this customer.',
            },
            externalDebtorCreditorNumber: {
                label: 'LIS debtor/creditor code',
            },
            phone: {
                label: 'Phone number',
                shortLabel: 'Phone',
            },
            email: {
                label: 'Email',
            },
            emailFromName: {
                label: 'Customer update "from" name',
            },
            emailFromAddress: {
                label: 'Customer update "from" e-mail address',
            },
            emailFromNameDocumentsInvoices: {
                label: 'Documents & Invoices "from" name',
            },
            emailFromAddressDocumentsInvoices: {
                label: 'Documents & Invoices "from" e-mail address',
            },
            iban: {
                label: 'IBAN',
            },
            chamberOfCommerce: {
                label: 'Chamber of Commerce',
            },
            exactOnlineCode: {
                label: 'Exact Online ID',
            },
            vatCode: {
                label: 'VAT code',
            },
            accountManager: {
                label: 'Account manager',
            },
            showDeactive: {
                label: 'Show deactive?',
            },
            paymentTerm: {
                label: 'Payment term (in days)',
            },
            amountToBeInvoiced: {
                label: 'Amount to be invoiced',
            },
            amountDue: {
                label: 'Amount due',
            },
            outstandingAmount: {
                label: 'Outstanding amount',
            },
            creditLimitAmount: {
                label: 'Credit limit',
            },
            creditLimitExpiryDate: {
                label: 'Expiry date',
            },
            creditRisk: {
                label: 'Credit risk',
            },
            canCreateTrailer: {
                label: 'Can create trailer',
                yes: 'Yes',
                no: 'No',
                noDescription: 'When "No" is selected, dispatchers are not allowed to create trailers but have to select a trailer from the predefined list. Tracked equipement on trailers are also kept even after trailer changes.',
                yesDescription: 'When "Yes" is selected, dispatchers are allowed to create trailers on the fly. Tracked equipement on trailers are dropped on trailer changes.',
            },
            useCustomerId: {
                label: 'Use customer id in the update e-mails',
                yes: 'Yes',
                no: 'No',
            },
            isCmrRequestedForLoad: {
                label: "Requested CMR for load activity",
                yes: "Yes",
                no: "No",
            },
            isCmrRequestedForTrailerDrop: {
                label: "Requested CMR for dropping trailer activity",
                yes: "Yes",
                no: "No",
            },
            updateInterval: {
                label: 'Update interval in minutes',
            },
            openingHoursFrom: {
                label: 'Open From',
            },
            openingHoursTill: {
                label: 'Open Till',
            },
            openingHours: {
                shortLabel: 'Opening hours',
            },
            afterOfficeHours: {
                label: 'After office hours',
                options: {
                    email: 'Email',
                    'only call': 'Only Call',
                }
            },
            orderInstructions: {
                label: 'Instructions / how to read order',
                shortLabel: 'Instructions',
            },
            activitySummary: {
                label: 'Summary of activities',
                shortLabel: 'Activities',
            },
            updateMailToAddress: {
                label: 'Email',
            },
            emailTo: {
                label: 'Email to',
            },
            lastUpdateSentAt: {
                label: 'Last mail update sent',
            },
            updateMailTemplate: {
                label: 'Template',
                dropText: 'Click or drag a file to upload a template (.xlsx)',
                downloadExisting: 'Download existing file',
                showVariables: 'Show template variables',
            },
        },
    },
    fleetvisorForm: {
        create: {
            title: 'Create Fleetvisor Form',
        },
        edit: {
            title: 'Edit Fleetvisor Form',
        },
        overview: {
            createButton: 'New form',
        },
        field: {
            id: {
                label: 'ID',
            },
            formType: {
                label: 'Form type',
            },
            activityType: {
                label: 'Activity type',
            },
            activityKind: {
                label: 'Activity kind',
            },
            formId: {
                label: 'Form id',
            },
            formVersion: {
                label: 'Form version',
            },
        },
    },
    transicsForm: {
        create: {
            title: 'Create Transics Form',
        },
        edit: {
            title: 'Edit Transics Form',
        },
        overview: {
            createButton: 'New form',
        },
        field: {
            id: {
                label: 'ID',
            },
            activityType: {
                label: 'Activity type',
            },
            activityKind: {
                label: 'Activity kind',
            },
            formId: {
                label: 'Form id',
            },
        },
    },
    activities: {
        activityExportYearly: {
            title: 'Download annual report',
        },
        activityExport: {
            title: 'Export',
        },
        activityExportAll: {
            title: 'Export with stops',
        },
        field: {
            id: {
                label: 'ID',
            },
            instructions: {
                label: 'Instructions',
            },
        },
    },
    contact: {
        createButton: 'Add contact',
        noName: 'Nameless contact',
        field: {
            name: {
                label: 'Name',
            },
            types: {
                label: 'Type',
            },
            // Below is a typo...
            type: {
                label: 'Type',
                options: {
                    dayshift: 'Dayshift',
                    nightshift: 'Nightshift',
                    invoice: 'Invoice',
                    operation: 'Operation',
                    administration: 'Administration',
                    general: 'General',
                    dispute: 'Disputes',
                },
            },
            landline: {
                label: 'Landline',
            },
            mobile: {
                label: 'Mobile',
            },
            email: {
                label: 'Email',
            },
            allocations: {
                label: 'Customer IDs',
            },
            contracts: {
                label: 'Contracts',
            },
        },
    },
    contract: {
        emailTemplateSubject: 'Subject',
        emailTemplateContent: 'Content',
        createButton: 'Add contract',
        noName: 'Nameless contract',
        needExistingContract: 'You have to save this contract before adding tariffs',
        create: {
            title: 'Create',
        },
        edit: {
            title: '{{customer}} ≫ Edit contract',
            basicInfoTitle: 'Basic info',
            tariffTitle: 'Tariff',
            routeOptimizationSettings: 'PTV route optimization levels',
            invoiceSettings: 'Invoice settings',
            createTariffButton: 'Add tariff',
        },
        field: {
            name: {
                label: 'Name',
            },
            documents: {
                label: 'Documents',
            },
            fcCode: {
                label: 'Currency',
            },
            trackedEquipment: {
                label: 'Tracked Equipment',
            },
            trackedTrailerEquipment: {
                label: 'Tracked Trailer Equipment',
            },
            trackedAllocationEquipment: {
                label: 'Tracked Allocation Equipment',
            },
            defaultInvoiceRemarks: {
                label: 'Default invoice remarks',
            },
            emailFromName: {
                label: 'Customer update "from" name',
            },
            emailFromAddress: {
                label: 'Customer update "from" address (leave empty to use address on customer)',
            },
            invoiceEmailSubject: {
                label: 'Invoice e-mail subject',
            },
            invoiceEmailContent: {
                label: 'Invoice e-mail content',
            },
            draftInvoiceEmailSubject: {
                label: 'Draft invoice e-mail subject',
            },
            draftInvoiceEmailContent: {
                label: 'Draft invoice e-mail content',
            },
            proposedRouteRejectEmailSubject: {
                label: 'Proposed Route e-mail subject',
            },
            proposedRouteRejectEmailContent: {
                label: 'Proposed Route e-mail content',
            },
            activityFinalizeEmailSubject: {
                label: 'Activitiy Finalize send documents e-mail subject',
            },
            activityFinalizeEmailContent: {
                label: 'Activitiy Finalize send documents e-mail content',
            },
            showCalculationMatrix: {
                label: 'Include calculation matrix',
            },
            shortestRouteOptimizationLevel: {
                label: 'PTV shortest route optimization level',
                shortLabel: 'Shortest',
            },
            normalRouteOptimizationLevel: {
                label: 'PTV normal route optimization level',
                shortLabel: 'Normal',
            },
            fastestRouteOptimizationLevel: {
                label: 'PTV fastest route optimization level',
                shortLabel: 'Fastest',
            },
            startDate: {
                label: 'Start date',
            },
            endDate: {
                label: 'End date',
            },
            entity: {
                label: 'Entity',
            },
            entityFee: {
                label: 'Entity Fee',
            },
            allocations: {
                label: 'Customer IDs',
            },
            orderReferenceValidations: {
                label: 'Order reference validations',
            },
            allowedKmPerDay: {
                label: 'Target kilometers per day',
            },
            disputeToleranceMaxAmount: {
                label: 'Dispute tolerance maximum amount',
            },
            disputeToleranceMaxPercentage: {
                label: 'Dispute tolerance maximum percentage',
            },
            attachDocuments: {
                label: 'Attach documents in e-mail to customer',
                emptyText: 'No documents will be attached.',
            },
            includeDetails: {
                label: 'Invoice specifications',
                options: {
                    true: 'Show details',
                    false: 'Only summary',
                },
            },
            useTemplate: {
                label: 'Use invoice template',
                options: {
                    true: 'Yes',
                    false: 'No',
                },
            },
            includeSpecification: {
                label: 'Include Activity specifications',
                options: {
                    true: 'Yes',
                    false: 'No',
                },
            },
            invoiceMomentWhen: {
                label: 'Invoice moment',
                options: {
                    'after finalize': 'After finalize',
                    'weekly': 'Weekly',
                    '2-weekly': '2-Weekly',
                    '4-weekly': '4-Weekly',
                    'monthly': 'Monthly',
                },
            },
            documentsMomentWhen: {
                label: 'Send documents',
                options: {
                    'after finalize': 'After finalize',
                    'dont send': 'Dont send',
                    'with invoice': 'With invoice',
                },
            },
            invoiceMomentDayOfWeek: {
                label: 'Day of week',
                options: {
                    1: 'Monday',
                    2: 'Tuesday',
                    3: 'Wednesday',
                    4: 'Thursday',
                    5: 'Friday',
                    6: 'Saturday',
                    7: 'Sunday',
                },
            },
            invoiceMomentDayOfMonth: {
                label: 'Day of month',
            },
            invoiceMomentUpToAndIncluding: {
                label: 'Invoice up to and including',
                options: {
                    'same day': 'Same day',
                    'previous day': 'Previous day',
                    'previous week': 'Previous week',
                    'previous month': 'Previous month',
                },
            },
            orderReferenceRegex: {
                label: 'Order Reference Validator (regex)',
            },
            doubleTeam: {
                label: 'Double team',
                options: {
                    'true': 'Double team',
                    'false': 'Single driver',
                },
            },
            customer: {
                label: 'Customer',
            },
            noPlannedEmailSubject: {
                label: 'No Planned Email Subject',
            },
            noPlannedEmailContent: {
                label: 'No Planned Email Content',
            },
            activityActivityLoadFinishedEmailSubject: {
                label: 'Activity Load Finished Email Subject',
            },
            activityActivityLoadFinishedEmailContent: {
                label: 'Activity Load Finished Email Content',
            },
            activityActivityUnloadFinishedEmailSubject: {
                label: 'Activity Load Finished Email Subject',
            },
            activityActivityUnloadFinishedEmailContent: {
                label: 'Activity Load Finished Email Content',
            },
            activityActivityTrailerChangeFinishedEmailSubject: {
                label: 'Activity Load Finished Email Subject',
            },
            activityActivityTrailerChangeFinishedEmailContent: {
                label: 'Activity Load Finished Email Content',
            },
            useEquipmentModule: {
                label: 'Use Equipment module on TARA',
                description: 'When enabled, driver will be allowed to enter equipment values in TARA',
            },
            pendingDisputesEmailSubject: {
                label: 'Disputes to customer e-mail subject',
            },
            pendingDisputesEmailContent: {
                label: 'Disputes to customer e-mail content',
            },
            showDeactivated: {
                label: 'Show deactivated?',
            }
        },
        // We store options in specific boolean fields, but the interface groups
        // them in a radio button.
        fakeType: {
            options: {
                'dont send': "Don't send",
                'email': 'Send by email',
                'post': 'Send by post',
            },
        },
    },
    formQuestionText: {
        field: {
            multiLine: {
                label: 'Allow multiple lines'
            },
            autoFill: {
                label: 'Auto fill'
            },
        },
    },
    formQuestionInteger: {
        field: {
            minValue: {
                label: 'Min'
            },
            maxValue: {
                label: 'Max'
            },
            autoFill: {
                label: 'Auto fill'
            },
        },
    },
    contractOrderReferenceValidation: {
        createButton: 'Add validation rule',
        type: {
            length: 'length',
            prefix: 'prefix',
            suffix: 'suffix',
        },
        field: {
            type: {
                label: 'What to validate',
            },
            content: {
                label: 'Value',
            },
            length: {
                label: 'Length',
            },
        }
    },
    leaseCompany: {
        overview: {
            title: 'Lease companies',
            createButton: 'New company',
        },
        edit: {
            title: 'Edit lease company',
        },
        create: {
            title: 'Add lease company',
        },
        field: {
            name: {
                label: 'Company name',
            },
        },
    },
    supplier: {
        overview: {
            createButton: 'New supplier',
        },
        edit: {
            title: 'Edit supplier',
            contactsTitle: 'Contact persons',
        },
        create: {
            title: 'Add supplier',
        },
        field: {
            type: {
                label: 'Type',
                value: {
                    'lease company': 'Lease Company',
                    'insurance company': 'Insurance Company',
                    'insurance broker': 'Insurance Broker',
                    'service company': 'Service Company',
                },
            },
            name: {
                label: 'Name',
            },
            vatCode: {
                label: 'vatCode',
            },
        },
    },
    bepTicketSubcategory: {
        overview: {
            createButton: 'New BEP Ticket subcategory',
        },
        edit: {
            title: 'Edit BEP Ticket subcategory',
        },
        create: {
            title: 'Add BEP Ticket subcategory',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            slug: {
                label: 'URL slug',
            },
            createdAt: {
                label: 'Created at',
            },
            updatedAt: {
                label: 'Updated at',
            },
        },
    },
    truckBrand: {
        overview: {
            createButton: 'New model',
        },
        edit: {
            title: 'Edit truck model',
        },
        create: {
            title: 'Add truck model',
        },
        field: {
            manufacturer: {
                label: 'Manufacturer',
            },
            brand: {
                label: 'Model',
            },
        },
    },
    truckLicense: {
        noName: 'Empty license',
        createButton: 'Add license',
        field: {
            license: {
                label: 'Type',
            },
            dateObtained: {
                label: 'Obtained at',
            },
            dateOfExpiry: {
                label: 'Expires at',
            },
            documentNumber: {
                label: 'Document number / Description',
            },
            valid: {
                label: 'Valid',
            },
            issuingCountries: {
                label: 'Valid in',
            },
            emptyString:{
                label: "s"
            },
            documentScan: {
                label: 'Truck license: ',
                labelSecondary: 'ATTACHMENT',
                dropText: 'Click or drag a file to upload a truck license',
                downloadExisting: 'Download existing file',
                showVariables: 'Show scan variables',
            },
        },
    },
    truckTollbox: {
        noName: 'Empty tollbox',
        createButton: 'Add tollbox',
        field: {
            countries: {
                label: 'Countries',
            },
            supplier: {
                label: 'Supplier',
            },
            serialNumber: {
                label: 'Serial number',
            },
            validStartDate: {
                label: 'Start date',
            },
            validEndDate: {
                label: 'End date',
            },
            documentScan: {
                label: 'Tollbox contract',
            },
        },
    },
    location: {
        field: {
            address: {
                label: 'Address',
                placeholder:
                    'Address example: street, city, country \nGPS coordinates example: 52.308594, 17.056734',
            },
            street: {
                label: 'Street',
            },
            houseNumber: {
                label: 'House number',
            },
            zipCode: {
                label: 'Zip code',
            },
            city: {
                label: 'City',
            },
            country: {
                label: 'Country',
            },
            region: {
                label: 'Region'
            },
        },
    },
    message: {
        sendBulkMessageModal: {
            title: 'Send bulk message to {{count}} trucks',
        },
        sendMessageButton: 'Send bulk message',
        submitButton: 'Send',
        routeMessagePreText: 'Sent route to driver:',
        routeMessagePreTextWithoutRoute: 'Sent activity to driver:',
        routeMessagePreTextSendDocument: 'Requested a new document:',
        routeMessagePreTextNewScan: 'STATUS UPDATE - NEW SCAN',
        sendRouteTooltip: 'Send route to driver',
        routeAlreadySentTooltip: 'Activity already sent at {{sentAt}}',
        markFormAsReadTooltip: 'Ignore driver update',
        formMessageText: 'STATUS UPDATE - {{status}}',
        resendWithoutRoute: {
            title: 'Could not send route',
            content:
                'Fleetvisor is giving us some problems... Do you want to send the activity without route?',
        },
        routeAlreadySentWarning:
            'This route was already sent to the driver. Do you really want to send it again?',
        field: {
            id: {
                label: 'ID',
            },
            type: {
                label: 'Type',
            },
            activity: {
                label: 'Activity',
            },
            source: {
                label: 'Source',
            },
            text: {
                label: 'Message',
                placeholder: 'Enter a message…',
            },
            sendAfter: {
                label: 'Send after',
            },
            writtenAt: {
                label: 'Sent at',
            },
            seenAt: {
                label: 'Seen at',
            },
            seenByDriverAt: {
                label: 'Seen at',
            },
            receivedAt: {
                label: 'Received at',
            },
        },
    },
    entity: {
        overview: {
            createButton: 'New entity',
        },
        create: {
            title: 'Create Entity',
        },
        edit: {
            title: 'Edit Entity',
            basicInfoTitle: 'Basic info',
            vatRuleTitle: 'VAT rules',
            addVatRuleButton: 'Add VAT rule',
            contactsTitle: 'Contact persons',
        },
        field: {
            name: {
                label: 'Name',
            },
            chamberOfCommerce: {
                label: 'Chamber of Commerce',
            },
            vatCode: {
                label: 'VAT code',
            },
            iban: {
                label: 'IBAN',
            },
            users: {
                label: 'Users',
            },
            cashManagers: {
                label: 'Cash managers',
            },
            invoicePdfTemplate: {
                label: 'PDF template',
                options: {
                    nl: 'NL',
                    de: 'DE Zweigniederlassung',
                    degmbh: 'DE Gmbh'
                },
            },
            exactAccountCodeProd: {
                label: 'Exact code prod',
            },
            exactAccountCodeTest: {
                label: 'Exact code test',
            },
            address: {
                label: 'Address',
            },
            invoiceEmailContent: {
                label: 'Invoice e-mail content',
            },
            invoiceEmailSubject: {
                label: 'Invoice e-mail subject',
            },
            isDefaultForCostInvoice: {
                label: 'Default for Cost Invoice?',
            },
        },
    },
    incident: {
        edit: {
            title: 'Incident {{id}} - {{status}}',
            toggleSystemHistory: 'Show system history',
            claims: {
                header: 'Claims',
                empty: 'No claims yet...',
            },
            followups: {
                header: 'Followups',
                empty: 'No followups yet...',
            },
            history: {
                header: 'History',
                empty: 'No history yet...',
            },
            documents: {
                header: 'Documents',
                empty: 'No history yet...',
            },
            overview: 'Show incident',
        },
        create: {
            title: 'Add Incident',
        },
        field: {
            id: {
                label: 'ID',
            },
            type: {
                label: 'Type',
                value: {
                    traffic: 'Traffic',
                    maneuvering: 'Maneuvering',
                    causedBy3rdParty: 'Caused by 3rd Party',
                    theft: 'Theft',
                    cargo: 'Cargo',
                },
            },
            entity: {
                label: 'Entity',
            },
            status: {
                label: 'Status',
                value: {
                    noClaim: 'No claim',
                    toInformInsurance: 'To inform insurance',
                    collectingDocuments: 'Collecting documents',
                    waitingForEstimate: 'Waiting for estimate',
                    repairingWaitingForInvoice: 'Repairing / Waiting for invoice',
                    finishedWaitingForPayment: 'Finished / Waiting for payment',
                    finalized: 'Finalized',
                    '': 'Empty Status',
                },
            },
            reference: {
                label: 'Reference',
            },
            description: {
                label: 'Description',
            },
            incidentDatetime: {
                label: 'Date',
            },
            cost: {
                label: 'Cost',
            },
            ownRisk: {
                label: 'Own Risk',
            },
            net: {
                label: 'Net',
            },
            penalty: {
                label: 'Penalty',
            },
            activity: {
                label: 'Activity',
            },
            needsFollowup: {
                label: 'Needs follow-up',
            },
            followupAction: {
                label: 'Follow-up action',
            },
            followupActionDate: {
                label: 'Follow-up date',
            },
            followupActionTaker: {
                label: 'Follow-up taker',
            },
            hasSurveyor: {
                label: 'Has Surveyor',
            },
            surveyorName: {
                label: 'Surveyor Name',
            },
            surveyorPhoneNumber: {
                label: 'Phone number',
            },
            surveyorDate: {
                label: 'Surveyor Date',
            },
            createdBy: {
                label: 'Created By',
            },
            createdAt: {
                label: 'Created At',
            },
            updatedAt: {
                label: 'Last Changed At',
            },
            updatedBy: {
                label: 'Last Changed By',
            },
        }
    },
    incidentDocument: {
        field: {
            id: {
                label: 'ID',
            },
            file: {
                label: 'File',
            },
            type: {
                label: 'Type',
                value: {
                    damageForm: 'Damage Form',
                    policeReport: 'Police Report',
                    evidence: 'Evidence',
                    claimLetter: 'Claim Letter',
                    invoice: 'Invoice',
                    costApproval: 'Cost Approval',
                },
            },
        }
    },
    incidentFollowup: {
        field: {
            id: {
                label: 'ID',
            },
            performedAt: {
                label: 'Performed At',
            },
            performedBy: {
                label: 'Performed By',
            },
            description: {
                label: 'Description',
            },
        }
    },
    incidentComment: {
        addCommentLabel: 'Add comment',
    },
    claim: {
        field: {
            id: {
                label: 'ID',
            },
            type: {
                label: 'Type',
                value: {
                    truck: 'Truck',
                    trailer: 'Trailer',
                    cargo: 'Cargo',
                    thirdParty: '3rd Party',
                },
            },
            claimDate: {
                label: 'Date',
            },
            reference: {
                label: 'Reference Insurance',
                thirdPartyLabel: 'Reference Third Party',
            },
            instance: {
                label: 'Instance',
            },
            cost: {
                label: 'Cost',
            },
            ownRisk: {
                label: 'Own Risk',
            },
            net: {
                label: 'Net',
            },
            paymentDate: {
                label: 'Payment Date',
            },
        }
    },
    claimDocument: {
        field: {
            id: {
                label: 'ID',
            },
            type: {
                label: 'Type',
                value: {
                    damageForm: 'Damage Form',
                    policeReport: 'Police Report',
                    evidence: 'Evidence',
                    claimLetter: 'Claim Letter',
                    invoice: 'Invoice',
                    costApproval: 'Cost Approval',
                },
            },
            dropText: "Click or drag an image to upload as evidence.",
            multipleDropText: "Click or drag multiple images to upload as evidences.",
        },
    },
    verificationForm: {
        overview: {
            createButton: 'New verification form',
        },
        field: {
            id: {
                label: 'ID',
            },
            truckType: {
                label: 'Truck Type',
            },
            description: {
                label: 'Description',
            },
        }
    },
    formQuestion: {
        field: {
            id: {
                label: 'ID',
            },
            body: {
                label: 'Question',
            },
        }
    },
    formQuestionFile: {
        field: {
            id: {
                label: 'ID',
            },
            minCount: {
                label: 'Minimum amount',
            },
            maxCount: {
                label: 'Maximum amount',
            },
        }
    },
    formQuestionChoiceOption: {
        field: {
            id: {
                label: 'ID',
            },
            body: {
                label: 'Option',
            },
        }
    },
    formQuestionChoice: {
        field: {
            id: {
                label: 'ID',
            },
            allowMultiple: {
                label: 'Allow multiple',
            },
        }
    },
    verificationFormVersion: {
        addQuestionTextButton: 'Text',
        addQuestionIntegerButton: 'Number',
        addQuestionFileButton: 'File',
        addQuestionChoiceButton: 'Choice',
        addQuestionDateButton: 'Date',
        saveFinalizeButton: 'Save & Finalize',
        field: {
            id: {
                label: 'ID',
            },
            verificationForm: {
                label: 'Form',
            },
        }
    },
    vatRule: {
        noName: 'Nameless VAT rule',
        header: 'Normal Transport',
        headerOutsideEu: 'Transport outside EU',
        field: {
            name: {
                label: 'Name',
            },
            factor: {
                label: 'Factor',
            },
            default: {
                label: 'Default',
            },
            exactCode: {
                label: 'Exact code',
            },
        },
    },
    costInvoice: {
        uploadCsvButton: 'Upload CSV',
        reprocessCsvButton: 'Reprocess CSV',
        downloadCsvButton: 'Download CSV',
        edit: {
            title: 'Edit Cost Invoice',
            saveAndFinalizeButton: 'Save & Finalize',
            subtotalCosts: 'Subtotal: {{subtotal}}',
            confirmModalFcCode: {
                title: 'Are you sure you want to continue?',
                content: 'Changing the "FC Code" in the invoice header will not change the "FC Code" in the invoice items.',
            },
            confirmModalEntity: {
                title: 'Are you sure you want to continue?',
                content: 'Changing the "Entity" in the invoice header will not change the "Entity" in the invoice items.',
            },
            confirmModalCostSupplier: {
                title: 'Are you sure you want to continue?',
                content: 'Changing the "Cost Supplier" in the invoice header will not change the "Cost Supplier" in the invoice items.',
            },
        },
        create: {
            title: 'Add Cost Invoice',
        },
        field: {
            transactionDatetime: {
                label: 'Invoice date',
            },
            id: {
                label: 'ID',
            },
            costSupplier: {
                label: 'Cost Supplier',
            },
            entity: {
                label: 'Entity',
            },
            invoiceNumber: {
                label: 'Invoice Number',
            },
            invoiceSummary: {
                label: 'Invoice Summary',
            },
            invoiceDate: {
                label: 'Invoice Date',
            },
            invoiceDue: {
                label: 'Invoice Due',
            },
            netAmount: {
                label: 'Net Amount',
            },
            grossAmount: {
                label: 'Gross Amount',
            },
            vatAmount: {
                label: 'VAT Amount',
            },
            fcCode: {
                label: 'FC Code',
            },
            finalized: {
                label: 'Finalized?'
            },
            vatNumberSearch: {
                label: 'Search by VAT number',
            },
            finalizedAt: {
                label: 'Finalized At',
            },
            paidBy: {
                company: 'Company',
                driver: 'Driver',
                label: 'Paid by'
            },
        },
    },
    iconModal: {
        header: 'Select icon',
        filterPlaceholder: 'Search...',
        category: {
            accessibility: 'Accessibility',
            arrows: 'Arrows',
            audioVideo: 'Audio/Video',
            business: 'Business',
            chess: 'Chess',
            code: 'Code',
            communication: 'Communication',
            computers: 'Computers',
            currency: 'Currency',
            dateTime: 'Date/Time',
            design: 'Design',
            editors: 'Editing',
            files: 'Files',
            genders: 'Genders',
            handsGestures: 'Hand signatures',
            health: 'Health',
            images: 'Pictures',
            interfaces: 'Interfaces',
            logistics: 'Logistics',
            maps: 'Cards',
            medical: 'Medical',
            objects: 'Objects',
            paymentsShopping: 'Shopping',
            shapes: 'Shapes',
            sports: 'Sport',
            status: 'Status',
            usersPeople: 'People',
            vehicles: 'Vehicles',
writing: 'Writing',
        },
      },
    costProductSupplierCode: {
        title: 'Supplier products',
        editModal: {
            header: 'Edit Supplier Code',
        },
        field: {
            id: {
                label: 'ID',
            },
            costProduct: {
                label: 'Product',
            },
            costSupplier: {
                label: 'Supplier',
            },
            supplierCode: {
                label: 'Supplier Code',
            },
            costType: {
                label: 'Cost type',
                fuel: 'Fuel cost',
                road: 'Road cost',
            },
        },
    },
    ledgerVat: {
        edit: {
            title: 'Edit Ledger VAT',
        },
        create: {
            title: 'Add Ledger VAT',
        },
        field: {
            id: {
                label: 'ID',
            },
            entity: {
                label: 'Entity',
            },
            ledgerCode: {
                label: 'Code',
            },
            country: {
                label: 'Country',
            },
        },
    },
    ledgerCost: {
        edit: {
            title: 'Edit Ledger Cost',
        },
        create: {
            title: 'Add Ledger Cost',
        },
        field: {
            id: {
                label: 'ID',
            },
            entity: {
                label: 'Entity',
            },
            ledgerCodeAccounting: {
                label: 'Tax Code Accounting',
            },
            currentCodeReport: {
                label: 'Code Reporting'
            },
            assetTruckCategory: {
                label: 'Truck Category Reporting',
            },
            costProduct: {
                label: 'Cost Product'
            },
            nonTaxLedgerCodeAccounting: {
                label: 'non-Tax Code Accounting',
            }
        },
    },
    costSupplier: {
        edit: {
            title: 'Edit Cost Supplier',
        },
        create: {
            title: 'Add Cost Supplier',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Name',
            },
            vatNumber: {
                label: 'VAT number',
            },
            paidBy: {
                company: 'Company',
                driver: 'Driver',
                label: 'Paid by'
            },
            country: {
                label: 'Country',
            },
            costType: {
                label: 'Cost type',
                fuelCost: 'Fuel cost',
                roadCost: 'Road cost',
            },
        },
    },
    costProduct: {
        edit: {
            title: 'Edit Cost Product',
        },
        create: {
            title: 'Add Cost Product',
        },
        field: {
            id: {
                label: 'ID',
            },
            description: {
                label: 'Description',
            },
            suppliers: {
                label: 'Suppliers',
            },
            assignActivity:{
                label: 'Assign to activity',
                yes: 'Yes',
                no: 'No',
            },
            costType: {
                label: 'Cost type',
                fuel: 'Fuel cost',
                road: 'Raod cost',
            },
        },
    },
    costImportWarning: {
        deleteAllWarningButton: 'Delete all warnings',
        field: {
            id: {
                label: 'ID',
            },
            lineNumber: {
                label: 'Line',
            },
            field: {
                label: 'Field',
            },
            message: {
                label: 'Message',
            },
        },
    },
    costImport: {
        progress: 'Progress',
        field: {
            id: {
                label: 'ID',
            },
        },
    },
    fuelCost: {
        title: 'Fuel Costs',
        edit: {
            title: 'Edit Fuel Cost',
        },
        previousId: 'Previous transaction id: {{id}}.',
        create: {
            title: 'Add Fuel Cost',
            saveAndNewButton: 'Save & Add new',
            saveButton: 'Save',
            ledgerCostWarning: 'Could not find ledger cost for \nthis entity, truck and product combination.',
            locationWarning: 'Could not find truck location.',
        },
        modalChangeProductFor: {
            header: 'Change product for fuel cost',
            parseButton: 'Parse provided Transaction IDs',
            listHeader: "Transaction Id's",
            helpText: 'Paste a list from Excel \nor type IDs with "space" separator',
        },
        field: {
            id: {
                label: 'ID',
            },
            entity: {
                label: 'Entity',
            },
            costSupplier: {
                label: 'Cost Supplier',
            },
            transactionId: {
                label: 'Transaction ID',
            },
            transactionDatetime: {
                label: 'Transaction At',
                filter: 'Transaction date',
            },
            originalProductDescription: {
                label: 'Product Description',
            },
            costProduct: {
                label: 'Cost Product',
            },
            originalCardNumber: {
                label: 'Card Number',
            },
            originalDriverCode: {
                label: 'Driver Code',
            },
            driver: {
                label: 'Driver',
            },
            originalTruckLicensePlate: {
                label: 'Truck License Plate',
            },
            truck: {
                label: 'Truck',
            },
            ledgerCost: {
                label: 'Ledger Cost',
            },
            ledgerVat: {
                label: 'Ledger VAT',
            },
            originalTrailerLicensePlate: {
                label: 'Trailer License Plate',
            },
            trailer: {
                label: 'Trailer',
            },
            originalAddress: {
                label: 'Address',
            },
            originalCountryCode: {
                label: 'Country Code',
            },
            odometer: {
                label: 'Odometer',
            },
            quantity: {
                label: 'Quantity',
            },
            unitPrice: {
                label: 'Unit Price',
            },
            listPrice: {
                label: 'List Price',
            },
            netOc: {
                label: 'Net in EUR',
            },
            grossOc: {
                label: 'Gross in EUR',
            },
            vatOc: {
                label: 'VAT in EUR',
            },
            netFc: {
                label: 'Net FC',
            },
            grossFc: {
                label: 'Gross FC',
            },
            vatFc: {
                label: 'Vat FC',
            },
            vatPercentage: {
                label: 'VAT Percentage',
            },
            fcCode: {
                label: 'FC Code',
            },
            status: {
                label: 'Status'
            },
            paidBy: {
                company: 'Company',
                driver: 'Driver',
                label: 'Paid by'
            },
            productFor: {
                trailer: 'Trailer',
                truck: 'Truck',
                label: 'Product for'
            },
            haveReceipt: {
                label: 'Do you have receipt?',
            },
            modalButton: {
                label: 'Bulk Action',
            },
        },
    },
    roadCost: {
        title: 'Road Costs',
        edit: {
            title: 'Edit Road Cost',
        },
        previousId: 'Previous transaction id: {{id}}.',
        create: {
            title: 'Add Road Cost',
            saveAndNewButton: 'Save & Add new',
            saveButton: 'Save',
            ledgerCostWarning: 'Could not find ledger cost for \nthis entity, truck and product combination.',
            locationWarning: 'Could not find truck location.',
        },
        field: {
            id: {
                label: 'ID',
            },
            entity: {
                label: 'Entity',
            },
            costSupplier: {
                label: 'Cost Supplier',
            },
            transactionId: {
                label: 'Transaction ID',
            },
            transactionDatetime: {
                label: 'Transaction At',
                filter: 'Transaction date',
            },
            originalProductDescription: {
                label: 'Product Description',
                placeholder: 'Only required if product is Other',
            },
            costProduct: {
                label: 'Cost Product',
            },
            originalCardNumber: {
                label: 'Card Number',
            },
            originalDriverCode: {
                label: 'Driver Code',
            },
            driver: {
                label: 'Driver',
            },
            originalTruckLicensePlate: {
                label: 'Truck License Plate',
            },
            truck: {
                label: 'Truck',
            },
            ledgerCost: {
                label: 'Ledger Cost',
            },
            ledgerVat: {
                label: 'Ledger VAT',
            },
            originalTrailerLicensePlate: {
                label: 'Trailer License Plate',
            },
            trailer: {
                label: 'Trailer',
            },
            originalAddress: {
                label: 'Address',
            },
            originalCountryCode: {
                label: 'Country Code',
            },
            odometer: {
                label: 'Odometer',
            },
            unassignable: {
                label: 'Unassignable?',
            },
            quantity: {
                label: 'Quantity',
            },
            unitPrice: {
                label: 'Unit Price',
            },
            listPrice: {
                label: 'List Price',
            },
            netOc: {
                label: 'Net in EUR',
            },
            grossOc: {
                label: 'Gross in EUR',
            },
            vatOc: {
                label: 'VAT in EUR',
            },
            netFc: {
                label: 'Net FC',
            },
            grossFc: {
                label: 'Gross FC',
            },
            vatFc: {
                label: 'Vat FC',
            },
            vatPercentage: {
                label: 'VAT Percentage',
            },
            fcCode: {
                label: 'FC Code',
            },
            transactions: {
                label: 'Transactions'
            },
            status: {
                label: 'Status'
            },
            paidBy: {
                company: 'Company',
                driver: 'Driver',
                label: 'Paid by'
            },
            haveReceipt: {
                label: 'Do you have receipt?',
            },
        },
    },
    user: {
        overview: {
            fullName: 'Name',
            createButton: 'New user',
            activeateAllUserSSO: 'Activate all users SSO',
            deactiveateAllUserSSO: 'Deactivate all users SSO',
        },
        role: {
            superuser: 'Super Administrator',
            customer: 'Customer',
            dispatcher: 'Dispatcher',
            'operations manager': 'Dispatch Teamleader',
            'asset planner': 'Asset Planner',
            'customer service employee': 'Customer Service Employee',
            'customer service manager': 'Customer Service Teamleader',
            'human asset manager': 'HR',
            'human asset admin': 'HR Administrator',
            'technical asset manager': 'Technical Assets',
            'claim viewer': 'Claim Viewer',
            quality: 'Quality',
            manager: 'Manager',
            'truck checker': 'Truck Checker',
            admin: 'Administrator',
            'financial': 'Finance',
            'driver care manager': 'Driver Care Manager',
            'dcm': 'DCM',
            'driver recruiter': 'Driver Recruiter',
            driver: 'Driver',
            empty: 'No role',
            claims: 'Claims',
            'driver care administrator': 'Driver Care Administrator',
            'payroll administrator': 'Payroll Administrator',
            'customer administration': 'Customer Administration',
            'operations disputes': 'Operations Disputes',
        },
        edit: {
            title: 'Edit user',
        },
        create: {
            title: 'Add user',
        },
        field: {
            id: {
                label: 'Id',
            },
            username: {
                label: 'Email',
            },
            email: {
                label: 'Email',
            },
            firstName: {
                label: 'First name',
            },
            lastName: {
                label: 'Last name',
            },
            fullName: {
                label: 'Full name',
            },
            lastActive: {
                label: 'Last Active'
            },
            password: {
                label: 'Password',
            },
            groups: {
                label: 'Roles',
            },
            entities: {
                label: 'Entities',
            },
            customers: {
                label: 'Customers',
            },
            roles: {
                label: 'Roles',
            },
            emailSignature: {
                label: 'e-mail signature',
            },
            internalPhoneNumber: {
                label: 'Internal phone number',
            },
            deletedUsers: {
                filter: 'Show deleted?'
            },
            activateSsoLogin: {
                label: 'SSO Login'
            },
        },
        login: {
            title: 'Login',
            reloginMessage: 'You are logged out. Please login again.',
            forgotPasswordLink: 'Forgot password?',
            errors: {
                invalidCredentials: 'Username or password do not match',
                unknown: 'Unknown error, status code: {{status}}',
            },
            loginButton: 'Log in',
            loginButtonSSO: 'SSO Log in',
        },
        passwordForgot: {
            title: 'Forgot password',
            requestButton: 'Send email',
            requestedEmailText:
                'An email with a reset code has been sent to this email address.',
            loggedInError: 'You are logged in. Password forgot only works when you are not logged in.',
        },
        resetPassword: {
            title: 'Reset password',
            resetButton: 'Reset',
            saveSuccess: 'Password is successfully reset.',
            loggedInError: 'You are logged in. Password reset only works when you are not logged in.',
        },
        changePassword: {
            field: {
                passwordOld: {
                    label: 'Current password',
                },
                passwordNew: {
                    label: 'New password',
                },
            },
            saveButton: 'Change password',
            driverWarning: 'Here you can change the PIN for the driver. When changing the PIN, they are logged out directly.',
            driverPin: 'PIN'
        },
        account: {
            title: 'Account',
            logoutButton: 'Log out',
            unmasqueradeButton: 'Unmasquerade',
        },
        changeName: {
            error: 'First or Last Name cannot be empty!',
            saveButton: 'Save user name',
        },
    },
    systemHealth: {
        title: 'System health',
        hereTitle: 'HERE',
        lastGeoApiCallLabel: 'Last time request was made:',
        lastSuccessfulGeoApiCallLabel: 'Last time successful request was made:',
        lastMeasuredPositionLabel: 'Last truck position update',
        fleetvisorUnprocessedMessagesLabel: 'Fleetvisor messages to process',
        fleetvisor: '{{measured_at}} | {{count}} | {{success}}',
        fleetvisorPopup: {
            title: "Detected issues with Fleetvisor",
            avgRequestCount: 'Average request count',
            avgRequestTime: 'Average request time',
            errorCount: 'Error count',
            totalCount: 'Total requests tracked',
            currentMailboxSize: 'Current Mailbox Size',
            avgMailboxCount: 'Average Mailbox Count',
        },
        ptvHealthLabel: 'PTV health check',
        ptvHealthContent: '{{measured_at}} | status "{{status}}" and took {{time}}',
        ptvHealthPopup: {
            title: 'Detected issues with PTV. Impact: kms calculation, route calculation, ETA calculation not working optimally.',
            avgRequestTime: 'Average request time',
            errorCount: 'Error count',
            totalCount: 'Total requests tracked',
        },
        recalculateKmsNonFinishedActivitiesLabel: 'Recalculate KM/s non finished',
        recalculateKmsNonFinishedActivitiesContent: 'id: {{id}} | status: {{status}}',
        recalculateKmsFinishedActivitiesLabel: 'Recalculate KM/s  finished',
        recalculateKmsFinishedActivitiesContent: 'id: {{id}} | status: {{status}}',
        autoFinalizationContent: 'id: {{id}} | status: auto-finalized',
        pollPtvLabel: 'Poll PTV',
        pollPtvContent: 'id: {{id}} | status: {{status}}',
        pollPtvNewLabel: 'Poll PTV (New)',
        autoFinalization: 'Activity auto finalization'
    },
    admin: {
        text: 'Please choose a submenu',
        createButton: 'Create diet rule',
    },
    menu: {
        title: 'Menu'
    },
    pageNotFoundMessage: 'Page not found :(',
    hardDelete: {
        header: 'DELETE',
        content: 'Are you sure to delete this object?',
        cancel: 'cancel',
        confirm: 'OK',
    },
    dispute: {
        field: {
            id: {
                label: 'Dispute ID'
            },
            contract: {
                label: 'Contract'
            },
            customer: {
                label: 'Customer'
            },
            date: {
                label: 'Date'
            },
            leftAmount: {
                label: 'Disputed'
            },
            status: {
                label: 'Status',
                options: {
                    waiting_for_cs: 'Waiting for CS',
                    waiting_for_customer: 'Waiting for Customer',
                    waiting_for_operations: 'Waiting for Operations',
                    ready_for_invoicing: 'Ready for invoicing',
                    finalized: 'Finalized'
                },
            }
        },
        button: {
            sendToOperations: 'Send to Operations',
            sendToCustomer: 'Send to Customer',
            approve: 'Approve',
            reject: 'Reject',
            approveAll: 'Approve all'
        },
        rejectionModal: {
            title: 'Reject',
            rejectionReason: 'Rejection reason',
            rejectionExplanation: 'Rejection explanation',
        }
    }
};
