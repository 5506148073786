import { tint } from 'polished';
import { IS_STAGE, IS_DEBUG } from 'helpers';

export const COLOR_PRIMARY_PROD = '#35a798'; // Production.
export const COLOR_PRIMARY_STAGE = '#a73535'; // Trucks will receive messages.
export const COLOR_PRIMARY_DEV = '#c86700'; // No trucks will get messages, except for the demo unit trucks.
export const COLOR_PRIMARY = IS_STAGE ? COLOR_PRIMARY_STAGE : IS_DEBUG ? COLOR_PRIMARY_DEV : COLOR_PRIMARY_PROD;
export const COLOR_RED = '#dc0818';
export const COLOR_TEXT = 'rgba(0, 0, 0, 0.7)';
export const COLOR_WARNING = '#ffc107';

export const COLOR_PRIMARY_LIGHT = tint(0.15, COLOR_PRIMARY);

export const COLOR_INVOICE_WAIVED = '#db2828';
export const COLOR_INVOICE_AGREED = '#2185d0';
export const COLOR_INVOICE_CORRECTED = '#00b5ad';
export const COLOR_INVOICE_INVOICED = '#2a912d';
export const COLOR_INVOICE_DISPUTED = '#a5673f';
export const COLOR_INVOICE_COSTS = '#858685';

export const COLOR_DRIVER_TRAVELING = '#6435C9';
export const COLOR_DRIVER_MAYBE_WORKING = '#b5d9f1';
export const COLOR_DRIVER_WORKING = '#84c0e8';
export const COLOR_DRIVER_HOME = '#a0a8d8';
export const COLOR_DRIVER_MAYBE_AT_HOME = '#bbc1e3';
export const COLOR_HAS_TACHO_EVENT = '#ffc700';
export const COLOR_NO_TACHO_EVENT = "#dbdbdb";
export const MATCH = '#1eba40';
export const NOT_MATCH = '#fe291d';
export const CHECKED = 'green';
export const DELETED = 'grey';

// The home-icon is shown on a white background. This is 2-shades darker than
// the driver home color. Also on a white background, it seems color blind ppl
// cannot see the different between COLOR_DRIVER_WORKING and
// COLOR_DRIVER_HOME_ICON.
export const COLOR_DRIVER_HOME_ICON = '#8086AC';

export const COLOR_BEP_PRIORITY_HIGH = '#ee0201';
export const COLOR_BEP_PRIORITY_NORMAL = '#ff7f27';
export const COLOR_BEP_PRIORITY_LOW = '#ffdd51';

// Theme for re-cy-cle
// TODO: We should slowly refactor all variables to use the theme prop instead of the exported consts above.
// ^ Don't agree... I think it's better to use the const exports: if you import a wrong const, it will complain at compile time.
export const theme = {
    primaryColor: COLOR_PRIMARY,

    // Same as re-cy-cle. Copy pasted here, so I can easily access these colors in tht tests.
    dangerColor: '#dc0818',
    warningColor: COLOR_WARNING,

    zIndexModal: 1001,
};

export default theme;
