import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { InvoiceLineItemStore } from 'store/InvoiceLineItem'

export const SUPPORTED_CURRENCIES = [
    {
        code: 'PLN',
        currency: 'Zloty'
    },
    {
        code: 'SEK',
        currency: 'Swedish krona'
    },
    {
        code: 'GBP',
        currency: 'Pound sterling'
    },
    {
        code: 'EUR',
        currency: 'Euro'
    },
]

export class Currency extends Model {
    static backendResourceName = 'currency';

    @observable id = null;
    @observable rate = 1;
    @observable type = 'EUR';
    @observable createdAt = null;
    @observable rateDate = null;

    relations() {
        return {
            invoiceLineItems: InvoiceLineItemStore,
        }
    }
}

export class CurrencyStore extends Store {
    Model = Currency
    static backendResourceName = 'currency';
}
