import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import {  Modal } from 'semantic-ui-react';
import { IconButton } from 'spider/semantic-ui/Button';
import { BEPTicketSubcategory, MEANING_POSITIVE, MEANING_NEGATIVE } from 'store/BEPTicket';
import CreateBEPDialog from 'component/BEPTicket/CreateDialog';
import HeaderRight from 'spider/semantic-ui/HeaderRight';
import { Activity } from 'store/Activity';
import { Invoice } from 'store/Invoice';
import { Truck } from 'store/Truck';
import { Driver } from 'store/Driver';
import { Customer } from 'store/Customer';
import { Supplier } from 'store/Supplier';
import { Trailer } from 'store/Trailer';
import { Entity } from 'store/Entity';
import { User } from 'store/User';
import styled from 'styled-components';
import { Incident } from 'store/Incident';

const StyledHeaderRight = styled(HeaderRight)`
    /* To display avatar nicely centered T19832. */
    .ui.header {
        display: flex;
    }
`;

/**
 * Render context-dependent thumbs up/down buttons to be used for
 * creating a positive or negative BEP ticket for this particular
 * context object (truck, driver, activity, ...).
 */
@observer
export default class BEPContextButtons extends Component {
    static propTypes = {
        entity: PropTypes.instanceOf(Entity),

        contextType: PropTypes.string.isRequired,
        contextActivity: PropTypes.instanceOf(Activity),
        contextInvoice: PropTypes.instanceOf(Invoice),
        contextTruck: PropTypes.instanceOf(Truck),
        contextTrailer: PropTypes.instanceOf(Trailer),
        contextIncident: PropTypes.instanceOf(Incident),

        subjectToType: PropTypes.string.isRequired,
        subjectToDriver: PropTypes.instanceOf(Driver),
        subjectToCustomer: PropTypes.instanceOf(Customer),
        subjectToUser: PropTypes.instanceOf(User),
        subjectToEntity: PropTypes.instanceOf(Entity),
        subjectToSupplier: PropTypes.instanceOf(Supplier),
        subjectToSubcategory: PropTypes.instanceOf(BEPTicketSubcategory),

        currentUser: PropTypes.instanceOf(User).isRequired,
        disabled: PropTypes.bool,
        children: PropTypes.node,
        content: PropTypes.string,
        size: PropTypes.string.isRequired,
    };

    static defaultProps = {
        size: 'large',
    };

    @observable bepModalNegativeOpen = false;
    @observable bepModalPositiveOpen = false;

    render() {
        const { content, children, size } = this.props;

        return (
            <StyledHeaderRight data-test-bep-context-buttons content={content || ''}>
                {children}
                <Modal open={this.bepModalPositiveOpen} onClose={() => this.bepModalPositiveOpen = false}>
                    <CreateBEPDialog
                        close={() => this.bepModalPositiveOpen = false}
                        meaning={MEANING_POSITIVE}
                        currentUser={this.props.currentUser}
                        entity={this.props.entity ? this.props.entity.toJS() : null}

                        contextType={this.props.contextType}
                        contextActivity={this.props.contextActivity ? this.props.contextActivity.toJS() : null}
                        contextInvoice={this.props.contextInvoice ? this.props.contextInvoice.toJS() : null}
                        contextTruck={this.props.contextTruck ? this.props.contextTruck.toJS() : null}
                        contextTrailer={this.props.contextTrailer ? this.props.contextTrailer.toJS() : null}
                        contextIncident={this.props.contextIncident ? { id:  this.props.contextIncident.id} : null} // Incident.toJS() is causing a circular structure issue. Passing only id is fine for now.

                        subjectToType={this.props.subjectToType}
                        subjectToDriver={this.props.subjectToDriver ? this.props.subjectToDriver.toJS() : null}
                        subjectToCustomer={this.props.subjectToCustomer ? this.props.subjectToCustomer.toJS() : null}
                        subjectToUser={this.props.subjectToUser ? this.props.subjectToUser.toJS() : null}
                        subjectToEntity={this.props.subjectToEntity ? this.props.subjectToEntity.toJS() : null}
                        subjectToSupplier={this.props.subjectToSupplier ? this.props.subjectToSupplier.toJS() : null}
                        subjectToSubcategory={this.props.subjectToSubcategory ? this.props.subjectToSubcategory.toJS() : null}
                    />
                </Modal>
                <IconButton data-test-add-positive-bep-button
                    name="thumbs up outline"
                    size={size}
                    title={t('nav.main.bep.positive')}
                    disabled={this.props.disabled}
                    onClick={() => this.bepModalPositiveOpen = true}
                />
                <Modal open={this.bepModalNegativeOpen} onClose={() => this.bepModalNegativeOpen = false}>
                    <CreateBEPDialog
                        close={() => this.bepModalNegativeOpen = false}
                        meaning={MEANING_NEGATIVE}
                        currentUser={this.props.currentUser}
                        entity={this.props.entity ? this.props.entity.toJS() : null}

                        contextType={this.props.contextType}
                        contextActivity={this.props.contextActivity ? this.props.contextActivity.toJS() : null}
                        contextInvoice={this.props.contextInvoice ? this.props.contextInvoice.toJS() : null}
                        contextTruck={this.props.contextTruck ? this.props.contextTruck.toJS() : null}
                        contextTrailer={this.props.contextTrailer ? this.props.contextTrailer.toJS() : null}
                        contextIncident={this.props.contextIncident ? { id:  this.props.contextIncident.id} : null} // Incident.toJS() is causing a circular structure issue. Passing only id is fine for now.

                        subjectToType={this.props.subjectToType}
                        subjectToDriver={this.props.subjectToDriver ? this.props.subjectToDriver.toJS() : null}
                        subjectToCustomer={this.props.subjectToCustomer ? this.props.subjectToCustomer.toJS() : null}
                        subjectToUser={this.props.subjectToUser ? this.props.subjectToUser.toJS() : null}
                        subjectToEntity={this.props.subjectToEntity ? this.props.subjectToEntity.toJS() : null}
                        subjectToSupplier={this.props.subjectToSupplier ? this.props.subjectToSupplier.toJS() : null}
                        subjectToSubcategory={this.props.subjectToSubcategory ? this.props.subjectToSubcategory.toJS() : null}
                    />
                </Modal>
                <IconButton data-test-add-negative-bep-button
                    name="thumbs down outline"
                    size={size}
                    disabled={this.props.disabled}
                    title={t('nav.main.bep.negative')}
                    onClick={() => this.bepModalNegativeOpen = true}
                />
            </StyledHeaderRight>
        );
    }
}
