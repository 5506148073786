import { observable, action } from 'mobx';
import { Model, Store, api } from 'store/Base';
import { InvoiceLineCorrectionStore } from './InvoiceLineCorrection';
import { WaiverStore } from './Waiver';
import { OrderedStore } from 'store/Base';
import { User } from './User';
import { InvoiceLine } from './InvoiceLine';
import { Casts } from 'store/Base';
import { DisputeComment } from './Dispute/Comment';
import { omit } from 'lodash';
import { Contract } from './Contract';

export const STATUS_WAITING_FOR_CS = 'waiting_for_cs';
export const STATUS_WAITING_FOR_CUSTOMER = 'waiting_for_customer';
export const STATUS_WAITING_FOR_OPERATIONS = 'waiting_for_operations';
export const STATUS_READY_FOR_INVOICING = 'ready_for_invoicing';
export const STATUS_FINALIZED = 'finalized';

export const STATUSES = [STATUS_WAITING_FOR_CS, STATUS_WAITING_FOR_CUSTOMER, STATUS_WAITING_FOR_OPERATIONS, STATUS_READY_FOR_INVOICING, STATUS_FINALIZED];

/**
 * Each amount is stored in cents, but any in between calculations should not
 * do any rounding. This means that:
 *
 * - Functions that start with calcExpected returns float.
 * - Setting any amounts on invoiceLine requires rounding.
 * - Disputed should round calcExpected before compairing to manually set amount.
 */
export class Dispute extends Model {
    static backendResourceName = 'dispute';

    static omitFields = [
        'currentComment',
        'leftAmount'
    ]

    @observable id = null;
    @observable date = null;
    @observable disputedAmount = null;
    @observable leftAmount = null;
    @observable sentAt = null;
    @observable approvedAt = null;
    @observable sentToCustomerAt = null;
    @observable status = null;

    relations() {
        return {
            waivers: WaiverStore,
            corrections: InvoiceLineCorrectionStore,
            invoiceLine: InvoiceLine,
            finalizedBy: User,
            contract: Contract,
            currentComment: DisputeComment,
        };
    }

    casts() {
        return {
            date: Casts.date,
            sentAt: Casts.datetime,
        };
    }

    approve() {
        return this.api.post(this.url + 'approve/');
    }

    reject(reason, explanation) {
        return this.api.post(this.url + 'reject/', {reason, explanation});
    }


    /**
     * Handling disputes uses a special endpoint, since it will create a new
     * invoice if no open invoice exists for that client. Otherwise it will
     * add corrections to an open invoice.
     */
    handle(correction, waiver) {
        const data = {
            dispute: this.id,
            invoice_line_correction: {
                // There was a bug where the invoice_line didn't match the line.id.
                // Lets just omit it to be sure.
                ...omit(correction.toBackend(), 'dispute', 'items'),
                dispute: this.id,
            },
            invoice_line_correction_items: correction.items.map(item => item.toBackend()),
            waiver: {
                ...omit(waiver.toBackend(), 'dispute', 'waiver_items'),
                dispute: this.id,
            },
            waiver_items: waiver.waiverItems.map(waiverItem => waiverItem.toBackend()),
        };

        if (!correction.hasCorrections) {
            delete data.invoice_line_correction;
            delete data.invoice_line_correction_items;
        }

        if (!waiver.hasWaivers) {
            delete data.waiver;
            delete data.waiver_items;
        }

        return api.post('/dispute/handle/', data);
    }
}

class UnorderedDisputeStore extends Store {
    Model = Dispute;

    static backendResourceName = 'dispute';

    @action
    fetchAssignedDisputesCount() {
        return this.api.get('/dispute/count_assigned/').then(res => {
            return res.data.count;
        });
    }

    approveAll() {
        return this.api.post(this.url() + 'approve_all/');
    }
}

export const DisputeStore = OrderedStore(UnorderedDisputeStore, 'ordering');

export class AssignedDisputeStore extends DisputeStore {
    @action
    fetch(options = {}) {
        return super.fetch({url: `${this.url()}assigned/`, ...options});
    }
}