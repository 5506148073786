import { observable } from 'mobx';
import { Model, Store } from './Base';
import { DietRuleStore } from './DietRule';
import { Entity } from './Entity';

export class PayrollCompany extends Model {
    static backendResourceName = 'payroll_company';

    @observable id = null;
    @observable name = '';

    relations() {
        return {
            entity: Entity,
            dietRules: DietRuleStore,
        }
    }
}

export class PayrollCompanyStore extends Store {
    Model = PayrollCompany;
    static backendResourceName = 'payroll_company';
}
