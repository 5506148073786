import React from 'react';
import { reaction, observable } from 'mobx';
import { observer } from 'mobx-react';
import { EditorState, RichUtils } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import addLinkPlugin from 'component/Draftjs/addLinkPlugin';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import styled from 'styled-components';

import { TargetBase } from 'spider/semantic-ui/Target';

const EditorStyling = styled.div`
    /* Stolen from .ui.form textarea */
    .DraftEditor-root {
        margin: 0em;
        -webkit-appearance: none;
        tap-highlight-color: rgba(255, 255, 255, 0);
        padding: 0.78571429em 1em;
        background: #FFFFFF;
        border: 1px solid rgba(34, 36, 38, 0.15);
        outline: none;
        color: rgba(0, 0, 0, 0.87);
        border-radius: 0.28571429rem;
        -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
        box-shadow: 0em 0em 0em 0em transparent inset;
        -webkit-transition: color 0.1s ease, border-color 0.1s ease;
        transition: color 0.1s ease, border-color 0.1s ease;
        font-size: 1em;
        line-height: 1.2857;
        resize: vertical;

        /* Stolen from .ui.form textarea:focus */
        ${props => props.hasFocus && `
            color: rgba(0, 0, 0, 0.95);
            border-color: #85B7D9;
            border-radius: 0.28571429rem;
            background: #FFFFFF;
            -webkit-box-shadow: 0px 0em 0em 0em rgba(34, 36, 38, 0.35) inset;
            box-shadow: 0px 0em 0em 0em rgba(34, 36, 38, 0.35) inset;
            -webkit-appearance: none;
        `}
    }
`;

const fromHtml = convertFromHTML({
    htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === 'a') {
            return createEntity(
                'LINK',
                'MUTABLE',
                {url: node.href}
            )
        }
    },
});

const toHtml = convertToHTML({
    entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return <a href={entity.data.url}>{originalText}</a>;
        }

        return originalText;
    }
});

@observer
export default class TargetWysiwygInput extends TargetBase {
    @observable editorState = EditorState.createWithContent(fromHtml(this.value));
    @observable hasFocus = false;
    @observable ignoreReaction = false;

    componentDidMount() {
        this.disposeReaction = reaction(
            () => this.value,
            value => {
                if (!this.ignoreReaction) {
                    this.editorState = EditorState.createWithContent(fromHtml(value))
                }

                this.ignoreReaction = false
            },
        );
    }

    componentWillUnmount() {
        this.disposeReaction();
    }

    onChange(editorState) {
        this.editorState = editorState;

        this.ignoreReaction = true;
        super.onChange(toHtml(editorState.getCurrentContent()));

        // Initial onChange happens before reaction?
        this.ignoreReaction = false;
    }

    addLink = () => {
        const editorState = this.editorState;
        const selection = editorState.getSelection();
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
            url: 'http://www.nu.nl',
        });
        const newEditorState = EditorState.push(
            editorState,
            contentWithEntity,
            "create-entity"
        );
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        this.editorState = RichUtils.toggleLink(newEditorState, selection, entityKey);
    }

    renderContent(props) {
        return (
            <React.Fragment>
                {/*
                <button
                    onClick={() => {
                        this.editorState = RichUtils.toggleInlineStyle(this.editorState, 'BOLD');
                    }}
                >
                    Bold
                </button>
                <button
                    onClick={() => {
                        this.addLink()
                    }}
                >
                    Add link
                </button>
                <hr/>
                */}
                <EditorStyling hasFocus={this.hasFocus} name={props.name}>
                    <Editor
                        editorState={this.editorState}
                        onChange={this.onChange}
                        onFocus={() => this.hasFocus = true}
                        onBlur={() => this.hasFocus = false}
                        plugins={[addLinkPlugin]}
                        {...props}
                    />
                </EditorStyling>
            </React.Fragment>
        );
    }
}
