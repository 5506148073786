import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Grid, Form, Modal } from 'semantic-ui-react';
import { BEPTicket, BEPTicketCategory, BEPTicketCategoryStore, BEPTicketSubcategoryStore, BEPTicketSubcategory, SUBJECT_TO_TYPE, SUBJECT_FROM_TYPE, SUBJECT_TYPE_USER, IMPACT_SMALL, IMPACT_LARGE } from 'store/BEPTicket';
import { Documents } from 'container/BEPTicket/Edit/Documents';
import { FormRadioButtons } from 'spider/semantic-ui/Form';
import { SaveButton, CancelButton } from 'spider/semantic-ui/Button';
import { FormSelect, FormTextArea } from 'spider/semantic-ui/Form';
import Context from 'component/BEPTicket/Context';
import Subject from 'component/BEPTicket/Subject';
import { showSaveNotification } from 'helpers/notification';
import { User, UserStore } from 'store/User';
import { Entity, EntityStore } from 'store/Entity';
import { Customer, CustomerStore } from 'store/Customer';
import { Driver, DriverStore } from 'store/Driver';
import { Supplier, SupplierStore } from 'store/Supplier';
import { Activity } from 'store/Activity';
import { Invoice } from 'store/Invoice';
import { Truck } from 'store/Truck';
import { Trailer } from 'store/Trailer';
import RightDivider from 'spider/component/RightDivider';
import { Incident } from 'store/Incident';
import { HistoryBonus, HistoryBonusStore } from 'store/Bonus'
import { DriverBonusStore } from 'store/DriverBonus'
import { TargetSelect, TargetDatePicker, TargetTextArea } from 'spider/semantic-ui/Target';
import moment from 'moment';
import TargetMoneyInput from 'component/TargetMoneyInput';

@observer
export default class CreateDialog extends Component {
    static propTypes = {
        meaning: PropTypes.string.isRequired,
        subjectToType: PropTypes.string,
        contextType: PropTypes.string,
        close: PropTypes.func,
        currentUser: PropTypes.instanceOf(User).isRequired,
        entity: PropTypes.instanceOf(Entity),
        subjectToDriver: PropTypes.instanceOf(Driver),
        subjectToCustomer: PropTypes.instanceOf(Customer),
        subjectToUser: PropTypes.instanceOf(User),
        subjectToEntity: PropTypes.instanceOf(Entity),
        subjectToSupplier: PropTypes.instanceOf(Supplier),
        subjectToSubcategory: PropTypes.instanceOf(BEPTicketSubcategory),
        contextActivity: PropTypes.instanceOf(Activity),
        contextInvoice: PropTypes.instanceOf(Invoice),
        contextTruck: PropTypes.instanceOf(Truck),
        contextTrailer: PropTypes.instanceOf(Trailer),
        contextIncident: PropTypes.instanceOf(Incident),
    };

    @observable bepTicket = new BEPTicket({
        meaning: this.props.meaning,
        entity: this.props.entity,

        subjectFromType: SUBJECT_TYPE_USER,
        subjectFromUser: this.props.currentUser.toJS(),
        subjectToType: this.props.subjectToType,
        subjectToDriver: this.props.subjectToDriver,
        subjectToCustomer: this.props.subjectToCustomer,
        subjectToUser: this.props.subjectToUser,
        subjectToEntity: this.props.subjectToEntity,
        subjectToSupplier: this.props.subjectToSupplier,
        subjectToSubcategory: this.props.subjectToSubcategory,

        contextType: this.props.contextType,
        contextActivity: this.props.contextActivity,
        contextInvoice: this.props.contextInvoice,
        contextTruck: this.props.contextTruck,
        contextTrailer: this.props.contextTrailer,
        contextIncident: this.props.contextIncident,

        needsFollowup: false, // Should this be overridable?
    }, {
        relations: [
            'documents', 'entity',

            'category', 'category.followupActionTaker', 'category.followupActionCategory', 'category.driverBonus', 'bonuses.driver', 'bonuses.driverBonus',

            'subjectFromDriver', 'subjectFromCustomer', 'subjectFromUser',
            'subjectFromSupplier',

            'subjectToDriver', 'subjectToCustomer', 'subjectToUser',
            'subjectToEntity', 'subjectToSupplier', 'subjectToSubcategory',

            'contextActivity', 'contextInvoice',
            'contextTruck', 'contextTrailer', 'contextIncident',

            'followupActionTaker', 'followupActionCategory', 'finishedBy',
            'reviewedBy', 'createdBy',

            'followups', 'followups.createdBy', 'followups.performedBy',
            'followups.requestedActionCategory',
            'followups.performedActionCategory',
        ],
    });
    @observable entityStore = new EntityStore();
    @observable userStore = new UserStore();
    @observable customerStore = new CustomerStore();
    @observable driverStore = new DriverStore();
    @observable supplierStore = new SupplierStore();
    @observable bepTicketSubcategoryStore = new BEPTicketSubcategoryStore();
    @observable bepTicketCategoryStore = new BEPTicketCategoryStore({
        limit: null,
        relations: [
            'followupActionTaker',
            'followupActionCategory',
            'subjectToSubcategories',
            'driverBonus',
        ],
    });
    @observable allowedSubjectToTypes = SUBJECT_TO_TYPE;

    @observable driverBonusStore = new DriverBonusStore({
        params: {
            '.bonus_or_deduction_from_bep': true,
            '.type': 'bonus',
        },
    });

    @observable driverDeductionStore = new DriverBonusStore({
        params: {
            '.bonus_or_deduction_from_bep': true,
            '.type': 'deduction',
        },
    });

    @observable bonus = new HistoryBonus({},
        {
        relations: ['driver', 'driverBonus']
        }
    )
    @observable bonusStore = new HistoryBonusStore({
        relations: [
            'driver',
            'driverBonus',
            'bepTicket.category',
        ],
        params: {
            '.driver': new Driver().id
        }
    })

    @observable choosenCategory = new BEPTicketCategory();

    componentDidMount() {
        this.entityStore.fetch();
        this.bepTicketCategoryStore.fetch();
        this.driverBonusStore.fetch();
        this.driverDeductionStore.fetch();
    }

    componentDidUpdate() {
        if (this.bonus.dateObtained === null) {
            this.bonus.dateObtained=moment()
        }

        if (this.choosenCategory.relatedToSalary){
            if (this.bepTicket.meaning === 'positive'){
                this.bonus.setInput('driverBonus', this.driverBonusStore.at(0))
            }
            if (this.bepTicket.meaning === 'negative'){
                this.bonus.setInput('driverBonus', this.driverDeductionStore.at(0))
            }
            if (this.choosenCategory.bepAmount && this.bonus.bonusRate === null){
                this.bonus.setInput('bonusRate', this.choosenCategory.bepAmount)
            }
            if (this.bepTicket.meaning === 'idea'){
                this.bonus = new HistoryBonus({},
                    {
                        relations: ['driver', 'driverBonus']
                    }
                )
            }
        }

        if(this.bepTicket.toJS().id!==null && this.bepTicket.bonuses.length > 0){
            this.bonus = this.bepTicket.bonuses.at(0)

        }else{
            this.bonus.driver = this.bepTicket.subjectToDriver
            this.bonus.type = this.bonus.driverBonus.type
        }
    }

    describeContext() {
        return t('bepTicket.contextDescription', {
            contextType: t(`bepTicket.contextType.${this.bepTicket.contextType}`),
            contextText: this.bepTicket.contextToText()
        });
    }

    disabled = () => {
        if (this.choosenCategory.relatedToSalary) {
            if (!this.bonus.dateObtained || !this.bonus.bonusRate || !this.bonus.remark){
                return true
            }
        }
        return false
    }

    disableBonusRate = () => {
        if (!!this.bepTicket.reviewedAt || this.choosenCategory.fixed) {
            return true
        }
        return false
    }

    save = () => {
        const { close, currentUser } = this.props;

        this.bonus.makeDeductionAlwaysNegative()
        this.bonus.makeBonusAlwayspositive()

        if (currentUser.entities.length > 0) {
            this.bepTicket.setInput('entity', currentUser.entities.at(0));
        }

        if (this.bepTicket.bonuses.length===0 && this.bepTicket.meaning !== 'idea' && this.choosenCategory.relatedToSalary === true) {
            this.bepTicket.bonuses.add(this.bonus.toJS())
        }

        this.bepTicket.saveAll({ relations: ['documents', 'bonuses.driver', 'bonuses.driverBonus'], onlyChanges: true, })
            .then(() => Promise.all(this.bepTicket.documents.map(doc => doc.file.save(doc.url) )))
            .then(() => {
                showSaveNotification();
                close();
            });
    };

    render() {
        return (
            <React.Fragment>
                <Modal.Header>
                    {this.bepTicket.contextType
                        ? t('bepTicket.create.titleWithContext', {
                            meaning: t(`bepTicket.meaning.${this.bepTicket.meaning}`),
                            context: this.describeContext(),
                        })
                        : t('bepTicket.create.titleWithoutContext', {
                            meaning: t(`bepTicket.meaning.${this.bepTicket.meaning}`),
                        })
                    }
                </Modal.Header>
                <Modal.Content scrolling>
                    <Form>
                        <Grid columns={2}>
                            <Grid.Row>
                                {false && <Grid.Column width={16}>
                                    <Context
                                        bepTicket={this.bepTicket}
                                    />
                                </Grid.Column>}
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Subject
                                        subject="subjectFrom"
                                        allowedTypes={SUBJECT_FROM_TYPE}
                                        bepTicket={this.bepTicket}
                                        userStore={this.userStore}
                                        driverStore={this.driverStore}
                                        customerStore={this.customerStore}
                                        supplierStore={this.supplierStore}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Subject
                                        subject="subjectTo"
                                        allowedTypes={this.allowedSubjectToTypes}
                                        bepTicket={this.bepTicket}
                                        userStore={this.userStore}
                                        driverStore={this.driverStore}
                                        entityStore={this.entityStore}
                                        customerStore={this.customerStore}
                                        supplierStore={this.supplierStore}
                                        bepTicketSubcategoryStore={this.bepTicketSubcategoryStore}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <FormSelect search
                                        model={this.bepTicket}
                                        name="category"
                                        label={t('bepTicket.field.category.label')}
                                        options={this.bepTicketCategoryStore.filter(e => e.acceptableForTicket(this.bepTicket)).map(e => ({
                                            text: e.name,
                                            value: e.id,
                                        }))}
                                        disabled={!this.bepTicket.subjectToType}
                                        afterChange={() => {
                                            // Yay, after change, the category model is an empty husk with only an id...
                                            const cat = this.bepTicketCategoryStore.get(this.bepTicket.category.id);
                                            cat.populateTicketFromDefaults(this.bepTicket);
                                            this.choosenCategory = cat
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <FormRadioButtons
                                        model={this.bepTicket}
                                        name="impact"
                                        label={t('bepTicket.field.impact.label')}
                                        options={[IMPACT_SMALL, IMPACT_LARGE].map(k => ({
                                            label: t(`bepTicket.impact.${k}`),
                                            value: k,
                                        }))}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <FormTextArea model={this.bepTicket} name="description" label={t('bepTicket.field.description.label')} />
                                </Grid.Column>
                            </Grid.Row>
                            {(this.choosenCategory.relatedToSalary && this.bepTicket.meaning !== 'idea') &&
                                <React.Fragment>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <TargetDatePicker required
                                                target={this.bonus}
                                                name='dateObtained'
                                                disabled={!!this.bepTicket.reviewedAt}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <TargetSelect remote  disabled required
                                                key={this.bonus.driverBonus.id}
                                                target={this.bonus}
                                                name="driverBonus"
                                                store={this.bepTicket.meaning==='positive' ? this.driverBonusStore : (this.bepTicket.meaning==='negative' && this.driverDeductionStore )}
                                                toOption={bonus => ({
                                                    value: bonus.id,
                                                    text: bonus.name,
                                                })}
                                                label={t('nav.masterdata.driverBonus')}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <TargetMoneyInput  allowNegative required
                                                target={this.bonus}
                                                label='Amount'
                                                name='bonusRate'
                                                disabled={this.disableBonusRate()}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <TargetTextArea required
                                                target={this.bonus}
                                                name='remark'
                                                disabled={!!this.bepTicket.reviewedAt}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </React.Fragment>
                                }
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Documents bepTicket={this.bepTicket}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions style={{ display: 'flex' }}>
                    <CancelButton onClick={this.props.close} />
                    <RightDivider />
                    <SaveButton disabled={this.disabled()} primary loading={this.bepTicket.isLoading} onClick={this.save} />
                </Modal.Actions>
            </React.Fragment>
        );
    }
}
