import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
export const REASONS_FOR_FIRING = 'reasons for firing';
export const REASONS_FOR_REJECTION_OF_LEAD = 'reasons for rejection of lead';
export const REASONS_FOR_QUITTING = 'reasons for quitting';
export const REASONS_FOR_REJECT_BONUS = 'reasons for reject bonus';
export const REASONS_FOR_REJECTION_OF_DISPUTE = 'reasons for rejection of dispute';
export const KINDS_OF_REJECTION_REASON = [REASONS_FOR_FIRING, REASONS_FOR_REJECTION_OF_LEAD, REASONS_FOR_QUITTING, REASONS_FOR_REJECT_BONUS, REASONS_FOR_REJECTION_OF_DISPUTE];

export class RejectionReason extends Model {
    static backendResourceName = 'rejection_reason';

    @observable id = null;
    @observable description = '';
    @observable kind = '';
}

export class RejectionReasonStore extends Store {
    Model = RejectionReason;
    static backendResourceName = 'rejection_reason';
}
