import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Entity } from 'store/Entity';

export class LedgerVat extends Model {
    static backendResourceName = 'ledger_vat';

    @observable id = null;
    @observable ledgerCode = '';
    @observable country = '';

    relations() {
        return {
            entity: Entity,
        };
    }

    getLedgerVatText() {
        if(this.entity.name.indexOf('.') !== -1){
            return `${this.ledgerCode} - ${this.country} - ${this.entity.name.substring(this.entity.name.indexOf('.')-2, this.entity.name.indexOf('.')).toUpperCase()}`;
        } else {
            return `${this.ledgerCode} - ${this.country} - ${this.entity.name.split(" ").pop().toUpperCase()}`;
        }
    }
}

export class LedgerVatStore extends Store {
    static backendResourceName = 'ledger_vat';

    Model = LedgerVat;
}
