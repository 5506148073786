import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { TollboxSupplier } from "./TollboxSupplier";

export class TruckTollbox extends Model {
    static backendResourceName = 'truck_tollbox';

    @observable id = null;
    @observable countries = [];
    @observable serialNumber = '';
    @observable validStartDate = null;
    @observable validEndDate = null;
    @observable documentScan = null;
    @observable deleted = false;

    relations() {
        return {
            supplier: TollboxSupplier,
        };
    }

    casts() {
        return {
            validStartDate: Casts.date,
            validEndDate: Casts.date,
        };
    }
}

export class TruckTollboxStore extends Store {
    Model = TruckTollbox;
    static backendResourceName = 'truck_tollbox';
}
