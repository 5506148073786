import { observable, action } from 'mobx';
import { Model, api, parseRegisterCancelToken } from 'store/Base';
import { MessageStore } from './Message';
import { CONDITION_ADR } from './Activity';
import { get, omit } from 'lodash';
import { TruckPositionStore } from './TruckPosition';
import { ActivityStore } from './Activity';
import { SERVER_DATETIME_FORMAT } from 'helpers';

/**
 * Try to match activity based on truck and timestamp. Tries 2 things:
 *
 * 1. Match truck position based on aMoment. Then, find activity based on odometer. Check that day matches of aMoment and activity.finishedDatetime.
 * 2. Match aMoment and activity.finishedDatetime as fallback.
 *
 * The fallback (2.) is because before 2020 we pruned the truck positions after 3 months.
 */
export function fetchLocationByTruck(truckId, aMoment, activityHasFuelCost=false) {
    return new Promise((resolve, reject) => {
        const truckPositionStore = new TruckPositionStore();
        const activityByFinishedDatetimeStore = new ActivityStore({ relations: ['location', 'assignment.driver1', 'assignment.driver2']});
        const activityByTruckPositionStore = new ActivityStore({ relations: ['location', 'assignment.driver1', 'assignment.driver2']});

        if (!!truckId && !!aMoment) {
            truckPositionStore.params = {
                '.truck': truckId,
                '.measured_at:gte': aMoment.format(SERVER_DATETIME_FORMAT),
                '.odometer:not:isnull': '',
                order_by: 'measured_at',
                limit: 1,
            };

            if(activityHasFuelCost){
                activityByFinishedDatetimeStore.params = {
                    '.assignment.truck': truckId,
                    '.finished_datetime:gte': aMoment.format(SERVER_DATETIME_FORMAT),
                    '.type': 'tanking',
                    order_by: 'finished_datetime',
                    limit: 1,
                };
            }else{
                activityByFinishedDatetimeStore.params = {
                    '.assignment.truck': truckId,
                    '.finished_datetime:gte': aMoment.format(SERVER_DATETIME_FORMAT),
                    order_by: 'finished_datetime',
                    limit: 1,
                };
            }


            Promise.all([
                activityByFinishedDatetimeStore.fetch(),
                truckPositionStore.fetch()
            ]).then(() => {
                    if (truckPositionStore.length === 1) {
                        let activity;
                        let location;

                        const truckPosition = truckPositionStore.at(0);

                        if(activityHasFuelCost){
                            activityByTruckPositionStore.params = {
                                '.start_km:lte': truckPosition.odometer,
                                '.end_km:gte': truckPosition.odometer,
                                '.type': 'tanking',
                                '.assignment.truck': truckId,
                                limit: 1,
                            };
                        }else{
                            activityByTruckPositionStore.params = {
                                '.start_km:lte': truckPosition.odometer,
                                '.end_km:gte': truckPosition.odometer,
                                '.assignment.truck': truckId,
                                limit: 1,
                            };
                        }


                        activityByTruckPositionStore.fetch().then(() => {
                            if (
                                activityByTruckPositionStore.length === 1 &&
                                aMoment.clone().subtract(1, 'weeks').format('YYYY-MM-DD') <= activityByTruckPositionStore.at(0).finishedDatetime.format('YYYY-MM-DD') &&
                                activityByTruckPositionStore.at(0).finishedDatetime.format('YYYY-MM-DD') <= aMoment.clone().add(1, 'weeks').format('YYYY-MM-DD')
                            ) {
                                activity = activityByTruckPositionStore.at(0);
                            } else if (activityByFinishedDatetimeStore.length === 1) {
                                activity = activityByFinishedDatetimeStore.at(0);
                            }

                            truckPosition.geocode().then(response => {
                                location = new Location({ ...truckPosition.toLocation(), id: response.data.id });
                                resolve({ activity, location });
                            });
                        });
                    }
                })
                .catch(reject);
        } else {
            reject();
        }
    });
}

/**
 * Fetch markers for an activity. To be used to draw a route on a map.
 */
export function fetchMarkers({
    activity, activityIndex = 0, truckPos = null, timeFrame = null, allocation = null,

    // Arrays which will be mutated with the result.
    activityMarkers = [], activityRoute = [], truckRoute = []
}, requestOptions) {
    // Markers for previous activities and stops between it.
    return activity.fetchPrecedingRoute().then(route1Res => {
        const activities = route1Res.data;
        const optimizationLevel = activity.routeOptimizationLevel;
        const hazardousGoods = activity.conditions.includes(CONDITION_ADR);
        const euroClass = activity.allocation
            ? activity.allocation.contract.euroClass
            : activity.allocation;

        activityMarkers.push(...activities);

        // Route between the markers from above
        const routeLocations = [];

        activities.forEach(a => {
            routeLocations.push({
                lat: a.location.point.lat,
                lng: a.location.point.lng,
            });
            if (a.combined_transport_id) { // XXX: Note that "activities" doesn't hold real Activity objects
                routeLocations.push({
                    combined_transport_id: a.combined_transport_id,
                });
            }
        });

        // Try catch because if 2 preceding routes have the same location, then
        // calculateRoute will fail.
        // {fail-route}
        Location.calculateRoute(routeLocations, { optimizationLevel, hazardousGoods, euroClass }, requestOptions)
            .then(route2Res => {
                activityRoute.push(...route2Res.coordinates.map(({ lat, lng }, coordinateIndex) => ({
                    activityIndex: activityIndex,
                    coordinateIndex: coordinateIndex,
                    point: { lat, lng },
                })));
            })
            .catch(() => {});


        if (truckPos && timeFrame === 'current') {
            const routeFromPositionLocations = [{
                lat: truckPos.point.lat,
                lng: truckPos.point.lng,
            }, {
                lat: activity.location.point.lat,
                lng: activity.location.point.lng,
            }];
            if (activity.combinedTransportId) {
                routeFromPositionLocations.push({
                    combined_transport_id: activity.combinedTransportId,
                });
            }

            Location.calculateRoute(routeFromPositionLocations, { optimizationLevel, hazardousGoods }, requestOptions)
                .then(route3Res => truckRoute.push(...route3Res.coordinates.map(({ lat, lng }, coordinateIndex) => ({
                    activityIndex: activityIndex,
                    coordinateIndex: coordinateIndex,
                    point: { lat, lng },
                }))))
                .catch(() => {});
        }
    });
};

export class Location extends Model {
    static backendResourceName = 'location';

    // Hack so LocationEditFreeForm reparses the given location.
    @observable _key = null;
    @observable id = null;
    @observable address = '';
    @observable street = '';
    @observable houseNumber = '';
    @observable zipCode = '';
    @observable city = '';
    @observable country = 'NL';
    @observable geoStatus = '';
    @observable preciseMatch = true;
    @observable timezone = 'Europe/Amsterdam';
    @observable point = {
        lng: null,
        lat: null,
    }

    relations() {
        return {
            messages: MessageStore,
        };
    }

    toBackend(options) {
        const data = super.toBackend(options);
        return omit(data, ['geo_status', 'precise_match']);
    }

    @action
    fetchCountries() {
        return this.api.get('/location/country/').then(res => {
            return res.data;
        });
    }

    // {geocode-copy-pasta}
    @action
    geocode() {
        this.__pendingRequestCount += 1;
        const address = this.address;
        if (!address) {
            return Promise.resolve();
        }
        this.geoStatus = 'loading';
        return this.api
            .post(
                '/location/geocode/',
                { address },
                {
                    skipRequestError: true,
                }
            )
            .then(this.fromBackend.bind(this))
            .then(() => {
                this.geoStatus = 'success';
                this.__pendingRequestCount -= 1;
            })
            .catch(err => {
                const errorCode = get(err, 'response.data.code');
                if (errorCode) {
                    this.geoStatus = errorCode;
                }
                this.__pendingRequestCount -= 1;
                throw err;
            });
    }

    static calculateRoute(locations, options = {}, extraRequestOptions = {}) {
        extraRequestOptions = parseRegisterCancelToken(extraRequestOptions);

        // Yeah, don't know how to do this as default argument.
        // The useRealtimeInfo value is a dirty hack to make the cypress tests function properly,
        // window.noRealtimeRoutes will be set to true in some cypress tests.
        const mergedOptions = { optimizationLevel: 80, useRealtimeInfo: !window['noRealtimeRoutes'], hazardousGoods: false, euroClass: 5 };
        Object.assign(mergedOptions, options);
        const requestOptions = {
            locations,
            'optimization_level': mergedOptions.optimizationLevel,
            'use_realtime_info': mergedOptions.useRealtimeInfo,
            'hazardous_goods': mergedOptions.hazardousGoods,
            'euro_class': mergedOptions.euroClass,
        };

        return new Promise((resolve, reject) => {
            api.post(
                '/location/calculate_route/',
                requestOptions,
                {
                    skipRequestError: true,
                    ...extraRequestOptions,
                }
            ).then(res => {
                resolve({...res, useRealtimeInfo: mergedOptions.useRealtimeInfo });
            }).catch(e => {
                // Fail? Try again without realtime info, as there may
                // be a road block right now (but it can be cleared up
                // when we arrive, or it might have not existed when
                // we planned it or drove, but it does exist now).
                // Unfortunately, PTV does not tell us if that's the
                // case so we have to blindly retry.
                new Promise(() => {
                    api.post(
                        '/location/calculate_route/',
                        Object.assign({use_realtime_info: false}, requestOptions),
                        {
                            skipRequestError: true,
                            ...extraRequestOptions,
                        }
                    )
                    .then(res => resolve({...res, useRealtimeInfo: false }))
                    .catch(res => reject(res));
                });
            });
        });
    }

    boekNotation() {
        return `${this.country}-${this.zipCode.replace(/\s/g, '')} ${
            this.city
        }`;
    }
}

export class LocationWithoutPoint extends Location {
    toBackend() {
        return omit(super.toBackend(), 'point');
    }
}
