import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ContactStore } from 'store/Contact';
import { TargetTextInput, TargetSelect, TargetTextArea } from 'spider/semantic-ui/Target';
import TargetWysiwygInput from 'component/TargetWysiwygInput';
import { uniq } from 'lodash';
import styled from 'styled-components';

const RequiredValuesWrapper = styled.div`
    ${props => props.requiredValues.length > 0 && props.requiredValues.map(val =>
        `[value=${CSS.escape(val)}] .delete.icon {
            display: none;
        }`
    )}
`;

@observer
export default class ComposeEmail extends Component {
    static propTypes = {
        wysiwyg: PropTypes.bool,
        viewStore: PropTypes.object.isRequired,
        invoice: PropTypes.object,
        entityInvoice: PropTypes.object,
        model: PropTypes.object.isRequired,
        contactStore: PropTypes.instanceOf(ContactStore).isRequired,
        requiredContactStore: PropTypes.instanceOf(ContactStore),
        className: PropTypes.string,
        errorMessages: PropTypes.array.isRequired,
        regenerate: PropTypes.func,
    }

    static defaultProps = {
        errorMessages: [],
    }

    handleChange = (name, value) => {
        this.props.model.setInput(name, value);
    };

    /**
     * User can add recipients which are not in the contactStore.
     */
    getRecipientOptions = () => {
        const { model, contactStore } = this.props;
        const emails = [];
        const options = [];

        contactStore.models.forEach(contact => {
            emails.push(contact.email);
            options.push(this.formatRecipientOption(contact));
        });

        model.recipients.forEach(email => {
            if (!emails.includes(email)) {
                options.push({
                    value: email,
                    text: email,
                });
            }
        });

        return options;
    }

    formatRecipientOption = contact => {
        let extra = '';

        if (contact.type === 'dayshift') {
            extra = '☀️';
        }

        if (contact.type === 'nightshift') {
            extra = '🌚';
        }

        return {
            value: contact.email,
            text: `${contact.name} ${extra}`,
        };
    };

    render() {
        const { model, className, errorMessages, wysiwyg, requiredContactStore } = this.props;
        const InputComponent = wysiwyg ? TargetWysiwygInput : TargetTextArea;
        const requiredValues = requiredContactStore ? requiredContactStore.map(this.formatRecipientOption).map(c => c.value) : [];

        return (
            <div className={className}>
                <RequiredValuesWrapper requiredValues={requiredValues}>
                    <TargetSelect allowAdditions multiple search
                        upward={false}
                        label={t('statusUpdate.field.recipients.label')} // Model can be multiple models, even a fake one without translation (like used in invoice).
                        target={model}
                        name="recipients"
                        options={this.getRecipientOptions()}
                        toTarget={value => uniq([...requiredValues, ...value])}
                        style={{ marginBottom: "1em" }} // overwrite the forced semantic ui selector
                    />
                </RequiredValuesWrapper>
                {/* The subject cannot contain html. */}
                <TargetTextInput
                    label={t('statusUpdate.field.subject.label')} // Model can be multiple models, even a fake one without translation (like used in invoice).
                    target={model}
                    name="subject"
                />
                <InputComponent autoHeight
                    label={t('statusUpdate.field.content.label')}
                    target={model}
                    name="content"
                    mapErrors={errors => [...errors, ...errorMessages]}
                />
            </div>
        );
    }
}
