import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export class DriverBonus extends Model {
    static backendResourceName = 'driver_bonus';

    @observable id = null;
    @observable name = '';
    @observable type = null;
    @observable isBonus = true;
    @observable bonusOrDeductionFromBep = false;
}

export class DriverBonusStore extends Store {
    Model = DriverBonus;
    static backendResourceName = 'driver_bonus';
}
