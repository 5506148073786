import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { ContactStore } from './Contact';

export const TYPE_LEASE_COMPANY = 'lease company';
export const TYPE_INSURANCE_COMPANY = 'insurance company';
export const TYPE_SERVICE_COMPANY = 'service company';
export const TYPE_INSURANCE_BROKER = 'insurance broker';

export class Supplier extends Model {
    static backendResourceName = 'supplier';

    @observable id = null;
    @observable type = TYPE_LEASE_COMPANY;
    @observable name = '';
    @observable vatCode = '';

    relations() {
        return {
            contacts: ContactStore,
        };
    }
}

export class SupplierStore extends Store {
    Model = Supplier;
    static backendResourceName = 'supplier';
}
