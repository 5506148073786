import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { findLast } from 'lodash';
import { getMoneyForUser, getFuelSurchargeForUser, snakeToCamel, getCurrencySymbolFromCode, OWN_CURRENCY } from 'helpers';

export class TariffKmRate extends Model {
    static backendResourceName = 'tariff_km_rate';

    @observable id = null;
    @observable invoiceKmField = 'given_km';
    @observable minKm = 0;
    @observable price = 0;
    @observable fixed = false;
    @observable fuelSurchargeFactor = 0;

    getInvoiceKmFromActivity(activity) {
        switch (this.invoiceKmField) {
            case 'driven_km':
                return activity.totalActivityDrivenKm;
            default:
                return activity.givenKm;
        }
    }

    calcPriceForActivity(activity, totalExpectedKm) {
        activity._tariffKmRateExplaination = '';

        return this.calcKmAmountForActivity(activity, totalExpectedKm);
    }

    calcKmAmountForTotalKm(totalKm) {
        return this.price * totalKm / 10;
    }

    calcKmAmountForActivity(activity, totalExpectedKm) {
        const km = this.getInvoiceKmFromActivity(activity);
        const currencySymbol = getCurrencySymbolFromCode(activity && activity.allocation && activity.allocation.contract && activity.allocation.contract.fcCode ? activity.allocation.contract.fcCode : OWN_CURRENCY)

        activity._tariffKmAmountExplaination = '';
        
        if (!this.fixed) {
            const amount = this.calcKmAmountForTotalKm(km);

            activity._tariffKmAmountExplaination = `Price / KM: ${currencySymbol}${getMoneyForUser(this.price, 3)} * ${km}km = ${currencySymbol}${getMoneyForUser(amount)}`;

            return amount;
        } else {
            let amount = this.price / 10;

            if (totalExpectedKm) {
                amount *= this.getInvoiceKmFromActivity(activity) / totalExpectedKm;
            }

            activity._tariffKmAmountExplaination = `Price (fixed): ${currencySymbol}${getMoneyForUser(this.price, 3)}`;

            if (totalExpectedKm) {
                activity._tariffKmAmountExplaination += ` / ${totalExpectedKm}km * ${km}km`;
            }

            return amount;
        }

    }

    calcSurchargeForActivity(activity, totalExpectedKm) {
        const kmAmount = this.calcKmAmountForActivity(activity, totalExpectedKm);
        const currencySymbol = getCurrencySymbolFromCode(activity && activity.allocation && activity.allocation.contract &&  activity.allocation.contract.fcCode ? activity.allocation.contract.fcCode : OWN_CURRENCY )
        const fuelSurchargeAmount = this.calcSurcharge(kmAmount);

        activity._tariffKmSurchargeExplaination = `Fuel surcharge: ${currencySymbol}${getMoneyForUser(kmAmount)} * ${getFuelSurchargeForUser(this.fuelSurchargeFactor)}% = ${currencySymbol}${getMoneyForUser(fuelSurchargeAmount)}`;
        return fuelSurchargeAmount;
    }

    calcSurcharge(amount) {
        const fuelSurchargeAmount = amount * this.fuelSurchargeFactor / 10000;

        return fuelSurchargeAmount;
    }

    explainForActivity(activity, totalExpectedKm) {
        return this.explainKmAmountForActivity(activity, totalExpectedKm);
    }

    explainKmAmountForActivity(activity, totalExpectedKm) {
        if (!activity._tariffKmAmountExplaination) {
            this.calcKmAmountForActivity(activity, totalExpectedKm);
        }

        return activity._tariffKmAmountExplaination;
    }

    explainKmSurchargeForActivity(activity, totalExpectedKm) {
        if (!activity._tariffKmSurchargeExplaination) {
            this.calcSurchargeForActivity(activity, totalExpectedKm);
        }

        return activity._tariffKmSurchargeExplaination;
    }

    explainKmForActivity(activity) {
        return `${t('tariffKmRate.field.invoiceKmField.label')}: ${snakeToCamel(this.invoiceKmField)} => ${this.getInvoiceKmFromActivity(activity)}`;
    }
}

export class TariffKmRateStore extends Store {
    Model = TariffKmRate;
    static backendResourceName = 'tariff_km_rate';
    comparator = 'minKm';

    /**
     * Find volume rate which totalKm falls under. Example:
     *
     * totalKm = 1337
     *
     * minKm = 1000, price 17
     * minKm = 1300, price 16 <-- match
     * minKm = 1600, price 15
     *
     */
    getRateForKm(totalKm) {
        return findLast(this.models, rate => rate.minKm <= totalKm);
    }
}
